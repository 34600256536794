import type {
  AbortCause,
  AbortCauseObject,
  AbortSignalWithCauseMaybe,
} from './abort-controller-with-cause';
import { AbortControllerWithCause } from './abort-controller-with-cause';

// eslint-disable-next-line no-secrets/no-secrets
/**
 * {@link AbortSignal} combined from multiple other source signals.
 *
 * Is {@link AbortSignalWithCauseMaybe} if at least one source signal
 * has an {@link AbortCause}.
 * Is regular {@link AbortSignal} if no source signal has an {@link AbortCause}.
 */
export type AbortSignalCombined<TAbortSignal extends AbortSignal> =
  AbortSignal &
    (TAbortSignal extends { cause?: infer TCause }
      ? Partial<AbortCauseObject<Extract<TCause, AbortCause>>>
      : { cause?: undefined });

/**
 * Races multiple abort signals: the returned signal is aborted when
 * any of the given source signals aborts.
 *
 * For example this is useful if an async operation is divided into sub-operations
 * where each can be aborted and the parent operation should be aborted if
 * any of the sub-operations fail.
 *
 * **To prevent leaking memory** returned signal either needs to be
 * [cleared]{@link Disposable}
 * if the operation ends without being aborted or a `signal` has to be passed
 * to abort the race by it performing cleanup.
 *
 * @param sources The signals to race.
 * @param signal Signals to abort the race, e.g. because the operation has
 * ended hence waiting for abort of `sources` is not necessary any more.
 * Performs cleanup like {@link Disposable}.
 * @return combined signal that aborts if any of `signals` aborts.
 *
 * @see https://github.com/whatwg/dom/issues/920
 */
// eslint-disable-next-line max-lines-per-function
export function raceAbortSignals<
  TAbortSignal extends AbortSignalWithCauseMaybe = AbortSignalWithCauseMaybe,
>(
  sources: Iterable<TAbortSignal>,
  signal?: AbortSignal,
): Disposable & { signal: AbortSignalCombined<TAbortSignal> } {
  const cleanup = new DisposableStack();

  type CombinedAbortCause = TAbortSignal['cause'] extends undefined
    ? AbortCause
    : NonNullable<TAbortSignal['cause']>;

  const controllerCombined = new AbortControllerWithCause<CombinedAbortCause>();
  const signalCombined =
    controllerCombined.signal as AbortSignalCombined<TAbortSignal>;

  function removeAllListeners(): void {
    cleanup.dispose();
  }

  function handleAbort(event: { target: unknown }): void {
    const signalAborted =
      event.target as AbortSignalWithCauseMaybe<CombinedAbortCause>;
    removeAllListeners();
    controllerCombined.abort(signalAborted.cause);
  }

  let signalAbortedAlready: TAbortSignal | undefined = undefined;
  let count = 0;
  for (const sourceSignal of sources) {
    count += 1;

    if (sourceSignal.aborted) {
      signalAbortedAlready = sourceSignal;
      break;
    }

    sourceSignal.addEventListener('abort', handleAbort, { once: true });

    cleanup.defer(() => {
      sourceSignal.removeEventListener('abort', handleAbort);
    });
  }

  if (count === 0) {
    throw new TypeError('No abort signals to race.');
  }
  if (signalAbortedAlready) {
    cleanup.dispose();

    handleAbort({ target: signalAbortedAlready });
  } else {
    signal?.addEventListener('abort', removeAllListeners, {
      once: true,
      signal: signalCombined,
    });

    cleanup.defer(() => {
      signal?.removeEventListener('abort', removeAllListeners);
    });
  }

  return { signal: signalCombined, [Symbol.dispose]: removeAllListeners };
}

export function isAbortSignalDefined<
  TAbortSignal extends AbortSignalWithCauseMaybe = AbortSignalWithCauseMaybe,
>(signal: TAbortSignal | undefined): signal is TAbortSignal {
  return signal != null;
}
