import type {
  SignoutRedirectArgs,
  User as OidcUser,
  UserManagerSettings,
} from 'oidc-client-ts';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { waitForAbort } from '../../../../base/async/wait-abort';
import { AbortError } from '../../../../base/error/abort-error';
import { getEventHandlersNextValue } from '../../../../base/event/event-handlers-async';
import type { EventHandlersWithStateOf } from '../../../../base/event/event-handlers-state';
import { asError } from '../../../../base/extras-typescript-asserts/type-error-asserts';
import { asNonNullable } from '../../../../base/extras-typescript-asserts/type-non-nullable-asserts';
import type { User } from '../../model/user';
import type { AppAuthenticationClientState } from './app-authentication-client-state';
import {
  getUserMaybeFromAppAuthenticationClientState,
  isAppAuthenticationClientStateWithUser,
  isAppAuthenticationClientStateWithUserStale,
} from './app-authentication-client-state';
import { createAppAuthenticationClientStateMachine } from './app-authentication-client-state-machine';
import { createAppAuthenticationClientStorage } from './app-authentication-client-storage';
import {
  asAppAuthenticationUser,
  asAppAuthenticationUserOrNull,
} from './app-authentication-contract';
import {
  isAuthenticationRedirectUrlWithError,
  parseAppAuthenticationErrorFromRedirectUrl,
} from './app-authentication-error-from-redirect-url';
import {
  AppAuthenticationError,
  wrapWithAuthenticationErrorMaybe,
} from './app-authentification-error';

function createUserManagerSettings(
  storage: Storage,
  settings: UserManagerSettings,
): UserManagerSettings {
  return {
    includeIdTokenInSilentRenew: true,
    userStore: new WebStorageStateStore({ store: storage }),
    stateStore: new WebStorageStateStore({ store: storage }),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    automaticSilentRenew: false,
    ...settings,

    metadata: {
      ...settings.metadata,
    },
  };
}

function isUserLoggedIn(user: User | null | undefined): boolean {
  return user != null && !user.expired;
}

async function login(userManager: UserManager): Promise<void> {
  const user = asAppAuthenticationUserOrNull(await userManager.getUser());

  if (isUserLoggedIn(user) || isRedirectWithCode(window.location)) {
    throw new AppAuthenticationError('Tried to login while still logged in', {
      code: 'LoginThoughLoggedIn',
    });
  }

  try {
    await userManager.signinRedirect();
  } catch (error) /* istanbul ignore next – report traceable error, no error expected */ {
    await userManager.clearStaleState();
    throw AppAuthenticationError.fromCause(asError(error), 'LoginFailed');
  }
}

function isUserLoginRefreshable(user: User | null | undefined): boolean {
  return user?.refresh_token != null;
}

async function refreshLogin(userManager: UserManager): Promise<User> {
  const user = asAppAuthenticationUserOrNull(await userManager.getUser());
  if (!isUserLoginRefreshable(user)) {
    await userManager.removeUser();
    throw new AppAuthenticationError('User is not refreshable', {
      code: 'RefreshFailed',
    });
  }

  let refreshedUser: OidcUser | null = null;
  try {
    refreshedUser = await userManager.signinSilent();
  } catch (error) {
    await userManager.removeUser();

    throw wrapWithAuthenticationErrorMaybe(error, 'RefreshFailed');
  }

  const returnedUser = asAppAuthenticationUserOrNull(refreshedUser);

  if (returnedUser == null) {
    await userManager.removeUser();
    throw new AppAuthenticationError('User is not refreshable', {
      code: 'RefreshFailed',
    });
  }

  return returnedUser;
}

async function logout(
  userManager: UserManager,
  settings: UserManagerSettings,
): Promise<void> {
  const signoutArgs: SignoutRedirectArgs = {
    extraQueryParams: {
      goto: settings.post_logout_redirect_uri as string,
    },
    redirectMethod: 'replace',
  };

  try {
    await userManager.signoutRedirect(signoutArgs);
  } catch (error) /* istanbul ignore next – report traceable error, no error expected */ {
    throw AppAuthenticationError.fromCause(asError(error), 'LogoutFailed');
  }
}

function removeLocationSearch(): void {
  // eslint-disable-next-line no-restricted-globals
  history.replaceState({}, '', window.location.pathname);
}

/**
 * Loads persisted user state and resumes from any pending (login etc.) redirects.
 * Needs to be executed when the app loads.
 */
async function loadAndResumeFromRedirects(
  userManager: UserManager,
): Promise<User | undefined> {
  let user = asAppAuthenticationUserOrNull(await userManager.getUser());

  if (user && (isUserLoggedIn(user) || isUserLoginRefreshable(user))) {
    return user;
  }

  if (isAuthenticationRedirectUrlWithError(window.location)) {
    throw parseAppAuthenticationErrorFromRedirectUrl(window.location);
  }

  if (isLoginRedirect(window, user)) {
    try {
      user = asAppAuthenticationUser(
        await userManager.signinRedirectCallback(),
      );

      return user;
    } catch (error) {
      await userManager.clearStaleState();

      throw AppAuthenticationError.fromCause(asError(error), `LoginFailed`);
    } finally {
      /**
       * Remove the code url query parameter after login.
       * This leads to a clean url which is seen by the user. This will also
       * prevent processing the login code a second time if the user gets invalid
       * for e.g. if the access token and the refresh token are up to running out.
       */
      removeLocationSearch();
    }
  }

  return undefined;
}

/**
 * Inspects the `location` to determine if the authentication service redirected
 * back to us with a `code`.
 */
function isRedirectWithCode(location: Location): boolean {
  return location.search.includes('code=');
}

function isLoginRedirect(window: Window, user: User | null): boolean {
  return !isUserLoggedIn(user) && isRedirectWithCode(window.location);
}

function withDiscardPromiseRejected<TArguments extends unknown[], TReturn>(
  callback: (...parameters: TArguments) => Promise<TReturn>,
): (...parameters: TArguments) => Promise<TReturn> {
  return async (...parameters): Promise<TReturn> => {
    return callback(...parameters).catch((error: unknown) => {
      if (
        // AuthenticationErrors are handled by the state machine
        !(error instanceof AppAuthenticationError) &&
        // AbortErrors should not be propagated since they are expected errors
        !(error instanceof AbortError)
      )
        throw error;
    }) as Promise<TReturn>;
  };
}

function withRaceAgainstAbortOnDispose<
  TLoginOptions extends unknown[],
  TResult,
>(
  runAsyncFunction: (...options: TLoginOptions) => Promise<TResult>,
  abortSignal: AbortSignal,
): typeof runAsyncFunction {
  return async (...options) => {
    const operationFinishedController = new AbortController();

    try {
      return await Promise.race([
        runAsyncFunction(...options),
        waitForAbort({
          target: abortSignal,
          signal: operationFinishedController.signal,
          abortError: new AbortError(
            'Aborted due to dispose of Authentication Client',
          ),
        }),
      ]);
    } finally {
      operationFinishedController.abort();
    }
  };
}

type HandleUser = (user: User | undefined) => void;
type HandleAuthenticationClientStatus = (
  status: AppAuthenticationClientState,
) => void;

export type AppAuthenticationClientOptions = {
  settings: UserManagerSettings;
};

export type AppAuthenticationClient = {
  load: () => Promise<User | undefined>;
  /**
   * Called everytime an authenticated user session is established
   * (e.g. after completing a login flow or loading an existing user)
   * or a session is terminated.
   *
   * The user's token might be expired when an existing user is loaded
   * from client side storage.
   */
  onUser: EventHandlersWithStateOf<HandleUser, undefined>;
  /**
   * called everytime the Authentication client changes it status which is of
   * interest for using it. This includes `loggingIn`, `loggedIn`, `loggingOut`
   *  and `loading`.
   */
  onStatus: EventHandlersWithStateOf<
    HandleAuthenticationClientStatus,
    [AppAuthenticationClientState]
  >;
  getUser: () => Promise<User>;
  getIsUserLoggedIn: () => boolean;
  getAccessToken: () => Promise<string>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  setStateToLoggedOutDueToInvalidAuthentication: () => void;
} & Disposable;

// eslint-disable-next-line max-lines-per-function
export function createAppAuthenticationClient({
  settings,
}: AppAuthenticationClientOptions): AppAuthenticationClient {
  const abortOnDispose = new AbortController();

  const {
    onStateChange,
    setState,
    onUserChange,
    loadUserWithStateHandling,
    loginWithStateHandling,
    logoutWithStateHandling,
    refreshLoginWithStateHandling,
  } = createAppAuthenticationClientStateMachine({
    loadUser: async () => loadAndResumeFromRedirects(userManager),
    login: async () => {
      try {
        await login(userManager);
      } catch (error) {
        if ((error as AppAuthenticationError).code === 'LoginThoughLoggedIn') {
          // user is already logged in or is coming back from a redirect from the auth provider.
          // loading the user here will either
          // - just set the state to logged in (if the user is already logged in) or
          // - parse the redirect response and set the state accordingly
          await loadUserWithStateHandling();
        } else {
          throw error;
        }
      }
    },
    logout: async () => {
      await logout(userManager, settings);
    },
    refreshLogin: async () => refreshLogin(userManager),
  });

  const userManager: UserManager = new UserManager(
    createUserManagerSettings(
      createAppAuthenticationClientStorage('localStorage', console.error),
      settings,
    ),
  );

  function setStateToLoggedOutDueToInvalidAuthentication(): void {
    userManager.removeUser().catch((error: unknown) => {
      console.error('[AppAuthenticationClient] Removing user failed', error);
    });

    setState({
      state: 'loggedOut',
      reason: new AppAuthenticationError('Authentication is invalid', {
        code: 'InvalidAuthentication',
      }),
    });
  }

  async function getUserPromise(): Promise<User> {
    const user = getUserMaybeFromAppAuthenticationClientState(
      onStateChange.get(),
    );

    if (
      user &&
      isUserLoggedIn(user) &&
      !isAppAuthenticationClientStateWithUserStale(onStateChange.get())
    ) {
      return user;
    }

    const [userNext] = await getEventHandlersNextValue(onUserChange, {
      signal: abortOnDispose.signal,
      filterPredicate: (value) => value?.access_token !== user?.access_token,
    });

    return asNonNullable(userNext, 'userNext');
  }

  function refreshTokenWithErrorCallbacks(): void {
    void withDiscardPromiseRejected(
      withRaceAgainstAbortOnDispose(
        refreshLoginWithStateHandling,
        abortOnDispose.signal,
      ),
    )();
  }

  function disposeRefreshTokenEvents(): void {
    userManager.events.removeAccessTokenExpiring(
      refreshTokenWithErrorCallbacks,
    );
    userManager.events.removeAccessTokenExpired(refreshTokenWithErrorCallbacks);
  }

  // activate refreshing token
  userManager.events.addAccessTokenExpired(refreshTokenWithErrorCallbacks);
  userManager.events.addAccessTokenExpiring(refreshTokenWithErrorCallbacks);

  function disposeUserManager(): void {
    abortOnDispose.abort();
    disposeRefreshTokenEvents();
    userManager.stopSilentRenew();
  }

  return {
    load: withDiscardPromiseRejected(
      withRaceAgainstAbortOnDispose(
        loadUserWithStateHandling,
        abortOnDispose.signal,
      ),
    ),
    getIsUserLoggedIn: () => {
      return isAppAuthenticationClientStateWithUser(onStateChange.get());
    },
    onUser: onUserChange,
    onStatus: onStateChange,
    setStateToLoggedOutDueToInvalidAuthentication,
    getUser: getUserPromise,
    getAccessToken: async () => {
      const user = await getUserPromise();
      return user.access_token;
    },
    login: withDiscardPromiseRejected(
      withRaceAgainstAbortOnDispose(
        loginWithStateHandling,
        abortOnDispose.signal,
      ),
    ),
    logout: withDiscardPromiseRejected(
      withRaceAgainstAbortOnDispose(
        logoutWithStateHandling,
        abortOnDispose.signal,
      ),
    ),
    [Symbol.dispose]: () => {
      disposeUserManager();
    },
  };
}
