import instance from '../../../app-shell/service/axios';
import type { DealerDetails } from '../../model/dealer-details';
import { getDealerDetailsApiPath } from './dealer-api-config';

export async function getDealerAccountDetails(
  dealerId: string,
  signal?: AbortSignal,
): Promise<DealerDetails> {
  return instance
    .get<DealerDetails>(getDealerDetailsApiPath(dealerId), { signal })
    .then((response) => response.data);
}
