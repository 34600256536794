import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import type {
  DeviceModel,
  DeviceType,
  StihlDeviceTag,
} from '../../../../device/model';
import { startMassAssignmentUpdate } from '../../../service/device-management-api-client/device-management-api-client';

const StyledInfoWrapper = styled.div`
  display: flex;
`;

const StyledInfoContainer = styled.div`
  padding: 4rem;
  text-align: center;
`;

const StyledTextContainer = styled.div`
  inline-size: 400px;
  padding: 2rem 0 2rem 4rem;
`;

const StyledSummaryTypography = styled(Typography)`
  inline-size: 400px;
  margin-block-end: 0.5rem;
  color: ${stihlColor.fontGrey};
`;

const StyledErrorTextContainer = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-block-end: 1.5rem;
`;

const StyledErrorText = styled.span`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
`;

type MassAssignmentSummaryProps = {
  selectedSwVersion: string;
  deviceModel: DeviceModel | undefined;
  deviceTypes: DeviceType[];
  deviceTag: StihlDeviceTag | undefined;
  swVersionFilter: string;
  countryFilter: string;
  handleStepChange: (panel: string) => void;
  resetFilters: () => void;
  isLoadingDryRun: boolean;
  affectedDevicesNumber: string | number;
};

export const MassAssignmentSummary = ({
  selectedSwVersion,
  deviceModel,
  deviceTypes,
  deviceTag,
  swVersionFilter,
  countryFilter,
  handleStepChange,
  resetFilters,
  isLoadingDryRun,
  affectedDevicesNumber,
}: MassAssignmentSummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  function handleMassAssignmentUpdate(): void {
    if (deviceModel === undefined) return;

    setIsLoadingUpdate(true);
    const country = countryFilter === '' ? undefined : countryFilter;
    const selectedDeviceTypes =
      deviceTypes.length > 0 ? deviceTypes : undefined;
    startMassAssignmentUpdate(
      deviceModel,
      swVersionFilter,
      selectedSwVersion,
      selectedDeviceTypes,
      country,
      deviceTag,
    )
      .then((response) => {
        setAlert({
          isOpen: true,
          message: t('updateManagement.successfullyUpdated', {
            updatedDevices: `${response}/${affectedDevicesNumber}`,
          }),
          severity: 'success',
        });
        setIsLoadingUpdate(false);
        resetFilters();
        return handleStepChange('step1');
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          message: t('updateManagement.updateFailed'),
          severity: 'error',
        });
        return setIsLoadingUpdate(false);
      });
  }

  return (
    <>
      <StyledInfoWrapper>
        <StyledInfoContainer>
          <Typography variant="h4" pb={1}>
            {isLoadingDryRun ? <CircularProgress /> : affectedDevicesNumber}
          </Typography>
          <b>{t('updateManagement.selectedDevices')}</b>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <Typography variant="h4" pb={1}>
            {selectedSwVersion}
          </Typography>
          <b>{t('updateManagement.softwareVersion')}</b>
        </StyledInfoContainer>
        <StyledTextContainer>
          <Typography pb={1}>
            <b>{t('updateManagement.massAssignmentFor')}</b>
          </Typography>
          <StyledSummaryTypography data-testid="deviceModelSummary">
            <b>{t('filterLabel.deviceModel')}:</b> {deviceModel}
          </StyledSummaryTypography>
          <StyledSummaryTypography>
            <b>{t('filterLabel.deviceType')}: </b>
            {deviceTypes.length === 0 ? 'All' : deviceTypes.join(', ')}
          </StyledSummaryTypography>
          <StyledSummaryTypography data-testid="deviceTagSummary">
            <b>{t('filterLabel.deviceTag')}:</b> {deviceTag ?? 'All'}
          </StyledSummaryTypography>
          <StyledSummaryTypography>
            <b>{t('filterLabel.currentSoftwareVersion')}:</b> {swVersionFilter}
          </StyledSummaryTypography>
          <StyledSummaryTypography>
            <b>{t('country')}: </b>
            {countryFilter === '' ? 'All' : countryFilter}
          </StyledSummaryTypography>
        </StyledTextContainer>
      </StyledInfoWrapper>
      {(affectedDevicesNumber === '?' || affectedDevicesNumber === 0) && (
        <StyledErrorTextContainer data-testid="errorMessage">
          <StihlIconXFilled color="error" />
          {affectedDevicesNumber === 0 ? (
            <StyledErrorText>
              {t('updateManagement.dryRunZero')}
            </StyledErrorText>
          ) : (
            <StyledErrorText>
              {t('updateManagement.dryRunError')}
            </StyledErrorText>
          )}
        </StyledErrorTextContainer>
      )}

      <StihlButtonSecondaryLoading
        loading={isLoadingUpdate}
        onClick={handleMassAssignmentUpdate}
        disabled={affectedDevicesNumber === '?' || affectedDevicesNumber === 0}
        data-testid="updateButton"
      >
        {t('updateManagement.startProcess')}
      </StihlButtonSecondaryLoading>
    </>
  );
};
