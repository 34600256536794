import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTextField from '../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconClose from '../../../../base/stihl-material-ui/icons/stihl-icon-close';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { DeviceModel } from '../../../device/model';
import type { DeviceStatus } from '../../model/statistics.model';
import { getDevicesWithSoftwareVersion } from '../../service/device-management-api-client/device-management-api-client';

const StyledContainer = styled.div`
  align-self: center;
  margin-inline-start: 1rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: black;
`;

const SwVersionSearch = ({
  deviceModel,
  beforeFetch,
  onSuccess,
}: {
  deviceModel: DeviceModel | undefined;
  beforeFetch: () => void;
  onSuccess: (response: DeviceStatus[]) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const [softwareVersionSearch, setSoftwareVersionSearch] = useState('');
  const [isLoadingSwVersionSearch, setIsLoadingSwVersionSearch] =
    useState(false);

  function handleSubmit(): void {
    if (softwareVersionSearch && deviceModel != null) {
      beforeFetch();
      setIsLoadingSwVersionSearch(true);

      getDevicesWithSoftwareVersion(deviceModel, softwareVersionSearch)
        .then((response) => {
          setIsLoadingSwVersionSearch(false);
          return onSuccess(response);
        })
        .catch(() => {
          setIsLoadingSwVersionSearch(false);
          setAlert({
            isOpen: true,
            message: t('deviceStatusTable.swVersionRequestFailed'),
            severity: 'error',
          });
        });
    }
  }

  return (
    <StyledContainer>
      <InputLabel htmlFor="softwareVersionSearch" variant="standard">
        {t('deviceStatusTable.swVersionSearch')}
      </InputLabel>
      <StihlTextField
        id="softwareVersionSearch"
        sx={{
          '&.MuiTextField-root.MuiFormControl-marginDense': {
            inlineSize: '13rem',
          },
        }}
        inputProps={{ 'data-testid': 'searchInput' }}
        placeholder={t('deviceStatusTable.search')}
        value={softwareVersionSearch}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !isLoadingSwVersionSearch) {
            handleSubmit();
          }
        }}
        onChange={(event) =>
          !isLoadingSwVersionSearch &&
          setSoftwareVersionSearch(event.target.value)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoadingSwVersionSearch ? (
                <StyledCircularProgress size="24px" />
              ) : (
                <>
                  {softwareVersionSearch !== '' && (
                    <IconButton
                      style={{ padding: '6px' }}
                      size="large"
                      onClick={() => setSoftwareVersionSearch('')}
                      data-testid="resetButton"
                    >
                      <StihlIconClose color="text.primary" />
                    </IconButton>
                  )}
                  <IconButton
                    style={{ padding: '6px' }}
                    size="large"
                    onClick={handleSubmit}
                    data-testid="searchButton"
                  >
                    <SearchIcon color="secondary" />
                  </IconButton>
                </>
              )}
            </InputAdornment>
          ),
        }}
      />
    </StyledContainer>
  );
};

export default SwVersionSearch;
