import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import type { FC } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import StihlSnackbarAlert from '../base/stihl-material-ui/components/stihl-snackbar-alert/stihl-snackbar-alert';
import { StihlMaterialThemeProvider } from '../base/stihl-material-ui/theme/stihl-theme-provider';
import { useAlertStore } from '../modules/app-alert/service/alert-provider';
import { AppAuthenticationType } from '../modules/app-authentication/model/authentication-session';
import useAppAuthenticationSession from '../modules/app-authentication/service/app-authentication-session/app-authentication-session-service';
import { useAxiosInterceptors } from '../modules/app-authentication/service/authentication-interceptor';
import { NavigationItem } from '../modules/app-shell/model/app.models';
import { useIsFetchingAppBase } from '../modules/app-shell/service/app-base/app-base-api-client';
import { AppNavigationFrame } from '../modules/app-shell/ui/app-navigation-frame';
import AppErrorBoundary from '../modules/app-shell/ui/error-boundary/app-error-boundary';
import CustomerAccountRegistrationForm from '../modules/customer-management/ui/customer-registration/customer-account-registration';
import { CustomerManagementTabsComponent } from '../modules/customer-management/ui/tabs/customer-management-tabs-component';
import DeviceDetails from '../modules/device/ui/device-details/device-details';
import DeviceManagementTabsComponent from '../modules/device-management/ui/tabs/device-management-tabs-component';
import { HelpTabsComponent } from '../modules/help/ui/help-tabs-component';
import { useAreTermsAndConditionsAccepted } from '../modules/legal-documents/service/terms-and-conditions-service/terms-and-conditions-service';
import LoginPage from '../modules/login/ui/login-page';
import TermsAndConditionsModal from '../modules/login/ui/terms-and-conditions-modal';
// eslint-disable-next-line import/max-dependencies
import SystemHealthOverview from '../modules/system-health/ui/system-health-overview';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100vw;
  block-size: 100vh;
`;

export const App: FC = () => {
  const session = useAppAuthenticationSession();
  const [alert, setAlert] = useAlertStore();

  useAxiosInterceptors();

  const isPageLoading = useIsFetchingAppBase();
  const areTermsAndConditionsAccepted = useAreTermsAndConditionsAccepted();

  if (session.authType === undefined) {
    return (
      <AppErrorBoundary>
        <StihlMaterialThemeProvider>
          <LoginPage />
        </StihlMaterialThemeProvider>
      </AppErrorBoundary>
    );
  }

  if (
    session.authType === AppAuthenticationType.Dealer &&
    !areTermsAndConditionsAccepted
  ) {
    return (
      <AppErrorBoundary>
        <StihlMaterialThemeProvider>
          <TermsAndConditionsModal />
        </StihlMaterialThemeProvider>
      </AppErrorBoundary>
    );
  }

  const loginPath = new URL(process.env.REACT_APP_AUTH_REDIRECT_URI as string)
    .pathname;

  /* eslint-disable react/jsx-max-depth */
  return (
    <AppErrorBoundary>
      <StihlMaterialThemeProvider>
        {isPageLoading ? (
          <PageWrapper>
            <CircularProgress size="5rem" data-testid="loadingScreen" />
          </PageWrapper>
        ) : (
          <>
            <Router>
              <AppNavigationFrame>
                <Switch>
                  {session.navigationItems.includes(NavigationItem.Device) && (
                    <Route path="/device">
                      <DeviceDetails />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.SystemHealthStatus,
                  ) && (
                    <Route path="/system-health">
                      <div>
                        <SystemHealthOverview />
                      </div>
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.DeviceManagement,
                  ) && (
                    <Route path="/device-management">
                      <DeviceManagementTabsComponent />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.CustomerManagment,
                  ) && (
                    <Route path="/customer-management/register">
                      <CustomerAccountRegistrationForm />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.CustomerManagment,
                  ) && (
                    <Route path="/customer-management">
                      <CustomerManagementTabsComponent />
                    </Route>
                  )}
                  {session.navigationItems.includes(NavigationItem.Help) && (
                    <Route path="/help">
                      <HelpTabsComponent />
                    </Route>
                  )}
                  <Route path={loginPath}>
                    <Redirect to="/device" />
                  </Route>
                  <Route path="*">
                    <Redirect to="/device" />
                  </Route>
                </Switch>
              </AppNavigationFrame>
            </Router>
            <StihlSnackbarAlert alert={alert} setAlert={setAlert} />
          </>
        )}
      </StihlMaterialThemeProvider>
    </AppErrorBoundary>
    /* eslint-enable react/jsx-max-depth */
  );
};
export default App;
