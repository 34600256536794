import type { ToUnknownRecord } from '../extras-typescript/object-modifier';
import type { ErrorBaseOptions } from './error-base';
import { initErrorBase } from './error-base';

export type TimeoutErrorOptions<TCode extends string> =
  ErrorBaseOptions<TCode> & { milliseconds?: number };

export class TimeoutError<TCode extends string = string> extends Error {
  override name = 'TimeoutError';

  code?: TCode;
  milliseconds?: number;

  constructor(message: string, options?: TimeoutErrorOptions<TCode>) {
    super(message);
    initErrorBase(this, options, TimeoutError);
    this.milliseconds = options?.milliseconds;
  }
}

export function isTimeoutError(value: unknown): value is TimeoutError {
  const maybe = value as ToUnknownRecord<TimeoutError>;
  // check structural type instead of `instanceof` to allow plain objects
  // (e.g. created from a deserialized error) and errors from other JS contexts
  return (
    typeof value === 'object' &&
    value != null &&
    maybe.name === 'TimeoutError' &&
    typeof maybe.message === 'string' &&
    (maybe.stack == null || typeof maybe.stack === 'string') &&
    (maybe.milliseconds == null || typeof maybe.milliseconds === 'number') &&
    (maybe.code == null || typeof maybe.code === 'string')
  );
}
