import { createEventHandlersSync } from '../../../../base/event/event-handlers';
import type {
  EventHandlersDelegateWithState,
  EventHandlersWithStateOf,
} from '../../../../base/event/event-handlers-state';
import { withState } from '../../../../base/event/event-handlers-state';
import { assertNonNullable } from '../../../../base/extras-typescript-asserts/type-non-nullable-asserts';
import type { User } from '../../model/user';
import type { AppAuthenticationClientState } from './app-authentication-client-state';
import { getUserMaybeFromAppAuthenticationClientState } from './app-authentication-client-state';
import { wrapWithAuthenticationErrorMaybe } from './app-authentification-error';

type CreateAppAuthenticationClientStateMachineOptions<
  TLoadUserOptions extends unknown[],
  TLoginOptions extends unknown[],
  TLogoutOptions extends unknown[],
  TRefreshLoginOptions extends unknown[],
> = {
  loadUser: (...options: TLoadUserOptions) => Promise<User | undefined>;
  login: (...options: TLoginOptions) => Promise<void>;
  logout: (...options: TLogoutOptions) => Promise<void>;
  refreshLogin: (...options: TRefreshLoginOptions) => Promise<User>;
};

type HandleStateChange = (state: AppAuthenticationClientState) => void;
type HandleUserChange = (user: User | undefined) => void;

type HandleStateChangeInvokable = EventHandlersDelegateWithState<
  Parameters<HandleStateChange>,
  void,
  void,
  Parameters<HandleStateChange>
>;

type AppAuthenticationClientStateMachineHandlers = {
  onStateChange: EventHandlersWithStateOf<
    HandleStateChange,
    Parameters<HandleStateChange>
  >;
  onUserChange: EventHandlersWithStateOf<HandleUserChange, undefined>;
  setState: HandleStateChange;
};

type AppAuthenticationClientStateMachine<
  TLoadUserOptions extends unknown[],
  TLoginOptions extends unknown[],
  TLogoutOptions extends unknown[],
  TRefreshLoginOptions extends unknown[],
> = AppAuthenticationClientStateMachineHandlers & {
  [K in keyof CreateAppAuthenticationClientStateMachineOptions<
    TLoadUserOptions,
    TLoginOptions,
    TLogoutOptions,
    TRefreshLoginOptions
  > as `${K}WithStateHandling`]: CreateAppAuthenticationClientStateMachineOptions<
    TLoadUserOptions,
    TLoginOptions,
    TLogoutOptions,
    TRefreshLoginOptions
  >[K];
};

const initialState = [{ state: 'uninitialized' }] as const;

function createOnUserChangeFrom(
  onStateChange: AppAuthenticationClientStateMachineHandlers['onStateChange'],
): AppAuthenticationClientStateMachineHandlers['onUserChange'] {
  let size = 0;

  return {
    get size() {
      return size;
    },
    get() {
      return getUserMaybeFromAppAuthenticationClientState(onStateChange.get());
    },
    getAll() {
      return [this.get()];
    },
    add(handle: (user: User | undefined) => void) {
      size++;

      let previous: User | undefined = undefined;

      const disposeOnStateChangeSubscription = onStateChange.add(
        (authStatus) => {
          const current =
            getUserMaybeFromAppAuthenticationClientState(authStatus);

          if (previous?.access_token !== current?.access_token) {
            handle(current);
            previous = current;
          }
        },
      );

      return () => {
        size--;
        disposeOnStateChangeSubscription();
      };
    },
  };
}

function withStateChangeLoadUser<TLoadUserOptions extends unknown[]>(
  loadUser: (...options: TLoadUserOptions) => Promise<User | undefined>,
  handleStateChange: HandleStateChangeInvokable,
): typeof loadUser {
  return async (...options) => {
    handleStateChange.invoke({ state: 'loading' });

    try {
      const user = await loadUser(...options);

      if (user != null && !user.expired) {
        handleStateChange.invoke({ state: 'loggedIn', user });
      } else {
        handleStateChange.invoke({ state: 'loggedOut' });
      }

      return user;
    } catch (error) {
      handleStateChange.invoke({
        state: 'loggedOut',
        reason: wrapWithAuthenticationErrorMaybe(error, 'LoginFailed'),
      });

      throw error;
    }
  };
}

function withStateChangeLogin<TLoginOptions extends unknown[]>(
  login: (...options: TLoginOptions) => Promise<void>,
  handleStateChange: HandleStateChangeInvokable,
): typeof login {
  return async (...options) => {
    handleStateChange.invoke({ state: 'loggingIn' });

    try {
      await login(...options);
    } catch (error) {
      handleStateChange.invoke({
        state: 'loggedOut',
        reason: wrapWithAuthenticationErrorMaybe(error, 'LoginFailed'),
      });

      throw error;
    }
  };
}

function withStateChangeLogout<TLoginOptions extends unknown[]>(
  logout: (...options: TLoginOptions) => Promise<void>,
  handleStateChange: HandleStateChangeInvokable,
): typeof logout {
  return async (...options) => {
    const user = getUserMaybeFromAppAuthenticationClientState(
      handleStateChange.get(),
    );

    assertNonNullable(user, 'user');

    handleStateChange.invoke({ state: 'loggingOut', user });

    try {
      await logout(...options);

      handleStateChange.invoke({ state: 'loggedOut' });
    } catch (error) {
      handleStateChange.invoke({
        state: 'loggedOut',
        reason: wrapWithAuthenticationErrorMaybe(error, 'LogoutFailed'),
      });

      throw error;
    }
  };
}

// eslint-disable-next-line max-lines-per-function
function withStateChangeRefreshLogin<TLoginOptions extends unknown[]>(
  refreshLogin: (...options: TLoginOptions) => Promise<User>,
  handleStateChange: HandleStateChangeInvokable,
): typeof refreshLogin {
  return async (...options) => {
    const user = getUserMaybeFromAppAuthenticationClientState(
      handleStateChange.get(),
    );

    handleStateChange.invoke({
      state: 'refreshingToken',
      userStale: user,
    });

    try {
      const userAfterRefresh = await refreshLogin(...options);

      handleStateChange.invoke({
        state: 'loggedIn',
        user: userAfterRefresh,
      });

      return userAfterRefresh;
    } catch (error) {
      const authenticationError = wrapWithAuthenticationErrorMaybe(
        error,
        'RefreshFailed',
      );

      if (user && !user.expired) {
        handleStateChange.invoke({
          state: 'loggedIn',
          user,
          refreshFailedReason: authenticationError,
        });
      } else {
        handleStateChange.invoke({
          state: 'loggedOut',
          reason:
            // if there was no user before,
            // then this was the initial refresh.
            // no need to save the failed reason
            // because nothing really happened
            // Example case: When user starts the app,
            // a refresh token exists from previous app
            // usage, but is expired now.
            // Due to the expiry, the refresh fails.
            // As the user was not loggedIn before,
            // this is not a perceivable error
            user == null ? undefined : authenticationError,
        });
      }

      throw error;
    }
  };
}

// eslint-disable-next-line max-lines-per-function
export function createAppAuthenticationClientStateMachine<
  TLoadUserOptions extends unknown[],
  TLoginOptions extends unknown[],
  TLogoutOptions extends unknown[],
  TRefreshLoginOptions extends unknown[],
>({
  loadUser,
  login,
  logout,
  refreshLogin,
}: CreateAppAuthenticationClientStateMachineOptions<
  TLoadUserOptions,
  TLoginOptions,
  TLogoutOptions,
  TRefreshLoginOptions
>): AppAuthenticationClientStateMachine<
  TLoadUserOptions,
  TLoginOptions,
  TLogoutOptions,
  TRefreshLoginOptions
> {
  const handleStateChange = withState(
    createEventHandlersSync<HandleStateChange>(),
    initialState,
  );
  const onUserChange = createOnUserChangeFrom(handleStateChange);

  return {
    onStateChange: handleStateChange,
    setState: handleStateChange.invoke,
    onUserChange,
    loadUserWithStateHandling: withStateChangeLoadUser(
      loadUser,
      handleStateChange,
    ),
    loginWithStateHandling: withStateChangeLogin(login, handleStateChange),
    logoutWithStateHandling: withStateChangeLogout(logout, handleStateChange),
    refreshLoginWithStateHandling: withStateChangeRefreshLogin(
      refreshLogin,
      handleStateChange,
    ),
  };
}
