import type { UserManagerSettings } from 'oidc-client-ts';

// disable: oidc 3rd party lib does not follow naming conventions
/* eslint-disable @typescript-eslint/naming-convention */
const appAuthenticationSessionConfigDealer = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY as string,

  client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
  post_logout_redirect_uri: process.env
    .REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI as string,
  silent_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
  response_type: 'code',
  scope: process.env.REACT_APP_AUTH_SCOPES as string,
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET as string,
  acr_values: '2fa',
  metadata: {
    issuer: process.env.REACT_APP_AUTH_ISSUER as string,
    userinfo_endpoint: process.env.REACT_APP_AUTH_END_USER as string,
    authorization_endpoint: process.env.REACT_APP_AUTH_AUTHORIZATION as string,
    token_endpoint: process.env.REACT_APP_AUTH_TOKEN as string,
    end_session_endpoint: process.env.REACT_APP_AUTH_END_SESSION as string,
  },
} satisfies UserManagerSettings;
/* eslint-enable @typescript-eslint/naming-convention */

export default appAuthenticationSessionConfigDealer;
