import type { JSX, Dispatch, SetStateAction } from 'react';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StihlFilterChips from '../stihl-filter-chips/stihl-filter-chips';
import { StihlHelperText } from '../stihl-helper-text/stihl-helper-text';

export enum FilterParameterValue {
  SerialNumber = 'serialNumber',
  RangeOfSerialnumbers = 'rangeOfSerialNumbers',
  RangeOfProductionDates = 'rangeOfProductionDates',
  SoftwareVersion = 'softwareVersion',
  ConnectionState = 'connectionState',
  DeviceType = 'deviceType',
  Tag = 'tag',
  Imei = 'imei',
  Imsi = 'imsi',
  DeviceId = 'deviceId',
  DeviceModel = 'deviceModel',
  ProcessingChain = 'processingChain',
  Email = 'email',
  Name = 'name',
}

export type RangeObject = {
  identifier: FilterParameterValue;
  start: string;
  end: string;
  range: (Date | null)[];
};

export type ChipType = {
  filter: FilterParameterValue;
  searchTerm: string;
  value?: RangeObject | number;
  chipRenderText?: string;
};

type StihlChipContainerProps = {
  chips: ChipType[];
  setChips: Dispatch<SetStateAction<ChipType[]>>;
  isInvalidInput: boolean;
  isClearAllFiltersButtonShown?: boolean;
};

export const StihlChipContainer = ({
  chips,
  setChips,
  isInvalidInput,
  isClearAllFiltersButtonShown = true,
}: StihlChipContainerProps): JSX.Element => {
  const { t } = useTranslation();

  const filterChips = useMemo(
    () =>
      chips.map((chip) => ({
        id: chip.searchTerm,
        displayValue: (
          <>
            {t(`filterLabel.${chip.filter}`)}
            {': '}
            {chip.chipRenderText ?? chip.searchTerm}
          </>
        ),
      })),
    [chips, t],
  );

  const setFilterChips = useCallback(
    (filterChipsNew: { id: string }[]): void => {
      const chipIds = new Set(filterChipsNew.map(({ id }) => id));

      setChips((chipsCurrent) =>
        chipsCurrent.filter((chip) => chipIds.has(chip.searchTerm)),
      );
    },
    [setChips],
  );

  return (
    <StihlFilterChips
      chips={filterChips}
      setChips={setFilterChips}
      error={
        isInvalidInput && (
          <StihlHelperText
            text="filterLabel.duplicateError"
            data-testid="errorText"
          />
        )
      }
      clearAllLabel={t('filterLabel.clearAllFilters')}
      isClearAllFiltersButtonShown={isClearAllFiltersButtonShown}
    />
  );
};
