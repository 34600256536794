import type { AppAuthenticationErrorCode } from './app-authentification-error';
import { AppAuthenticationError } from './app-authentification-error';

function parseErrorCode(
  error: string,
  errorDescription: string | null,
): AppAuthenticationErrorCode {
  if (error === 'access_denied') {
    return 'LoginAccessDenied';
  }

  const errorDescriptionString = errorDescription
    ? ` (${errorDescription})`
    : '';

  throw new TypeError(
    `Unexpected authentication error: ${error}${errorDescriptionString}`,
  );
}

export function parseAppAuthenticationErrorFromRedirectUrl(
  url: Pick<URL, 'search'>,
): AppAuthenticationError {
  const urlParameters = new URLSearchParams(url.search);

  const error = urlParameters.get('error') ?? '';
  const errorDescription = urlParameters.get('error_description');

  const code = parseErrorCode(error, errorDescription);

  const errorDescriptionString = errorDescription
    ? ` (${errorDescription})`
    : '';

  return new AppAuthenticationError(
    `Unexpected authentication error with error code ${code}${errorDescriptionString}`,
    { code },
  );
}

export function isAuthenticationRedirectUrlWithError(
  url: Pick<URL, 'search'>,
): boolean {
  return (
    url.search.includes('error=') || url.search.includes('error_description=')
  );
}
