import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useMemo, useSyncExternalStore } from 'react';
import instance from '../../app-shell/service/axios';
import type { AppAuthenticationClient } from './app-authentication-client/app-authentication-client';
import { useAppAuthenticationSessionContext } from './app-authentication-session/app-authentication-session-provider';

export function addTokensInterceptor(
  client: AppAuthenticationClient,
): (config: InternalAxiosRequestConfig) => Promise<InternalAxiosRequestConfig> {
  return async (
    config: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> => {
    const user = await client.getUser();

    config.headers['Ocp-Apim-Subscription-Key'] = process.env
      .REACT_APP_IMOW_API_SUBSCRIPTION_KEY as string;

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!config.headers.IdToken) {
      config.headers.IdToken = `Bearer ${user.id_token}`;
    }
    return config;
  };
}

export function handleAuthError(
  authenticationClient: AppAuthenticationClient,
): (error: AxiosError) => Promise<AxiosResponse> {
  return async (error: AxiosError) => {
    const originalConfig =
      error.config as InternalAxiosRequestConfig<unknown> & {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _retry?: boolean;
      };

    if (
      Boolean(error.response) && // Access Token was expired
      error.response?.status === 401 &&
      // eslint-disable-next-line no-extra-boolean-cast
      !Boolean(originalConfig._retry)
    ) {
      console.warn('Access token expired requesting new token');
      originalConfig._retry = true;

      try {
        const user = await authenticationClient.getUser();

        originalConfig.headers.Authorization = `Bearer ${user.access_token}`;
        originalConfig.headers.IdToken = `Bearer ${user.id_token}`;

        return await instance(originalConfig);
      } catch (refreshError) {
        authenticationClient.setStateToLoggedOutDueToInvalidAuthentication();
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(refreshError);
      }
    } else if (
      Boolean(error.response) && // Access Token was expired
      error.response?.status === 401
    ) {
      authenticationClient.setStateToLoggedOutDueToInvalidAuthentication();
    }
    // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
    return Promise.reject(error);
  };
}

export const useAxiosInterceptors = (): void => {
  const { appAuthenticationClient } = useAppAuthenticationSessionContext();

  const interceptorStore = useMemo(() => {
    return {
      subscribe: () => {
        const requestInterceptor = instance.interceptors.request.use(
          // eslint-disable-next-line @getify/proper-ternary/where
          appAuthenticationClient
            ? addTokensInterceptor(appAuthenticationClient)
            : (response) => response,
          (error) => {
            throw error;
          },
        );
        const responseInterceptor = instance.interceptors.response.use(
          (response) => response,
          // eslint-disable-next-line @getify/proper-ternary/where
          appAuthenticationClient
            ? handleAuthError(appAuthenticationClient)
            : (error) => {
                throw error;
              },
        );

        return () => {
          instance.interceptors.request.eject(requestInterceptor);
          instance.interceptors.response.eject(responseInterceptor);
        };
      },
      getValue: () => null,
    };
  }, [appAuthenticationClient]);

  useSyncExternalStore(interceptorStore.subscribe, interceptorStore.getValue);
};
