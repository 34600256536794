import type { SubscribeToValueOrError } from './_subscribe/subscribe-abortable';
import { subscribeAbortable } from './_subscribe/subscribe-abortable';
import type {
  AbortCause,
  AbortSignalWithCauseMaybe,
} from './abort-controller-with-cause';
import { toAbortErrorFromCauseMaybe } from './abort-controller-with-cause';

type CreateAbortError = (abortCause?: AbortCause) => Error;

function createAbortErrorWaitForEvent(
  abortCause: AbortCause = 'waiting for event',
): Error {
  /* istanbul ignore next – pure delegation */
  return toAbortErrorFromCauseMaybe(abortCause);
}

type WaitForEventOptions<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = {
  subscribe: SubscribeToValueOrError<TValue, TError>;
  signal?: TAbortSignal;
  createAbortError?: CreateAbortError;
};

/**
 * Waits for an event to occur and resolves with its value.
 *
 * @param subscribe Subscribes to the event and returns an unsubscribe function.
 * This function passes it's `handle` callback that you call
 * - when the event value you are looking for has occurred, i.e. if you wait
 *   for any event pass `handle` to the underlying event dispatcher
 *   (e.g. `addEventListener`) and if you wait for a specific event define
 *   your own handler and pass that to the event dispatcher. Your handler
 *   checks the event and only calls `handle` if its the awaited event.
 * - the value you wish to to resolve the promise with, i.e. you are able
 *   to select a specific argument from the event callback or map it.
 * @param signal to abort waiting, e.g. after a {@link abortOnTimeout}.
 * Optionally supports a `cause` to determine which error to throw when aborted.
 * @param createAbortError Determines the error to throw when aborted.
 * Defaults to {@link AbortError} or `signal.cause` if it references an error.
 *
 * @throws AbortError or a custom error defined by `signal.cause` and/or
 * `createAbortError` if aborted.
 */
export async function waitForEvent<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>({
  subscribe,
  signal,
  createAbortError = createAbortErrorWaitForEvent,
}: WaitForEventOptions<TValue, TError, TAbortSignal>): Promise<TValue> {
  return new Promise((resolve, reject) => {
    subscribeAbortable({
      subscribe,
      signal,
      isDone: true,
      onValue: resolve,
      onError: reject,
      onAbort: (signalAborted) => {
        reject(createAbortError(signalAborted.cause));
      },
    });
  });
}
