import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StihlTooltipCentered from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-centered';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconUserLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-user-link';
import type {
  CustomerResult,
  DeviceResult,
  DeviceResultNotProvisioned,
} from '../../../model/card.model';
import DeviceSearchResultDeviceEntry from './device-search-result-device-entry';
import DeviceSearchResultDeviceEntryNotProvisioned from './device-search-result-device-entry-not-provisioned';
import { StyledBodyTableCell } from './device-search-result-shared';

const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

type DeviceSearchResultCustomerEntryProps = {
  customer: CustomerResult | undefined;
  devices: (DeviceResult | DeviceResultNotProvisioned)[];
  shouldShowCustomerInformation: boolean;
  onOpenDevice: (device: DeviceResult) => void;
};

const DeviceSearchResultCustomerInvalidTooltipTableCell: FC<{
  canAccessCustomerAccount: boolean;
  isAccountValid: boolean;
  children: ReactNode;
}> = ({ canAccessCustomerAccount, isAccountValid, children }) => {
  const { t } = useTranslation();

  if (canAccessCustomerAccount)
    return <StyledBodyTableCell>{children}</StyledBodyTableCell>;

  if (isAccountValid) {
    return (
      <StihlTooltipCentered title={t('customerDetails.notPreferredDealer')}>
        <StyledBodyTableCell>{children}</StyledBodyTableCell>
      </StihlTooltipCentered>
    );
  }

  return (
    <StihlTooltipCentered title={t('customerDetails.invalidAccount')}>
      <StyledBodyTableCell>{children}</StyledBodyTableCell>
    </StihlTooltipCentered>
  );
};

const DeviceSearchResultCustomerEntry: FC<
  DeviceSearchResultCustomerEntryProps
> = ({ customer, devices, shouldShowCustomerInformation, onOpenDevice }) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      {devices.map((device, deviceIndex) => {
        const customerRole = shouldShowCustomerInformation
          ? customer?.devices.find(
              (customerDevice) =>
                customerDevice.serialNumber === device.serialNumber,
            )?.type ?? null
          : undefined;

        return (
          <TableRow
            key={'deviceId' in device ? device.deviceId : device.serialNumber}
          >
            {shouldShowCustomerInformation &&
              (deviceIndex > 0 ? (
                <StyledBodyTableCell colSpan={4} />
              ) : customer == null ? (
                <StyledBodyTableCell colSpan={4}>
                  <IconAligner>
                    <Typography component="span">
                      {t('searchResultTable.noCustomerInformation')}
                    </Typography>
                    <StihlTooltipCentered
                      title={t(
                        // eslint-disable-next-line no-secrets/no-secrets
                        'searchResultTable.noCustomerInformationTooltip',
                      )}
                    >
                      <StihlIconInfo />
                    </StihlTooltipCentered>
                  </IconAligner>
                </StyledBodyTableCell>
              ) : (
                <>
                  <DeviceSearchResultCustomerInvalidTooltipTableCell
                    {...customer}
                  >
                    {customer.name}
                  </DeviceSearchResultCustomerInvalidTooltipTableCell>
                  <DeviceSearchResultCustomerInvalidTooltipTableCell
                    {...customer}
                  >
                    {customer.email}
                  </DeviceSearchResultCustomerInvalidTooltipTableCell>
                  <DeviceSearchResultCustomerInvalidTooltipTableCell
                    {...customer}
                  >
                    {customer.location}
                  </DeviceSearchResultCustomerInvalidTooltipTableCell>
                  <StyledBodyTableCell>
                    {
                      // eslint-disable-next-line unicorn/no-negated-condition
                      !customer.isUserPreferredDealer ? null : customer.isAccountValid ? (
                        <IconButton<typeof Link>
                          component={Link}
                          to={{
                            pathname: '/customer-management',
                            state: { filterEmail: customer.email },
                          }}
                        >
                          <StihlIconUserLink color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton disabled>
                          <StihlIconUserLink color="grey.500" />
                        </IconButton>
                      )
                    }
                  </StyledBodyTableCell>
                </>
              ))}

            {'deviceId' in device ? (
              <DeviceSearchResultDeviceEntry
                device={device}
                customerRole={customerRole}
                onOpenDevice={onOpenDevice}
              />
            ) : (
              <DeviceSearchResultDeviceEntryNotProvisioned
                device={device}
                customerRole={customerRole}
              />
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
export default DeviceSearchResultCustomerEntry;
