import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';

type JumpToAppTooltipProps = {
  customerAppAccess: 'allowed' | 'no-customer-data' | 'not-preferred-dealer';
  children: ReactNode;
};

const JumpToAppTooltip: FC<JumpToAppTooltipProps> = ({
  customerAppAccess,
  children,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (customerAppAccess === 'allowed') return <>{children}</>;

  let tooltipContent = '';

  if (customerAppAccess === 'not-preferred-dealer') {
    tooltipContent = t('jumpToApp.notPreferredDealerTooltip');
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (customerAppAccess === 'no-customer-data') {
    tooltipContent = t('jumpToApp.noCustomerDataTooltip');
  }
  return (
    <StihlTooltip title={tooltipContent} data-testid={customerAppAccess}>
      <div>{children}</div>
    </StihlTooltip>
  );
};

export default JumpToAppTooltip;
