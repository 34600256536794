import IconButton from '@mui/material/IconButton';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenClose } from '../../../../../base/extras-react/use-open-close';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconCustomerDisconnect from '../../../../../base/stihl-material-ui/icons/stihl-icon-customer-disconnect';
import StihlIconInvalid from '../../../../../base/stihl-material-ui/icons/stihl-icon-invalid';
import StihlIconMowerLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import CustomerDisconnectDeviceModal from '../../../../customer-management/ui/customer-disconnect-device-modal/customer-disconnect-device-modal';
import type { DeviceResult } from '../../../model/card.model';
import DeviceActivationStateChangeModal from '../../device-activation-state/device-activation-state-change-modal';
import {
  BodyTableCellHoverable,
  DeviceSearchResultDeviceCustomerRole,
} from './device-search-result-device-entry-shared';

type DeviceSearchResultDeviceEntryProps = {
  device: DeviceResult;
  customerRole?: 'OWNER' | 'DEPUTY' | null;
  onOpenDevice: (device: DeviceResult) => void;
};

const DeviceSearchResultDeviceEntryDisconnectDevice: FC<{
  device: DeviceResult;
}> = ({ device }) => {
  const { t } = useTranslation();

  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useOpenClose();

  return (
    <>
      <StihlTooltip title={t('searchResultTable.disconnectDevice')}>
        <IconButton onClick={openModal}>
          <StihlIconCustomerDisconnect size={16} />
        </IconButton>
      </StihlTooltip>

      <CustomerDisconnectDeviceModal
        open={isModalOpen}
        onClose={closeModal}
        serialNumber={device.serialNumber}
        deviceModel={device.deviceModel}
      />
    </>
  );
};

const DeviceSearchResultDeviceEntryActivationState: FC<{
  device: DeviceResult;
}> = ({ device }) => {
  const { t } = useTranslation();

  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useOpenClose();

  const isCertificateDisabled = device.iotDeviceActivationStatus === 'disabled';

  if (!device.canChangeActivationState) {
    return (
      <StihlTooltip
        title={
          isCertificateDisabled
            ? t('searchResultTable.activationState.deviceIdDisabled')
            : t('searchResultTable.activationState.deviceIdEnabled')
        }
      >
        <StihlIconInvalid color={isCertificateDisabled ? 'error' : 'black'} />
      </StihlTooltip>
    );
  }

  return (
    <>
      <StihlTooltip
        title={
          isCertificateDisabled
            ? t('searchResultTable.activationState.enableDeviceId')
            : t('searchResultTable.activationState.disableDeviceId')
        }
      >
        <IconButton onClick={openModal}>
          <StihlIconInvalid color={isCertificateDisabled ? 'error' : 'black'} />
        </IconButton>
      </StihlTooltip>

      <DeviceActivationStateChangeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        device={device}
      />
    </>
  );
};

const DeviceSearchResultDeviceEntry: FC<DeviceSearchResultDeviceEntryProps> = ({
  device,
  onOpenDevice,
  customerRole,
}) => {
  const handleClick = useCallback(() => {
    onOpenDevice(device);
  }, [onOpenDevice, device]);

  return (
    <>
      {customerRole !== undefined && (
        <BodyTableCellHoverable>
          <DeviceSearchResultDeviceCustomerRole role={customerRole} />
        </BodyTableCellHoverable>
      )}
      <BodyTableCellHoverable>{device.serialNumber}</BodyTableCellHoverable>
      <BodyTableCellHoverable>{device.deviceId}</BodyTableCellHoverable>
      <BodyTableCellHoverable>{device.deviceModel}</BodyTableCellHoverable>
      <BodyTableCellHoverable>{device.deviceType}</BodyTableCellHoverable>
      <BodyTableCellHoverable style={{ whiteSpace: 'nowrap' }}>
        {device.canDisconnectDevice && (
          <DeviceSearchResultDeviceEntryDisconnectDevice device={device} />
        )}

        <DeviceSearchResultDeviceEntryActivationState device={device} />
      </BodyTableCellHoverable>
      <BodyTableCellHoverable>
        <IconButton onClick={handleClick}>
          <StihlIconMowerLink color="stihlOrange" />
        </IconButton>
      </BodyTableCellHoverable>
    </>
  );
};
export default DeviceSearchResultDeviceEntry;
