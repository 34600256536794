/**
 * Maximum value for {@link setTimeout} (INT32).
 */
export const JS_TIMEOUT_MILLISECONDS_MAX = 2 ** 31 - 1;

/**
 * Checks range limits for {@link setTimeout}.
 *
 * @param milliseconds
 * @see https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
 */
export function isTimeoutInRange(milliseconds: number): boolean {
  return milliseconds >= 0 && milliseconds <= JS_TIMEOUT_MILLISECONDS_MAX;
}

export function assertTimeoutInRange(milliseconds: number): void {
  if (!isTimeoutInRange(milliseconds)) {
    throw new RangeError(
      `Timeout has to be between 0 and ${JS_TIMEOUT_MILLISECONDS_MAX}: ${milliseconds}`,
    );
  }
}
