import { Global, css } from '@emotion/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-unassigned-import
import './i18n';
import App from './app/app';
import { stihlColor } from './base/stihl-material-ui/theme/stihl-style-guide';
import { AlertProvider } from './modules/app-alert/service/alert-provider';
import AppAuthenticationSessionProvider from './modules/app-authentication/service/app-authentication-session/app-authentication-session-provider';
import { DeviceManagementStoreProvider } from './modules/device-management/service/device-management-store/device-management-store-provider';

const queryCache = new QueryCache({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
  queryCache,
});

const rootElement = document.querySelector('#root');

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <DeviceManagementStoreProvider>
        <AlertProvider>
          <QueryClientProvider client={queryClient}>
            <Global
              styles={css`
                body {
                  background-color: ${stihlColor.background};
                }
              `}
            />
            <Suspense fallback="Loading...">
              <AppAuthenticationSessionProvider>
                {/* eslint-disable-next-line react/jsx-max-depth */}
                <App />
              </AppAuthenticationSessionProvider>
            </Suspense>
          </QueryClientProvider>
        </AlertProvider>
      </DeviceManagementStoreProvider>
    </StrictMode>,
  );
}
