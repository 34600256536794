/**
 * This error is thrown if the user disabled a certain feature for the current browsing context.
 *
 * Examples include:
 *
 * A WebStorage is not available due to a usage with the `file:` or `data:` protocol or it is deactivated by the user.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage#exceptions
 *
 * When trying to request a MediaDevice (such as a camera, microphone or screen sharing) but the user does not allow it.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
 */
export function isSecurityError(error: unknown): boolean {
  return (
    error instanceof DOMException &&
    (error.name === 'SECURITY_ERR' || error.name === 'SecurityError')
  );
}

/**
 * This error is thrown if a certain quota is exceeded. This mostly includes `localStorage`, `sessionStorage` or `indexedDB`.
 * This error is thrown if there is no space left in the storage.
 *
 * This might be the case because the storage is simply full,
 * or in some browsers the maximum size is set to zero in private browsing mode.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#exceptions
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IDBTransaction/error
 */
export function isQuotaExceededError(error: unknown): boolean {
  return (
    error instanceof DOMException &&
    (error.name === 'QUOTA_EXCEEDED_ERR' || error.name === 'QuotaExceededError')
  );
}
