import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import QueryComponent from '../../../app-shell/ui/query-component';
import type { DeviceModel, DiagnosisSessionData } from '../../model';
import type { SearchObject } from '../../model/search.model';
import { SearchParameterValues } from '../../model/search.model';
import { useDeviceDetailsSearch } from '../../service/device-service/device-service';
import DeviceTabsDeviceDetails from '../tabs/device-tabs-device-details';
import { useDeviceDetailsAlert } from './device-details-alert';

export const DeviceDetails: FC = () => {
  const { state, search } = useLocation<SearchObject | null>();

  const [searchValue, setSearch] = useState<SearchObject | undefined>();

  useEffect(() => {
    if (state) {
      setSearch(state);
    }

    const query = new URLSearchParams(search);

    const serialNumber = query.get('serialNumber');
    const deviceModel = query.get('deviceModel') as DeviceModel | 'any' | null;

    if (serialNumber != null && deviceModel != null) {
      setSearch({
        selector: SearchParameterValues.SerialNumber,
        deviceModel,
        search: serialNumber,
      });
    }
  }, [state, search]);

  const deviceData = useDeviceDetailsSearch(searchValue);

  useDeviceDetailsAlert(deviceData);

  useEffect(() => {
    // reset search if there was an error
    // -> this shows the search box again
    if (deviceData.error && Boolean(searchValue?.search)) {
      setSearch(undefined);
    }
  }, [deviceData.error, searchValue?.search]);

  const [diagnosisDataArray, setDiagnosisDataArray] = useState<
    DiagnosisSessionData[] | undefined
  >(undefined);

  return (
    <QueryComponent data={deviceData}>
      {deviceData.data && (
        <DeviceTabsDeviceDetails
          deviceData={deviceData}
          diagnosisDataArray={diagnosisDataArray}
          setDiagnosisDataArray={setDiagnosisDataArray}
        />
      )}
    </QueryComponent>
  );
};

export default DeviceDetails;
