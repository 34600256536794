import type { ErrorCauseOption } from './error-cause';
import { initErrorCause } from './error-cause';
import type { ErrorCode, ErrorCodeOption } from './error-code';

export type ErrorBaseOptions<
  TCode extends ErrorCode = ErrorCode,
  TCause extends Error = Error,
> = ErrorCodeOption<TCode> & ErrorCauseOption<TCause>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NoInfer<T> = [T][T extends any ? 0 : never];

export type ErrorBaseCodedOptions<
  TCode extends ErrorCode = ErrorCode,
  TCause extends Error = Error,
> = Required<ErrorCodeOption<TCode>> & ErrorCauseOption<TCause>;

/**
 * Call in your custom {@link Error} constructor to initialize base properties.
 *
 * - [`cause`]{@link initErrorCause} if not already defined by `super` constructor.
 * - `code`.
 * - {@link Error.captureStackTrace} for node.
 *
 * @param errorConstructing
 * @param options
 * @param Constructor
 */
export function initErrorBase<
  TOptions extends ErrorBaseOptions,
  TError extends Error & Omit<TOptions, 'cause'>,
>(
  /*
  use `NoInfer` to prevent inferring `this` causing type error for `Constructor`
  TS2345: Argument of type 'typeof CustomError' is not assignable to parameter of type 'new (...args: never[]) => this'
   */
  errorConstructing: NoInfer<TError>,
  options?: TOptions,
  Constructor?: new (...args: never[]) => TError,
): TError {
  if (options != null) {
    initErrorCause(errorConstructing, options);
    errorConstructing.code = options.code;
  }
  if (Constructor != null && typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(errorConstructing, Constructor);
  }

  return errorConstructing;
}
