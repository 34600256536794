import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  UseQueryResult,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { FilterParameterValue } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { ChipType } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import type { DeviceModel } from '../../../device/model';
import type { ManagedDevice } from '../../model/overview.models';
import type { SoftwareRelease } from '../../model/release-management/release-management.model';
import type { ActivationStateRequestBody } from '../../model/search.models';
import type { SoftwareVersionMetadata } from '../../model/software-versions.model';
import type { DeviceStatistics } from '../../model/statistics.model';
import type { SwMassAssignment } from '../../model/update-management.model';
import {
  enableOrDisableDeviceId,
  getDeviceManagementTabs,
  getManagedDeviceDetails,
  getManagedDevices,
  getMassAssignments,
  getSoftwareReleases,
  getSoftwareVersions,
  getSoftwareVersionsMetadata,
  getStatistics,
  invalidateMassAssignment,
} from '../device-management-api-client/device-management-api-client';

export function useDeviceManagementTabs(): UseQueryResult<
  TabsResponse[],
  Error
> {
  return useQuery<TabsResponse[], Error>({
    queryKey: ['deviceManagementTabs'],
    queryFn: async ({ signal }) => getDeviceManagementTabs(signal),
  });
}

export function useDeviceStatistics(
  processingChain: string,
  deviceModel: string | undefined,
): UseQueryResult<DeviceStatistics, Error> {
  return useQuery<DeviceStatistics, Error>({
    queryKey: ['statistics', processingChain, deviceModel],
    queryFn: async ({ signal }) =>
      getStatistics(processingChain, deviceModel as string, signal),
    enabled: deviceModel != null,
  });
}

export function useManagedDevices(
  chips: ChipType[],
): UseQueryResult<ManagedDevice[], Error> {
  const parameters = new URLSearchParams();

  const serialNumbersArray = chips
    .filter(
      (serialNumberChip) =>
        serialNumberChip.filter.toString() === 'serialNumber',
    )
    .map((serialNumberChip) => serialNumberChip.searchTerm);
  const serialNumbersString = serialNumbersArray.join(',');
  if (serialNumbersArray.length > 0) {
    parameters.append('deviceSerialNumber', serialNumbersString);
  }

  const deviceIdsArray = chips
    .filter((deviceIdChip) => deviceIdChip.filter.toString() === 'deviceId')
    .map((deviceIdChip) => deviceIdChip.searchTerm);
  const deviceIdsString = deviceIdsArray.join(',');
  if (deviceIdsArray.length > 0) {
    parameters.append('deviceId', deviceIdsString);
  }

  const reducedChips = chips.filter(
    (chip) =>
      chip.filter.toString() !== 'serialNumber' &&
      chip.filter.toString() !== 'deviceId',
  );

  for (const chip of reducedChips) {
    if (chip.filter === FilterParameterValue.RangeOfSerialnumbers) {
      const searchTerms = chip.searchTerm.split(' - ');
      parameters.append('serialNumberStart', searchTerms[0]);
      parameters.append('serialNumberEnd', searchTerms[1]);
    } else if (
      chip.filter === FilterParameterValue.RangeOfProductionDates &&
      typeof chip.value === 'object'
    ) {
      parameters.append(
        'productionDatesStart',
        chip.value.range[0]?.toISOString() ?? '',
      );
      parameters.append(
        'productionDatesEnd',
        chip.value.range[1]?.toISOString() ?? '',
      );
    } else {
      parameters.append(chip.filter, chip.searchTerm);
    }
  }
  return useQuery<ManagedDevice[], Error>({
    // the parameters are only dependent on the chips
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['managedDevices', chips],
    queryFn: async ({ signal }) => getManagedDevices(parameters, signal),
    enabled: false,
    gcTime: 0,
  });
}

export function useManagedDeviceDetails(
  deviceId: string,
  deviceModel: DeviceModel | undefined,
): UseQueryResult<ManagedDevice, Error> {
  return useQuery<ManagedDevice, Error>({
    queryKey: ['managedDeviceDetails', deviceModel, deviceId],
    queryFn: async ({ signal }) =>
      getManagedDeviceDetails(deviceId, deviceModel, signal),
    enabled: deviceId !== '',
  });
}

export function useSoftwareVersions(
  deviceModel?: DeviceModel,
): UseQueryResult<string[], Error> {
  return useQuery<string[], Error>({
    queryKey: ['softWareVersions', deviceModel],
    queryFn: async ({ signal }) =>
      getSoftwareVersions(deviceModel as DeviceModel, signal),
    enabled: deviceModel != null,
  });
}

export function useSoftwareVersionsMetadata(
  deviceModel: DeviceModel,
  shouldFetchOnlyValid: boolean,
): UseQueryResult<SoftwareVersionMetadata[], Error> {
  return useQuery<SoftwareVersionMetadata[], Error>({
    queryKey: ['softwareVersionsMetadata', deviceModel, shouldFetchOnlyValid],
    queryFn: async ({ signal }) =>
      getSoftwareVersionsMetadata(deviceModel, shouldFetchOnlyValid, signal),
  });
}

const massAssignmentsQueryKey = (
  deviceModel: DeviceModel | undefined,
): string[] => {
  return ['massAssignments', deviceModel as string];
};

export function useMassAssignments(
  deviceModel: DeviceModel | undefined,
): UseQueryResult<SwMassAssignment[], Error> {
  return useQuery<SwMassAssignment[], Error>({
    queryKey: massAssignmentsQueryKey(deviceModel),
    queryFn: async ({ signal }) =>
      getMassAssignments(deviceModel as DeviceModel, signal),
    enabled: deviceModel != null,
  });
}

export function useInvalidateMassAssignment(
  deviceModel: DeviceModel,
): UseMutationResult<void, unknown, number, unknown> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (assignmentId: number) =>
      invalidateMassAssignment(assignmentId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: massAssignmentsQueryKey(deviceModel),
      });
    },
  });
}

export function useSoftwareReleases(
  deviceModel: DeviceModel | undefined,
): UseQueryResult<SoftwareRelease[], Error> {
  return useQuery<SoftwareRelease[], Error>({
    queryKey: ['softwareReleases', deviceModel],
    queryFn: async ({ signal }) =>
      getSoftwareReleases(deviceModel as DeviceModel, signal),
    enabled: deviceModel != null,
  });
}

export function useEnableOrDisableDeviceId(
  options?: Pick<
    UseMutationOptions<
      unknown,
      AxiosError,
      ActivationStateRequestBody,
      unknown
    >,
    'onSuccess' | 'onError' | 'onSettled' | 'onMutate'
  >,
): UseMutationResult<
  AxiosResponse<unknown, unknown>,
  AxiosError,
  ActivationStateRequestBody,
  unknown
> {
  return useMutation({
    ...options,
    mutationFn: async (body: ActivationStateRequestBody) =>
      enableOrDisableDeviceId(body),
  });
}
