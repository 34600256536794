import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAppAuthenticationSessionAuthenticated } from '../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import type { DeviceModel } from '../../../device/model';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type DeviceModelFilterProps = {
  deviceModel: DeviceModel | undefined;
  setDeviceModel:
    | ((deviceModel: DeviceModel) => void)
    | ((deviceModel: DeviceModel | undefined) => void);
  disabled?: boolean;
  hasEmptyOption?: boolean;
};

export const DeviceModelFilter = ({
  deviceModel,
  setDeviceModel,
  disabled = false,
  hasEmptyOption = false,
}: DeviceModelFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const session = useAppAuthenticationSessionAuthenticated();

  function handleDeviceModelChange(event: ChangeEvent<HTMLInputElement>): void {
    setDeviceModel(event.target.value as DeviceModel);
  }

  const labelId = useId();

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        id={labelId}
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('filterLabel.deviceModel')}
      </InputLabel>
      <StihlSelect
        SelectProps={{ labelId }}
        value={deviceModel ?? ''}
        onChange={handleDeviceModelChange}
        fullWidth
        disabled={disabled}
        data-testid="deviceModelFilter"
      >
        {hasEmptyOption && (
          <MenuItem value="" disabled>
            {t('deviceModelFilter.emptyPlaceholder')}
          </MenuItem>
        )}
        {session.deviceModels.map((deviceModelAvailable) => (
          <MenuItem key={deviceModelAvailable} value={deviceModelAvailable}>
            {deviceModelAvailable}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledWrapper>
  );
};

export default DeviceModelFilter;
