import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppAuthenticationSessionAuthenticated } from '../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import QueryComponent from '../../../app-shell/ui/query-component';
import type { DeviceModel } from '../../../device/model';
import { useSoftwareReleases } from '../../service/device-management-service/device-management-service';
import DeviceModelFilter from '../filters/device-model-filter';
import { ReleaseManagementTable } from './release-management-table';

export const ReleaseManagementOverview = ({
  handleAddMetadata,
}: {
  handleAddMetadata: (
    deviceModel: DeviceModel,
    version: string,
    fileName: string,
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const session = useAppAuthenticationSessionAuthenticated();
  const [deviceModel, setDeviceModel] = useState<DeviceModel | undefined>(
    session.deviceModels[0],
  );

  if (deviceModel === undefined && session.deviceModels.length > 0) {
    setDeviceModel(session.deviceModels[0]);
  }

  const softwareReleases = useSoftwareReleases(deviceModel);

  return (
    <>
      <div style={{ maxWidth: '250px' }}>
        <DeviceModelFilter
          deviceModel={deviceModel}
          setDeviceModel={setDeviceModel}
          data-testid="deviceModelFilter"
        />
      </div>
      <Typography mt="1rem" mb="1rem" data-testid="releaseManagement">
        {t('releaseManagement.noChangesHint')}{' '}
      </Typography>
      <QueryComponent data={softwareReleases}>
        {softwareReleases.data && (
          <ReleaseManagementTable
            softwareReleases={softwareReleases.data}
            handleAddMetadata={handleAddMetadata}
            deviceModel={deviceModel as DeviceModel}
          />
        )}
      </QueryComponent>
    </>
  );
};
