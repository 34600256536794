import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AppAuthenticationSessionDealer } from '../../../app-authentication/model/authentication-session';
import { AppAuthenticationType } from '../../../app-authentication/model/authentication-session';
import { useAppAuthenticationSessionAuthenticated } from '../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import type { DealerDetails } from '../../model/dealer-details';
import { getDealerAccountDetails } from './dealer-details-api';

export const useDealerDetails = (): UseQueryResult<DealerDetails> => {
  const authenticationSession = useAppAuthenticationSessionAuthenticated();

  const { dealerId } = authenticationSession as AppAuthenticationSessionDealer;

  return useQuery({
    queryKey: ['dealerDetails', dealerId],
    queryFn: async ({ signal }) => getDealerAccountDetails(dealerId, signal),
    enabled: authenticationSession.authType === AppAuthenticationType.Dealer,
    // 1h - this does not really change -> we don't need to reload all the time
    staleTime: 1000 * 60 * 60,
  });
};
