import styled from '@emotion/styled';
import TableCell from '@mui/material/TableCell';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';

export const StyledHeadingTableCell = styled(TableCell)`
  color: ${stihlColor.fontGrey};
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

export const StyledBodyTableCell = styled(TableCell)`
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

export const StyledBodyTableCellPrimary = styled(StyledBodyTableCell)`
  font-weight: 600;
`;
