import { subscribeAbortSignal } from './_subscribe/subscribe-abort-signal';
import type {
  AbortErrorFromSignalWithCauseMaybe,
  AbortSignalWithCauseMaybe,
} from './abort-controller-with-cause';
import { getAbortErrorFromSignalWithCauseMaybe } from './abort-controller-with-cause';

type AbortPromiseSettlement<
  TAbortSignal extends AbortSignalWithCauseMaybe,
  TAbortValue,
  TAbortError extends Error,
> = {
  /**
   * If defined the promise will resolve with this value if `aborted`.
   */
  abortValue?: TAbortValue | ((signal: TAbortSignal) => TAbortValue);
  // eslint-disable-next-line no-secrets/no-secrets
  /**
   * If defined (and if {@link abortValue} is not defined) the promise will
   * reject with this error.
   *
   * Defaults to use the {@link AbortSignalWithCauseMaybe#cause} or
   * falls back to {@link AbortError}.
   *
   * @default {@link getAbortErrorFromSignalWithCauseMaybe}
   */
  abortError?: TAbortError | ((signal: TAbortSignal) => TAbortError);
};

type AbortValueOrErrorResolved<TAbortValue, TAbortError extends Error> =
  | { abortValue?: undefined; abortError: TAbortError }
  | { abortValue: TAbortValue; abortError?: undefined };

function resolveAbortValueOrError<
  TAbortSignal extends AbortSignalWithCauseMaybe,
  TAbortValue,
  TAbortError extends Error = AbortErrorFromSignalWithCauseMaybe<TAbortSignal>,
>(
  options: AbortPromiseSettlement<TAbortSignal, TAbortValue, TAbortError>,
  target: TAbortSignal,
): AbortValueOrErrorResolved<TAbortValue, TAbortError> {
  const {
    abortValue: abortValueOrGetter,
    abortError: abortErrorOrGetter = getAbortErrorFromSignalWithCauseMaybe as (
      signal: TAbortSignal,
    ) => TAbortError,
  } = options;

  let abortValue: TAbortValue | undefined;
  let abortError: TAbortError | undefined;

  if ('abortValue' in options) {
    abortValue =
      typeof abortValueOrGetter === 'function'
        ? (abortValueOrGetter as (target: TAbortSignal) => TAbortValue)(target)
        : abortValueOrGetter;
  } else {
    abortError =
      typeof abortErrorOrGetter === 'object'
        ? abortErrorOrGetter
        : abortErrorOrGetter(target);
  }

  return { abortValue, abortError } as AbortValueOrErrorResolved<
    TAbortValue,
    TAbortError
  >;
}

type WaitForAbortOptions<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
  TAbortValue,
  TAbortError extends Error,
> = {
  /**
   * Awaited {@link AbortSignal#aborted}.
   */
  target: TAbortSignalAwaited;
  /**
   * Signals to stop waiting for {@link target} being `aborted` and removes the
   * `abort` event listener to prevent memory leaks.
   * Use to signal when the operation has ended (either succeeded, failed or
   * aborted otherwise) and waiting for abort is no more necessary.
   */
  signal?: TAbortSignal;
} & AbortPromiseSettlement<
  TAbortSignalAwaited | TAbortSignal,
  TAbortValue,
  TAbortError
>;
/**
 * Promise resolve value if defined or `never` meaning promise rejects
 * (`Promise.reject()` has type `Promise<never>`).
 *
 * Using `never` as default for value instead of `undefined` enables to use
 * `{ abortValue: undefined }`.
 */
type AbortValueOrErrorResult<TAbortValue = never> = [TAbortValue] extends [
  never,
]
  ? never
  : TAbortValue;

/**
 * Converts a target {@link AbortSignal} to a promise that either resolves with
 * a value or rejects with an error when the signal is/will be {@link AbortSignal#aborted}.
 *
 * @param options
 */
// eslint-disable-next-line max-lines-per-function
export async function waitForAbort<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
  TAbortValue = never,
  TAbortError extends Error = AbortErrorFromSignalWithCauseMaybe<TAbortSignal>,
>(
  options: WaitForAbortOptions<
    TAbortSignalAwaited,
    TAbortSignal,
    TAbortValue,
    TAbortError
  >,
): Promise<AbortValueOrErrorResult<TAbortValue>> {
  const { target, signal } = options;
  return new Promise<AbortValueOrErrorResult<TAbortValue>>(
    (resolve, reject) => {
      subscribeAbortSignal({
        target,
        signal,
        onTargetAbort: () => {
          const { abortValue, abortError } = resolveAbortValueOrError(
            options,
            target,
          );

          if (abortError == null) {
            resolve(abortValue as AbortValueOrErrorResult<TAbortValue>);
          } else {
            reject(abortError);
          }
        },
        onSignalAbort: (signalAborted) => {
          const { abortValue, abortError } = resolveAbortValueOrError(
            options,
            signalAborted,
          );

          if (abortError == null) {
            resolve(abortValue as AbortValueOrErrorResult<TAbortValue>);
          } else {
            reject(abortError);
          }
        },
      });
    },
  );
}

/**
 * Converts an {@link AbortSignal} to a promise that resolves with
 * `true` when the signal is already {@link AbortSignal#aborted} or when
 * its `abort` event is dispatched.
 *
 * @param target
 * @param signal Stops waiting for the `abort` and removes the `abort` event
 * listener to prevent memory leaks.
 * Use to signal when the operation has ended (either succeeded, failed or
 * aborted otherwise) and waiting for abort is no more necessary.
 */
export async function waitForAborted<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(target: TAbortSignalAwaited, signal: TAbortSignal): Promise<boolean> {
  return waitForAbort({
    target,
    signal,
    abortValue: (signalAborted) => {
      return signalAborted === signal ? false : target.aborted;
    },
  });
}

// eslint-disable-next-line no-secrets/no-secrets
/**
 * Converts an {@link AbortSignal} to a promise that rejects when the signal
 * is already {@link AbortSignal#aborted} or when its `abort` event is dispatched.
 *
 * @param target
 * @param signal Stops waiting for the `abort` and removes the `abort` event
 * listener to prevent memory leaks.
 * Use to signal when the operation has ended (either succeeded, failed or
 * aborted otherwise) and waiting for abort is no more necessary.
 * @param createAbortError Creates the error to reject the promise with when aborted.
 * Defaults to {@link getAbortErrorFromSignalWithCauseMaybe}.
 *
 * @throws AbortError (default) when `target` aborted. Error can be customized via `createAbortError`.
 * @throws AbortError (default) when `signal` aborted. Error can be customized
 *                    by using {@link AbortControllerWithCause} for to abort `signal`.
 */
export async function waitForAbortedError<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(
  target: TAbortSignalAwaited,
  signal: TAbortSignal,
  createAbortError: (
    signal: TAbortSignalAwaited | TAbortSignal,
  ) => Error = getAbortErrorFromSignalWithCauseMaybe,
): Promise<never> {
  return waitForAbort({
    target,
    signal,
    abortError: createAbortError,
  });
}
