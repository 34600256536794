import type { ThisParameter } from '../extras-typescript/function';

// use `void` for no `this` as TypeScript uses that too when calling function without `this`
/* eslint-disable @typescript-eslint/no-invalid-void-type */

function isPromise<T = void>(value: unknown): value is Promise<T> {
  return (
    typeof value === 'object' &&
    value != null &&
    typeof (value as { then: unknown }).then === 'function'
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HandlerArgsAny = any[];
export type HandlerReturn = void | Promise<void>;

export type Handler<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
> = (this: TThis, ...args: TArgs) => TReturn;

export type RemoveHandler = () => void;

export type AddHandlerOptions = {
  isOnce?: boolean;
};
const addHandlerOptionsDefault = {
  isOnce: false,
};

export type AddHandler<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
> = (
  handler: Handler<TArgs, TReturn, TThis>,
  options?: AddHandlerOptions,
) => RemoveHandler;

export type EventHandlersDelegate<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInvocationReturn extends HandlerReturn,
  TThis = void,
> = {
  add: AddHandler<TArgs, TReturn, TThis>;
  clear: () => void;
  size: number;
  invoke: (this: TThis, ...args: TArgs) => TInvocationReturn;
};

export type EventHandlers<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
> = Pick<EventHandlersDelegate<TArgs, TReturn, never, TThis>, 'add' | 'size'>;

export type EventHandlersDelegateOf<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
> = EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  ReturnType<THandler> extends void ? void : Promise<void>,
  ThisParameter<THandler>
>;

export type EventHandlersOf<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
> = EventHandlers<
  Parameters<THandler>,
  ReturnType<THandler>,
  ThisParameter<THandler>
>;

type InvokeHandlers<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
> = (
  handlers: Iterable<Handler<TArgs, TReturn, TThis>>,
  args: TArgs,
  thisArg: TThis,
) => TReturn;

export function invokeSequential<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
>(
  handlers: Iterable<Handler<TArgs, TReturn, TThis>>,
  args: TArgs,
  thisArg: TThis,
  iterator: Iterator<Handler<TArgs, TReturn, TThis>> = handlers[
    Symbol.iterator
  ](),
): TReturn {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-constant-condition
  while (true) {
    const result = iterator.next();
    if (result.done) break;
    const maybePromise = result.value.apply(thisArg, args);
    if (maybePromise && isPromise(maybePromise)) {
      const invokeBound = (): TReturn =>
        invokeSequential(handlers, args, thisArg, iterator);
      return maybePromise.then(invokeBound) as TReturn;
    }
  }
  return undefined as TReturn;
}

export function invokeParallel<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TThis = void,
>(
  handlers: Iterable<Handler<TArgs, TReturn, TThis>>,
  args: TArgs,
  thisArg: TThis,
): TReturn {
  let promises: Promise<void>[] | undefined;
  for (const handler of handlers) {
    const maybePromise = handler.apply(thisArg, args);
    if (maybePromise && isPromise(maybePromise)) {
      if (promises) {
        promises.push(maybePromise);
      } else {
        promises = [maybePromise];
      }
    }
  }
  if (promises) {
    return Promise.all(promises) as unknown as TReturn;
  }
  return undefined as TReturn;
}

export function invokeSync<
  TArgs extends HandlerArgsAny,
  TReturn extends void,
  TThis = void,
>(
  handlers: Iterable<Handler<TArgs, void, TThis>>,
  args: TArgs,
  thisArg: TThis,
): TReturn {
  for (const handler of handlers) {
    const result = handler.apply(thisArg, args);
    if ((result as unknown) !== undefined) {
      throw new TypeError(
        `A sync event handler is expected to return "void" not: ${String(
          result,
        )}`,
      );
    }
  }
  return undefined as TReturn;
}

type InvokeHandlersBound<TReturn extends HandlerReturn> = () => TReturn;

type ScheduleInvokeHandlers<TReturn extends HandlerReturn> = (
  invoke: InvokeHandlersBound<TReturn>,
) => Promise<void>;

const resolvedPromise = Promise.resolve();

export async function scheduleAsMicrotask<TReturn extends HandlerReturn>(
  invoke: InvokeHandlersBound<TReturn>,
): Promise<void> {
  await resolvedPromise;
  const result = invoke();
  if (result && isPromise(result)) {
    return result;
  }
}

export async function scheduleAsMacrotask<TReturn extends HandlerReturn>(
  invoke: InvokeHandlersBound<TReturn>,
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      try {
        const result = invoke();
        if (result && isPromise(result)) {
          // resolve should not throw
          // eslint-disable-next-line promise/catch-or-return
          result.then(resolve, reject);
        } else {
          resolve(result);
        }
      } catch (error) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(error as Error);
      }
    }, 0);
  });
}

/**
 * References mutable context state about the invocation to share
 * between functions.
 *
 * When mutating a property **never destructure** the property as the
 * object is necessary to carry the state information.
 */
type InvocationState = {
  isInvoking: boolean;
};

/**
 * Forbid an event handler that is currently invoked to cause another concurrent invocation –
 * at any time there should only be one invocation of event handlers for an event.
 *
 * Prevents endless invocation loops which choke the event loop.
 * An endless loop might be prevented by the handlers if they stabilize at a
 * certain condition so they don't trigger another invocation – however this
 * should be forbidden as well as those sequences are hard to reason about and debug.
 */
function assertNotInvoking(invocationState: InvocationState): void {
  if (invocationState.isInvoking) {
    invocationState.isInvoking = false;
    throw new Error(
      'Cannot invoke event handlers when an invocation is already in progress.',
    );
  }
}

/**
 * Delegates a single invocation to multiple handler functions.
 *
 * @param invoke Invokes a handler with a specific strategy, e.g. scheduled
 * as micro/macrotask.
 * @param isSync Determines if the handlers are expected to execute
 * synchronously and therefore not return a promise.
 * @param invocationState References mutable state for the invocation.
 * @param handlersWhenInvoked Set of handlers from the time of invocation.
 * This should be a cloned at the time of invocation as a client
 * may add/remove handlers until the handler's are actually invoked
 * (e.g. when scheduled).
 * @param args the handlers are invoked with.
 * @param thisArg the handlers are invoked with.
 */
// eslint-disable-next-line max-lines-per-function
function delegateToHandlers<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
>(
  invoke: InvokeHandlers<
    Parameters<THandler>,
    ReturnType<THandler>,
    ThisParameter<THandler>
  >,
  isSync: boolean,
  invocationState: InvocationState,
  handlersWhenInvoked: Set<
    Handler<Parameters<THandler>, ReturnType<THandler>, ThisParameter<THandler>>
  >,
  args: Parameters<THandler>,
  thisArg: ThisParameter<THandler>,
): ReturnType<THandler> {
  assertNotInvoking(invocationState);

  invocationState.isInvoking = true;
  let maybePromise;
  try {
    maybePromise = invoke(handlersWhenInvoked, args, thisArg);
  } catch (error) {
    if (isSync) {
      throw error;
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      maybePromise = Promise.reject(error as Error);
    }
  } finally {
    if (maybePromise && isPromise(maybePromise)) {
      maybePromise = maybePromise.finally(() => {
        invocationState.isInvoking = false;
      }) as ReturnType<THandler>;
    } else {
      invocationState.isInvoking = false;
    }
  }

  if (isSync && (maybePromise as unknown) !== undefined) {
    throw new Error(
      `Event handler is asserted to be synchronous but returned a non "undefined" result`,
    );
  }

  return (isSync ? undefined : maybePromise) as ReturnType<THandler>;
}

/**
 * Creates event handlers whose invocation is scheduled as a micro or macrotask.
 * Therefore the invocation return is always a promise
 *
 * @param invoke Invokes async event handlers (return a promise) either in parallel {@link invokeParallel} or
 * sequentially {@link invokeSequential}. Sync event handlers are always called sequentially.
 * @param schedule schedules the invocation of event handlers to a later point on the microtask
 * queue {@link scheduleAsMicrotask} or macrotask queue {@link scheduleAsMacrotask}.
 */
export function createEventHandlers<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
>(
  invoke: InvokeHandlers<
    Parameters<THandler>,
    ReturnType<THandler>,
    ThisParameter<THandler>
  >,
  schedule: ScheduleInvokeHandlers<ReturnType<THandler>>,
): EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  Promise<void>,
  ThisParameter<THandler>
>;

/**
 * Creates event handlers whose invocation is scheduled as a micro or macrotask.
 * Therefore the invocation return is always a promise
 *
 * @param invoke Invokes async event handlers (return a promise) either in parallel {@link invokeParallel} or
 * sequentially {@link invokeSequential}. Sync event handlers are always called sequentially.
 * @param isSync determines if all event handlers are expected to be synchronous
 * and return `void` instead of `Promise<void>`, so that the return of invoking all handlers is
 * also `void` instead of `Promise<void>`. Defaults to false so invocation return is `Promise<void>`.
 */
export function createEventHandlers<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
  TIsSync extends boolean = ReturnType<THandler> extends void ? true : false,
>(
  invoke?: InvokeHandlers<
    Parameters<THandler>,
    ReturnType<THandler>,
    ThisParameter<THandler>
  >,
  isSync?: TIsSync,
): EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  TIsSync extends true ? void : Promise<void>,
  ThisParameter<THandler>
>;

// function serves as scope so needs more lines
// eslint-disable-next-line max-lines-per-function
export function createEventHandlers<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
  TScheduleInvokeHandlers extends
    | ScheduleInvokeHandlers<ReturnType<THandler>>
    | undefined = undefined,
  TIsSync extends boolean = TScheduleInvokeHandlers extends undefined
    ? ReturnType<THandler> extends void
      ? true
      : false
    : false,
>(
  invoke: InvokeHandlers<
    Parameters<THandler>,
    ReturnType<THandler>,
    ThisParameter<THandler>
  > = invokeParallel,
  scheduleOrIsSync?: TScheduleInvokeHandlers | TIsSync,
): EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  TScheduleInvokeHandlers extends undefined
    ? ReturnType<THandler> extends void
      ? void
      : Promise<void>
    : Promise<void>,
  ThisParameter<THandler>
> {
  type Args = Parameters<THandler>;
  type Return = ReturnType<THandler>;
  type This = ThisParameter<THandler>;
  // if invocation is scheduled it is always async (`Promise<void>`)
  // else if event handlers are async their invocation is async as well (`Promise<void>`)
  // else if no scheduling is used and event handlers are sync (`void`) their invocation is sync too
  type InvocationReturn = TScheduleInvokeHandlers extends undefined
    ? ReturnType<THandler> extends void
      ? void
      : Promise<void>
    : Promise<void>;
  type EventHandlersResult = EventHandlersDelegate<
    Parameters<THandler>,
    ReturnType<THandler>,
    TScheduleInvokeHandlers extends undefined
      ? ReturnType<THandler> extends void
        ? void
        : Promise<void>
      : Promise<void>,
    ThisParameter<THandler>
  >;

  const schedule: TScheduleInvokeHandlers | undefined =
    typeof scheduleOrIsSync === 'function' ? scheduleOrIsSync : undefined;
  const isSync =
    typeof scheduleOrIsSync === 'boolean' ? scheduleOrIsSync : false;

  const handlers = new Set<Handler<Args, Return, This>>();
  function add(
    handler: Handler<Args, Return, This>,
    { isOnce }: AddHandlerOptions = addHandlerOptionsDefault,
  ): RemoveHandler {
    const handlerMaybeInvokedOnce = isOnce
      ? function handleOnce(this: This, ...args: Args): Return {
          removeHandler();
          return handler.apply(this, args);
        }
      : handler;

    const removeHandler = (): void => {
      handlers.delete(handlerMaybeInvokedOnce);
    };
    handlers.add(handlerMaybeInvokedOnce);

    return removeHandler;
  }
  function clear(): void {
    handlers.clear();
  }

  const invocationState: InvocationState = {
    isInvoking: false,
  };

  const invokeHandlers = schedule
    ? async function invokeHandlersScheduled(
        this: This,
        ...args: Args
      ): Promise<void> {
        if (handlers.size === 0) {
          return resolvedPromise;
        }
        assertNotInvoking(invocationState);
        const handlersWhenInvoked = new Set(handlers);
        const thisArg = (
          this === eventHandlersDelegate || this === invokeHandlers
            ? undefined
            : this
        ) as This;

        const delegateToHandlersBound = (): Return => {
          return delegateToHandlers(
            invoke,
            isSync,
            invocationState,
            handlersWhenInvoked,
            args,
            thisArg,
          );
        };

        return schedule(delegateToHandlersBound);
      }
    : function invokeHandlersNow(this: This, ...args: Args): InvocationReturn {
        if (handlers.size === 0) {
          return (isSync ? undefined : resolvedPromise) as InvocationReturn;
        }
        assertNotInvoking(invocationState);
        const handlersWhenInvoked = new Set(handlers);
        const thisArg = (
          this === eventHandlersDelegate || this === invokeHandlers
            ? undefined
            : this
        ) as This;

        return delegateToHandlers(
          invoke,
          isSync,
          invocationState,
          handlersWhenInvoked,
          args,
          thisArg,
        ) as InvocationReturn;
      };

  const eventHandlersDelegate = {
    add,
    clear,
    get size(): number {
      return handlers.size;
    },
    invoke: invokeHandlers,
  } as unknown as EventHandlersResult;

  return eventHandlersDelegate;
}

export function createEventHandlersSync<
  THandler extends Handler<HandlerArgsAny, void, never>,
>(): EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  void,
  ThisParameter<THandler>
> {
  return createEventHandlers<THandler, true>(invokeSync, true);
}

export function createEventHandlersSyncScheduled<
  THandler extends Handler<HandlerArgsAny, void, never>,
>(
  schedule: ScheduleInvokeHandlers<ReturnType<THandler>> = scheduleAsMicrotask,
): EventHandlersDelegate<
  Parameters<THandler>,
  ReturnType<THandler>,
  void,
  ThisParameter<THandler>
> {
  return createEventHandlers<THandler>(invokeSync, schedule);
}

/* eslint-enable @typescript-eslint/no-invalid-void-type */
