import { abortOnTimeout } from '../async/abort-on-timeout';
import { raceAbortSignals } from '../async/abort-signal-combinator';
import { waitForEvent } from '../async/wait-event';
import type {
  EventHandlers,
  HandlerArgsAny,
  HandlerReturn,
} from './event-handlers';

type GetEventHandlersNextValueOptions<THandlerArgs extends HandlerArgsAny> = {
  timeout?: number;
  signal?: AbortSignal;
  filterPredicate?: (...value: THandlerArgs) => boolean;
};

export async function getEventHandlersNextValue<
  THandlerArgs extends HandlerArgsAny,
>(
  eventHandler: EventHandlers<THandlerArgs, HandlerReturn, unknown>,
  {
    timeout = 5000,
    signal,
    filterPredicate = () => true,
  }: GetEventHandlersNextValueOptions<THandlerArgs> = {},
): Promise<THandlerArgs> {
  // TODO[2025-02-01] We can use `using disposables` here if we migrate to Vite
  const disposables = new DisposableStack();

  try {
    const abortOnDone = new AbortController();
    const abortOnTimeoutSignal = abortOnTimeout(timeout, abortOnDone.signal);

    disposables.defer(() => {
      abortOnDone.abort();
    });

    return await waitForEvent({
      subscribe: (handler) => {
        return eventHandler.add((...value: THandlerArgs) => {
          if (filterPredicate(...value)) {
            handler(value);
          }
        });
      },
      signal:
        signal == null
          ? abortOnTimeoutSignal
          : disposables.use(
              raceAbortSignals(
                [abortOnTimeoutSignal, signal],
                abortOnDone.signal,
              ),
            ).signal,
    });
  } finally {
    disposables.dispose();
  }
}
