import IconButton from '@mui/material/IconButton';
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import {
  dateTimeFormattingParamsDefault,
  useDateFormatting,
} from '../../../../base/date-formatting/date-formatting';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconDownload from '../../../../base/stihl-material-ui/icons/stihl-icon-download';
import { useAppAuthenticationSessionAuthenticated } from '../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import type { ReleaseManagementMetaData } from '../../model/release-management/release-management.model';
import { StihlLogoText } from './static/stihl-logo-text';

// Register font
// eslint-disable-next-line jest/require-hook
Font.register({
  family: 'STIHL Contraface Text',
  src: './stihl-material-ui/fonts/STIHLContrafaceText.woff',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'STIHL Contraface Text',
    fontSize: '12pt',
  },
  header: {
    textAlign: 'center',
    color: 'grey',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    inlineSize: 150,
    top: 10,
    right: 20,
  },
  headline: {
    textAlign: 'center',
    fontSize: '16pt',
    marginTop: 50,
  },
  section: {
    margin: 20,
    padding: 20,
  },
  entryGroup: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  entry: {
    flexGrow: 1,
    flexBasis: '50%',
  },
  fileHashTitle: {
    marginBottom: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    fontSize: '10pt',
    textAlign: 'right',
  },
});

// Create Document Component
// eslint-disable-next-line max-lines-per-function
export const MetadataDocument = ({
  metadata,
  userId,
}: {
  metadata: ReleaseManagementMetaData;
  userId: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateTimeString } = useDateFormatting();

  return (
    <Document data-testid="metadataDocument">
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.header}>Intern | Internal</Text>
        </View>
        <View>
          <Image style={styles.logo} src={StihlLogoText} />
        </View>
        <View>
          <Text style={styles.headline}>
            {t('releaseManagement.metadataForRelease', {
              version: metadata.version,
            })}
          </Text>
        </View>

        <View style={styles.section}>
          {Object.keys(metadata).map((entryKey) => {
            if (
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              !metadata[entryKey as keyof typeof metadata] &&
              metadata[entryKey as keyof typeof metadata] !== false
            ) {
              return null;
            }
            if (entryKey.startsWith('is') || entryKey.startsWith('requires')) {
              return (
                <View style={styles.entryGroup} key={entryKey}>
                  <Text style={styles.entry}>
                    {t(`releaseManagement.editMetadataForm.${entryKey}`)}
                  </Text>
                  <Text style={styles.entry}>
                    {metadata[entryKey as keyof typeof metadata] === true
                      ? t('releaseManagement.editMetadataForm.yes')
                      : t('releaseManagement.editMetadataForm.no')}
                  </Text>
                </View>
              );
            }
            if (entryKey === 'fileHash') {
              return (
                <View key={entryKey}>
                  <Text style={styles.fileHashTitle}>
                    {t('releaseManagement.editMetadataForm.fileHash')}
                  </Text>
                  <View style={styles.entryGroup}>
                    <Text style={{ ...styles.entry, paddingLeft: 10 }}>
                      {t('releaseManagement.editMetadataForm.fileHashValue')}
                    </Text>
                    <Text style={styles.entry}>{metadata.fileHash?.value}</Text>
                  </View>
                  <View style={styles.entryGroup}>
                    <Text style={{ ...styles.entry, paddingLeft: 10 }}>
                      {t('releaseManagement.editMetadataForm.fileHashType')}
                    </Text>
                    <Text style={styles.entry}>{metadata.fileHash?.type}</Text>
                  </View>
                </View>
              );
            }
            if (entryKey.startsWith('supported')) {
              return (
                <View key={entryKey} style={styles.entryGroup}>
                  <Text style={styles.entry}>
                    {t(`releaseManagement.editMetadataForm.${entryKey}`)}
                  </Text>
                  <Text style={styles.entry}>
                    {(
                      metadata[entryKey as keyof typeof metadata] as
                        | string[]
                        | undefined
                    )?.join(', ')}
                  </Text>
                </View>
              );
            }
            return (
              <View key={entryKey} style={styles.entryGroup}>
                <Text style={styles.entry}>
                  {t(`releaseManagement.editMetadataForm.${entryKey}`)}
                </Text>
                <Text style={styles.entry}>
                  {metadata[entryKey as keyof typeof metadata]?.toString()}
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.section}>
          <Text>
            {t('releaseManagement.exportedInformation', {
              user: userId,
              timestamp: new Date(),
              formatParams: {
                timestamp: dateTimeFormattingParamsDefault,
              },
            })}
          </Text>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `${t('releaseManagement.pageOfPages', {
              page: pageNumber,
              totalPages,
            })} \n ${toLocaleDateTimeString(new Date())}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const MetadataPdfExport = ({
  metadata,
}: {
  metadata: ReleaseManagementMetaData;
}): JSX.Element => {
  const { t } = useTranslation();
  const session = useAppAuthenticationSessionAuthenticated();
  return (
    <PDFDownloadLink
      document={
        <MetadataDocument metadata={metadata} userId={session.userId} />
      }
      fileName={`Metadata_v${metadata.version}.pdf`}
    >
      <StihlTooltip
        title={t('releaseManagement.metadataOverview.downloadAsPdfTooltip')}
        placement="left"
        data-testid="downloadAsPdfTooltip"
      >
        <IconButton data-testid="pdfDownloadButton">
          <StihlIconDownload color="black" />
        </IconButton>
      </StihlTooltip>
    </PDFDownloadLink>
  );
};

export default MetadataPdfExport;
