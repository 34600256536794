import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { ActivationState } from '../../../device-management/model/search.models';
import { useEnableOrDisableDeviceId } from '../../../device-management/service/device-management-service/device-management-service';
import type { DeviceResult } from '../../model/card.model';

type DeviceActivationStateChangeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  device: DeviceResult;
};

const DeviceActivationStateChangeModal: FC<
  DeviceActivationStateChangeModalProps
> = ({ isOpen, onClose, device }) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const enableOrDisableDeviceId = useEnableOrDisableDeviceId({
    onSuccess: (_data, { activationState }) => {
      setAlert({
        isOpen: true,
        message:
          activationState === ActivationState.Enabled
            ? t('searchResultTable.activationState.enableDeviceIdSuccess')
            : t('searchResultTable.activationState.disableDeviceIdSuccess'),
        severity: 'success',
      });

      onClose();
    },
    onError: (error, { activationState }) => {
      if (error.message.includes('409')) {
        setAlert({
          isOpen: true,
          message: t(
            // eslint-disable-next-line no-secrets/no-secrets
            'searchResultTable.activationState.enableDeviceIdConflictError',
          ),
          severity: 'error',
        });
      } else {
        setAlert({
          isOpen: true,
          message:
            activationState === ActivationState.Enabled
              ? t('searchResultTable.activationState.enableDeviceIdError')
              : t('searchResultTable.activationState.disableDeviceIdError'),
          severity: 'error',
        });
      }
    },
  });

  const handleChangeActivationState = useCallback(() => {
    const newActivationState =
      device.iotDeviceActivationStatus === 'disabled'
        ? ActivationState.Enabled
        : ActivationState.Disabled;

    enableOrDisableDeviceId.mutate({
      deviceId: device.deviceId,
      deviceModel: device.deviceModel,
      activationState: newActivationState,
    });
  }, [device, enableOrDisableDeviceId]);

  return (
    <StihlModal
      open={isOpen}
      onClose={onClose}
      title={
        device.iotDeviceActivationStatus === 'enabled'
          ? t('searchResultTable.activationState.disableDeviceId')
          : t('searchResultTable.activationState.enableDeviceId')
      }
      actionButtonText={t('searchResultTable.activationState.save')}
      handleActionClick={handleChangeActivationState}
      data-testid="enableOrDisableModal"
      isLoading={enableOrDisableDeviceId.isPending}
    >
      <Typography>
        {device.iotDeviceActivationStatus === 'enabled' ? (
          <Trans
            i18nKey="searchResultTable.activationState.disableDeviceIdText"
            values={{ deviceId: device.deviceId }}
            components={[<b key="0" />]}
          />
        ) : (
          <>
            <Trans
              i18nKey="searchResultTable.activationState.enableDeviceIdText"
              values={{ deviceId: device.deviceId }}
              components={[<b key="0" />]}
            />
            <div>
              {t('searchResultTable.activationState.enableDeviceIdHint')}
            </div>
          </>
        )}
      </Typography>
    </StihlModal>
  );
};
export default DeviceActivationStateChangeModal;
