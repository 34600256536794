import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltipSmall from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-small';
import StihlIconLink from '../../../../base/stihl-material-ui/icons/stihl-icon-link';
import StihlIconMedal from '../../../../base/stihl-material-ui/icons/stihl-icon-medal';
import StihlIconMowerLink from '../../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import StihlIconSettings from '../../../../base/stihl-material-ui/icons/stihl-icon-settings';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { SearchParameterValues } from '../../../device/model/search.model';
import type {
  CustomerManagementEquipment,
  CustomerDeviceColumns,
  CustomerDeviceHeadCell,
} from '../../model/customer-account.model';
import { EquipmentRole } from '../../model/customer-account.model';
import CustomerDisconnectDeviceModal from '../customer-disconnect-device-modal/customer-disconnect-device-modal';
// eslint-disable-next-line import/max-dependencies
import { CustomerManagementDeputyRemove } from '../customer-management-deputy/customer-management-deputy-remove';

const StyledTableCell = styled(TableCell)`
  padding: 0 16px;
`;

const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
`;

const Row = ({
  row,
  customerName,
  customerId,
}: {
  row: CustomerManagementEquipment;
  customerId: string;
  customerName: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const history = useHistory();
  function handleOpenDevice(serialNumber: string): void {
    history.push({
      pathname: '/device',
      state: {
        search: serialNumber,
        selector: SearchParameterValues.SerialNumber,
        deviceModel: row.deviceModel,
      },
    });
  }

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  function handleModalClose(): void {
    setModalOpen(false);
  }

  return (
    <TableRow
      role="checkbox"
      tabIndex={-1}
      key={row.serialNumber}
      data-testid="deviceRow"
      sx={{
        '&.Mui-selected': {
          backgroundColor: `${stihlColor.greyLight}`,
        },
        '&.MuiTableRow-root.Mui-selected:hover': {
          backgroundColor: `${stihlColor.greyMid}`,
        },
      }}
    >
      <StyledTableCell component="th" scope="row" align="left">
        <IconAligner>
          {row.role === EquipmentRole.Deputy ? (
            <StihlIconLink data-testid="deputyIcon" />
          ) : (
            <StihlIconMedal data-testid="ownerIcon" color="grey.500" />
          )}
          {t(row.role)}
        </IconAligner>
      </StyledTableCell>
      <StyledTableCell align="left">{row.deviceModel}</StyledTableCell>
      <StyledTableCell align="left">{row.modelName}</StyledTableCell>
      <StyledTableCell align="left">{row.serialNumber}</StyledTableCell>
      <StyledTableCell>
        <StihlTooltipSmall
          title={
            row.role === EquipmentRole.Deputy
              ? t('customerDetails.removeDeputy')
              : t('customerManagement.deviceTable.disconnect')
          }
        >
          <IconButton onClick={handleModalOpen}>
            <StihlIconSettings color="black" />
          </IconButton>
        </StihlTooltipSmall>
        {row.role === EquipmentRole.Deputy && row.subscriptionId ? (
          <CustomerManagementDeputyRemove
            open={isModalOpen}
            setOpen={setModalOpen}
            name={customerName}
            id={customerId}
            subscriptionId={row.subscriptionId}
          />
        ) : (
          <CustomerDisconnectDeviceModal
            open={isModalOpen}
            onClose={handleModalClose}
            serialNumber={row.serialNumber}
            deviceModel={row.deviceModel as string}
          />
        )}
        <StihlTooltipSmall
          title={t('customerManagement.deviceTable.goToDevice')}
        >
          <IconButton onClick={() => handleOpenDevice(row.serialNumber)}>
            <StihlIconMowerLink color="black" />
          </IconButton>
        </StihlTooltipSmall>
      </StyledTableCell>
    </TableRow>
  );
};

type CustomerDetailsTableProps = {
  customerDeviceDetails: CustomerManagementEquipment[];
  customerId: string;
  customerName: string;
};

const CustomerDetailsTable = ({
  customerDeviceDetails,
  customerId,
  customerName,
}: CustomerDetailsTableProps): JSX.Element => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof CustomerDeviceColumns>('deviceModel');

  const tableHeaderHeadCells: CustomerDeviceHeadCell[] = [
    {
      id: 'role',
      label: t('customerManagement.deviceTable.deviceRole'),
      isSortable: true,
    },
    {
      id: 'deviceModel',
      label: t('customerManagement.deviceTable.deviceModel'),
      isSortable: true,
    },
    {
      id: 'modelName',
      label: t('customerManagement.deviceTable.deviceType'),
      isSortable: true,
    },
    {
      id: 'serialNumber',
      label: t('customerManagement.deviceTable.serialNumber'),
      isSortable: true,
    },
  ];

  return (
    <TableContainer style={{ padding: '1rem' }}>
      <Table data-testid="customerDetailsTable">
        <StihlTableHeader<CustomerDeviceColumns>
          order={order}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          headCells={tableHeaderHeadCells}
          shouldHaveCheckbox={false}
          shouldHaveLastColumnEmpty
        />
        <TableBody>
          {[...customerDeviceDetails]
            .sort(getComparator(order, orderBy))
            .map((device) => {
              return (
                <Row
                  key={device.serialNumber}
                  row={device}
                  customerId={customerId}
                  customerName={customerName}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerDetailsTable;
