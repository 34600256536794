import type { Unsubscribe } from './_subscribe/subscribe-abortable';
import { subscribeAbortable } from './_subscribe/subscribe-abortable';
import type {
  AbortCause,
  AbortControllerWithCauseMaybe,
} from './abort-controller-with-cause';
import { AbortControllerWithCause } from './abort-controller-with-cause';

/**
 * Signals an abort when an event source emits a value or error.
 *
 * @param subscribe Subscribes to the event. The passed `handle` can
 * optionally receive an {@link AbortCause} that is passed to the
 * {@link AbortControllerWithCause#abort}. This is useful to choose
 * the error to throw for the abort depending on the received event.
 * @param signal Signals an abort if this function waiting for an event to
 * trigger an abort is no longer necessary because the performed task has
 * completed or was aborted otherwise. **Use to prevent memory leaks** due to
 * the event subscription.
 * The returned signal **will not** abort if this `signal` aborts.
 * @param abortController The abort controller to use. If not present
 * a controller dedicated to this abort will be created. Has to be
 * {@link AbortControllerWithCause} to support an {@link AbortCause}.
 * @return signal aborts if subscribed event source emits a value or error.
 */
export function abortOnEvent<
  TAbortCause extends AbortCause,
  TAbortController extends
    AbortControllerWithCauseMaybe<TAbortCause> = AbortControllerWithCause<TAbortCause>,
>(
  subscribe: (handle: (cause?: TAbortCause) => void) => Unsubscribe,
  signal?: AbortSignal,
  abortController: TAbortController = new AbortControllerWithCause<TAbortCause>() as unknown as TAbortController,
): TAbortController['signal'] {
  subscribeAbortable({
    subscribe,
    signal,
    // we are done after first value/error as it triggers the abort
    isDone: true,
    /*
    `onAbort` is not handled (e.g. to also abort this `abortController`)
    as aborting the given `signal` means we no longer need to abort:
    the operation has completed or was aborted otherwise

    `subscribeAbortable()` does not invoke `onValue`/`onError` if `signal` got
    aborted so the `abortController` will not be aborted if `signal` is aborted.
     */
    onValue: (cause?: TAbortCause) => {
      abortController.abort(cause);
    },
  });

  return abortController.signal;
}
