import { getOwnOrPrototypePropertyNames } from '../extras-javascript/object-prototype';

/**
 * {@link JSON.stringify} `replacer` to replace an error instance
 * with a serializable record.
 *
 * @param key
 * @param value
 */
function replaceErrorWithRecord(
  this: unknown,
  key: string,
  value: unknown,
): unknown {
  if (value instanceof Error) {
    // sub-type of error is not known at compile time, so there are additional unknown properties
    type UnknownErrorRecord = ErrorRecord<Error> & { [key: string]: unknown };
    const error: { [key: string]: unknown } = {};
    for (const propertyName of getOwnOrPrototypePropertyNames(value, [
      Object.prototype,
    ])) {
      error[propertyName] = (value as UnknownErrorRecord)[propertyName];
    }

    return error;
  }

  return value;
}

export type ErrorRecord<TError extends Error> = {
  [TKey in keyof TError]: TError[TKey];
};

/**
 * Converts an `Error` to a corresponding plain object record.
 * In contrast to the `Error` instance the error record is serializable with {@link JSON.stringify}.
 * @param error
 */
export function toErrorRecord<TError extends Error>(
  error: TError,
): ErrorRecord<TError> {
  return JSON.parse(
    JSON.stringify(error, replaceErrorWithRecord),
  ) as ErrorRecord<TError>;
}

export function isErrorRecord(value: unknown): value is ErrorRecord<Error> {
  if (!(typeof value === 'object' && value != null)) return false;

  const hasErrorName =
    'name' in value &&
    typeof value.name === 'string' &&
    value.name.endsWith('Error');

  const hasMessage = 'message' in value && typeof value.message === 'string';

  const hasOptionalStack =
    !('stack' in value) ||
    (typeof value.stack === 'string' && value.stack !== '');

  return hasErrorName && hasMessage && hasOptionalStack;
}
