import type { ErrorBaseCodedOptions } from '../../../../base/error/error-base';
import { initErrorBase } from '../../../../base/error/error-base';

export type AppAuthenticationErrorCode =
  | 'StorageUnavailable'
  | 'StorageFull'
  | 'RefreshFailed'
  | 'LoginAccessDenied'
  | 'LoginFailed'
  | 'LoginThoughLoggedIn'
  | 'InvalidAuthentication'
  | 'LogoutFailed';

export class AppAuthenticationError<
  TCode extends AppAuthenticationErrorCode = AppAuthenticationErrorCode,
  TCause extends Error = Error,
> extends Error {
  override name = 'AppAuthenticationError';

  // assigned in `initErrorBase()`
  // @ts-expect-error TS2564: Property 'code' has no initializer and is not definitely assigned in the constructor
  code: TCode;

  constructor(message: string, options: ErrorBaseCodedOptions<TCode, TCause>) {
    super(message);

    initErrorBase(this, options, AppAuthenticationError);
  }

  static fromCause<
    TCode2 extends AppAuthenticationErrorCode,
    TCause2 extends Error = Error,
  >(
    cause: TCause2,
    code: TCode2,
    message = '',
  ): AppAuthenticationError<TCode2, TCause2> {
    const joinedMessage =
      message && cause.message
        ? `${message} Cause: ${cause.message}`
        : message || cause.message;
    return new AppAuthenticationError<TCode2>(joinedMessage, { code, cause });
  }
}

export function wrapWithAuthenticationErrorMaybe(
  error: unknown,
  errorCodeDefault: AppAuthenticationErrorCode,
): AppAuthenticationError {
  if (error instanceof AppAuthenticationError) {
    return error as AppAuthenticationError;
  }

  if (error instanceof Error) {
    return AppAuthenticationError.fromCause(error, errorCodeDefault);
  }

  return new AppAuthenticationError(String(error), { code: errorCodeDefault });
}
