import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import type {
  CustomerResult,
  DeviceResult,
  DeviceResultNotProvisioned,
} from '../../../model/card.model';
import { SearchParameterValues } from '../../../model/search.model';
import { DeviceTabsContext } from '../../tabs/device-tabs.context';
import { CardHeader } from '../generic-card/generic-card';
import DeviceSearchResultCustomerEntry from './device-search-result-customer-entry';
import { StyledHeadingTableCell } from './device-search-result-shared';

type DeviceSearchResultCardData = {
  deviceResults: DeviceResult[];
  deviceResultsNotProvisioned?: DeviceResultNotProvisioned[];
  customerResults?: CustomerResult[];
};

type DeviceSearchResultCardProps = {
  cardData: DeviceSearchResultCardData;
};

const useSearchResultGrouping = (
  deviceResults: DeviceResult[],
  deviceResultsNotProvisioned: DeviceResultNotProvisioned[] | undefined,
  customerResults: CustomerResult[] | undefined,
): {
  customer: CustomerResult | undefined;
  devices: (DeviceResult | DeviceResultNotProvisioned)[];
}[] => {
  return useMemo(() => {
    const searchResultsGrouped: {
      customer: CustomerResult | undefined;
      devices: (DeviceResult | DeviceResultNotProvisioned)[];
    }[] = [];

    const remaining = new Map<
      string,
      (DeviceResult | DeviceResultNotProvisioned)[]
    >();

    for (const deviceResult of deviceResults) {
      const resultsForSerialNumber =
        remaining.get(deviceResult.serialNumber) ?? [];

      resultsForSerialNumber.push(deviceResult);

      remaining.set(deviceResult.serialNumber, resultsForSerialNumber);
    }

    for (const deviceResultNotProvisioned of deviceResultsNotProvisioned ??
      []) {
      const resultsForSerialNumber =
        remaining.get(deviceResultNotProvisioned.serialNumber) ?? [];

      resultsForSerialNumber.push(deviceResultNotProvisioned);

      remaining.set(
        deviceResultNotProvisioned.serialNumber,
        resultsForSerialNumber,
      );
    }

    for (const customer of customerResults ?? []) {
      searchResultsGrouped.push({
        customer,
        devices: customer.devices
          .flatMap((device) => {
            try {
              return remaining.get(device.serialNumber);
            } finally {
              remaining.delete(device.serialNumber);
            }
          })
          .filter((device): device is DeviceResult => device != null),
      });
    }

    if (remaining.size > 0) {
      searchResultsGrouped.push({
        customer: undefined,
        devices: [...remaining.values()].flat(),
      });
    }

    return searchResultsGrouped;
  }, [deviceResults, deviceResultsNotProvisioned, customerResults]);
};

const DeviceSearchResultCard: FC<DeviceSearchResultCardProps> = ({
  cardData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { openTab } = useContext(DeviceTabsContext);

  const searchResultsGrouped = useSearchResultGrouping(
    cardData.deviceResults,
    cardData.deviceResultsNotProvisioned,
    cardData.customerResults,
  );

  const shouldShowCustomerInformation =
    searchResultsGrouped.length > 1 ||
    (searchResultsGrouped.length === 1 &&
      searchResultsGrouped[0].customer !== undefined);

  const handleOpenDevice = useCallback(
    (device: DeviceResult) => {
      if (
        cardData.deviceResults.length === 1 &&
        cardData.deviceResults[0].deviceId === device.deviceId
      ) {
        openTab('tabs.overview');
      } else {
        history.push('/device', {
          selector: SearchParameterValues.DeviceId,
          search: device.deviceId,
          deviceModel: device.deviceModel,
        });
      }
    },
    [cardData.deviceResults, history, openTab],
  );

  const hasData =
    cardData.deviceResults.length > 0 ||
    (cardData.customerResults != null && cardData.customerResults.length > 0);

  return (
    <Card>
      <CardHeader>
        <Typography variant="h3">
          {t('tabs.contentTitles.searchResult')}
        </Typography>
      </CardHeader>
      <CardContent>
        {hasData ? (
          <Table>
            <TableHead>
              <TableRow>
                {shouldShowCustomerInformation && (
                  <>
                    <StyledHeadingTableCell>
                      {t('searchResultTable.headers.customerName')}
                    </StyledHeadingTableCell>
                    <StyledHeadingTableCell>
                      {t('searchResultTable.headers.customerEmail')}
                    </StyledHeadingTableCell>
                    <StyledHeadingTableCell>
                      {t('searchResultTable.headers.location')}
                    </StyledHeadingTableCell>
                    <StyledHeadingTableCell />
                    <StyledHeadingTableCell>
                      {t('searchResultTable.headers.role')}
                    </StyledHeadingTableCell>
                  </>
                )}

                <StyledHeadingTableCell>
                  {t('searchResultTable.headers.serialNumber')}
                </StyledHeadingTableCell>
                <StyledHeadingTableCell>
                  {t('searchResultTable.headers.deviceId')}
                </StyledHeadingTableCell>
                <StyledHeadingTableCell>
                  {t('searchResultTable.headers.deviceModel')}
                </StyledHeadingTableCell>
                <StyledHeadingTableCell>
                  {t('searchResultTable.headers.deviceType')}
                </StyledHeadingTableCell>
                <StyledHeadingTableCell />
                <StyledHeadingTableCell />
              </TableRow>
            </TableHead>
            {searchResultsGrouped.map((searchResult) => (
              <DeviceSearchResultCustomerEntry
                key={searchResult.customer?.id ?? 'unknown-customer'}
                customer={searchResult.customer}
                devices={searchResult.devices}
                shouldShowCustomerInformation={shouldShowCustomerInformation}
                onOpenDevice={handleOpenDevice}
              />
            ))}
          </Table>
        ) : (
          <Typography>{t('searchErrors.noDevicesFound')}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DeviceSearchResultCard;
