import type { DeviceModel } from './device.model';

export enum SearchParameterValues {
  SerialNumber = 'serial number',
  Email = 'email',
  Imei = 'IMEI',
  Imsi = 'IMSI',
  DeviceId = 'deviceId',
  FleetEmail = 'fleetEmail',
}

export type SearchObject = {
  deviceModel: DeviceModel | 'any';
  selector: SearchParameterValues;
  search: string;
};
