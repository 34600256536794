import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ChipType,
  RangeObject,
} from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { FilterParameterValue } from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import StihlTextField from '../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconFilter from '../../../../../base/stihl-material-ui/icons/stihl-icon-filter';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAppAuthenticationSessionAuthenticated } from '../../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import type {
  DeviceConnectionState,
  DeviceModel,
  DeviceType,
  ItemType,
  StihlDeviceTag,
} from '../../../../device/model';
import SearchCriteriaFilter from '../../filters/search-criteria-filter';
import SelectFilter from '../../filters/select-filter';
import {
  RangeInputProductionDate,
  RangeInputSerialNumber,
  SoftwareVersionInput,
} from '../input-options';
import { deviceConnectionStates } from '../utils/device-management.const';

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: start;
`;
const StyledIconButton = styled(IconButton)`
  margin: 2rem 0.5rem 0.5rem;
`;

const StyledHelperTextContainer = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledHelperText = styled.span`
  margin-block-start: 0.25rem;
  color: ${stihlColor.black};
`;

export const StyledStihlIconFilter = styled(StihlIconFilter)`
  &:hover {
    cursor: pointer;
  }
`;

function getPlaceholderText(
  value: string | FilterParameterValue | undefined,
): string {
  switch (value) {
    case FilterParameterValue.ConnectionState: {
      return 'filterParameters.connectionState';
    }
    case FilterParameterValue.SoftwareVersion: {
      return 'filterParameters.softwareVersion';
    }
    case FilterParameterValue.DeviceType: {
      return 'filterParameters.deviceType';
    }
    case FilterParameterValue.Tag: {
      return 'filterParameters.tag';
    }
    case FilterParameterValue.Imei: {
      return 'filterParameters.imei';
    }
    case FilterParameterValue.Imsi: {
      return 'filterParameters.imsi';
    }
    case FilterParameterValue.DeviceId: {
      return 'filterParameters.deviceId';
    }
    default: {
      return 'filterParameters.serialNumber';
    }
  }
}

function validateInputField(
  value: FilterParameterValue,
  searchTerm: string,
): boolean {
  switch (value) {
    case FilterParameterValue.SerialNumber: {
      const validSerialNumber = /^\d{9,10}$/u;
      return validSerialNumber.test(searchTerm);
    }
    case FilterParameterValue.Imei: {
      const validImei = /^\d{15}$/u;
      return validImei.test(searchTerm);
    }
    case FilterParameterValue.Imsi: {
      const validImsi = /^\d{14,15}$/u;
      return validImsi.test(searchTerm);
    }
    case FilterParameterValue.DeviceId: {
      const validDeviceId = /^[A-Z_\d]{1,35}$/u;
      return validDeviceId.test(searchTerm);
    }
    case FilterParameterValue.Email: {
      return searchTerm.length > 0;
    }
    default: {
      return searchTerm.length > 0 && searchTerm.length <= 30;
    }
  }
}

function getErrorText(value: FilterParameterValue): string {
  switch (value) {
    case FilterParameterValue.SerialNumber: {
      return 'deviceManagementFilter.errorSerialNr';
    }
    case FilterParameterValue.Imei: {
      return 'deviceManagementFilter.errorImei';
    }
    case FilterParameterValue.Imsi: {
      return 'deviceManagementFilter.errorImsi';
    }
    case FilterParameterValue.DeviceId: {
      return 'deviceManagementFilter.errorDeviceId';
    }
    default: {
      return 'deviceManagementFilter.errorLength';
    }
  }
}

type DevicePropertyFilterInputProps = {
  filter: FilterParameterValue;
  handleSubmitInput: (submitData: ChipType) => void;
  deviceTypes: ItemType<DeviceType>[];
  deviceModel: DeviceModel | undefined;
};

// eslint-disable-next-line max-lines-per-function
const DevicePropertyFilterInput: FC<DevicePropertyFilterInputProps> = ({
  filter,
  handleSubmitInput,
  deviceTypes,
  deviceModel,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const session = useAppAuthenticationSessionAuthenticated();

  useEffect(() => setSearchTerm(''), [filter, setSearchTerm]);

  function handleRangeInput(range: RangeObject): void {
    handleSubmitInput({
      filter: range.identifier,
      searchTerm: `${range.start} - ${range.end}`,
      value: range,
    });
    setSearchTerm('');
  }

  function handleSelectInput(
    name: string,
    value: StihlDeviceTag | DeviceConnectionState | DeviceType | string,
  ): void {
    handleSubmitInput({
      filter: name as FilterParameterValue,
      searchTerm: value.toString(),
      chipRenderText:
        name === (FilterParameterValue.ConnectionState as string)
          ? t(`deviceConnectionState.${value}`)
          : undefined,
    });
    setSearchTerm('');
  }

  function handleSearchTermChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ): void {
    setSearchTerm(event.target.value as string);
  }

  function handleEnter(event: React.KeyboardEvent): void {
    const pressedKey: string = event.key;
    if (pressedKey === 'Enter' && validateInputField(filter, searchTerm)) {
      handleSubmitInput({ filter, searchTerm });
      setSearchTerm('');
    }
  }

  switch (filter) {
    case FilterParameterValue.RangeOfSerialnumbers: {
      return (
        <RangeInputSerialNumber serialNumbersCallback={handleRangeInput} />
      );
    }
    case FilterParameterValue.RangeOfProductionDates: {
      return (
        <RangeInputProductionDate productionRangeCallback={handleRangeInput} />
      );
    }
    case FilterParameterValue.Tag: {
      return (
        <SelectFilter
          handleCallback={handleSelectInput}
          filterLabel="filterLabel.tag"
          items={session.deviceFlags.map((flag) => ({
            value: flag,
            label: flag,
          }))}
          name="tag"
        />
      );
    }
    case FilterParameterValue.ConnectionState: {
      return (
        <SelectFilter
          handleCallback={handleSelectInput}
          filterLabel="filterLabel.connectionState"
          items={deviceConnectionStates}
          name="connectionState"
        />
      );
    }
    case FilterParameterValue.DeviceType: {
      return (
        <SelectFilter
          handleCallback={handleSelectInput}
          filterLabel="filterLabel.deviceType"
          items={deviceTypes}
          name="deviceType"
        />
      );
    }
    case FilterParameterValue.SoftwareVersion: {
      return (
        <SoftwareVersionInput
          deviceModel={deviceModel}
          handleCallback={handleSelectInput}
        />
      );
    }
    default: {
      return (
        <StyledInputWrapper>
          <StihlTextField
            placeholder={t(getPlaceholderText(filter))}
            variant="standard"
            color="secondary"
            value={searchTerm}
            onChange={handleSearchTermChange}
            onKeyDown={handleEnter}
            fullWidth
            inputProps={{ 'data-testid': 'filterInput' }}
            error={
              searchTerm.length > 0 && !validateInputField(filter, searchTerm)
            }
            helperText={
              searchTerm.length > 0 &&
              !validateInputField(filter, searchTerm) ? (
                <StyledHelperTextContainer>
                  <StihlIconXFilled />
                  <StyledHelperText>{t(getErrorText(filter))}</StyledHelperText>
                </StyledHelperTextContainer>
              ) : (
                ''
              )
            }
            style={{ marginTop: '1.75rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filter === FilterParameterValue.DeviceId && (
                    <StihlTooltip
                      title={t('deviceManagementFilter.wildCardSearch')}
                      placement="top"
                    >
                      <StihlIconInfo />
                    </StihlTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <StyledIconButton
            onClick={() => {
              handleSubmitInput({ filter, searchTerm });
              setSearchTerm('');
            }}
            data-testid="addFilterButton"
            disabled={
              searchTerm.length === 0 || !validateInputField(filter, searchTerm)
            }
          >
            <StyledStihlIconFilter
              color={
                searchTerm.length === 0 ||
                !validateInputField(filter, searchTerm)
                  ? 'text.disabled'
                  : 'text.primary'
              }
            />
          </StyledIconButton>
        </StyledInputWrapper>
      );
    }
  }
};

type DevicePropertyFilterProps = {
  deviceTypes: ItemType<DeviceType>[];
  chips: ChipType[];
  addChip: (chipData: ChipType) => void;
  deviceModel: DeviceModel | undefined;
};

const DevicePropertyFilter: FC<DevicePropertyFilterProps> = ({
  deviceTypes,
  addChip,
  chips,
  deviceModel,
}) => {
  const [filter, setFilter] = useState<FilterParameterValue>(
    FilterParameterValue.SerialNumber,
  );

  function handleFilter(criteria: FilterParameterValue): void {
    setFilter(criteria);
  }

  function handleSubmit(submitData: ChipType): void {
    addChip(submitData);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <SearchCriteriaFilter
          searchCriteriaCallback={handleFilter}
          selectedFilters={chips.map((chip) => chip.filter)}
        />
      </Grid>
      <Grid item xs={6}>
        <DevicePropertyFilterInput
          filter={filter}
          handleSubmitInput={handleSubmit}
          deviceTypes={deviceTypes}
          deviceModel={deviceModel}
        />
      </Grid>
    </Grid>
  );
};

export default DevicePropertyFilter;
