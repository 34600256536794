import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { JSX, ChangeEvent } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { getDefaultProcessingChain } from '../../service/processing-chain';

const StyledProcessingChainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

export type ProcessingChainFilterProps = {
  processingChain: string | undefined;
  setProcessingChain:
    | ((processingChain: string) => void)
    | ((processingChain: string | undefined) => void);
  disabled?: boolean;
  hasEmptyOption?: boolean;
};

const ProcessingChainFilter = ({
  processingChain,
  setProcessingChain,
  disabled = false,
  hasEmptyOption = false,
}: ProcessingChainFilterProps): JSX.Element => {
  const { t } = useTranslation();

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setProcessingChain(event.target.value);
  }

  const processingChainOptions = [
    {
      label: t('deviceStatistics.processingChain.euProd'),
      value: 'EU_PROD',
    },
    {
      label: t('deviceStatistics.processingChain.euUat'),
      value: 'EU_UAT',
    },
    {
      label: t('deviceStatistics.processingChain.euInt'),
      value: 'EU_INT',
    },
  ].filter((processingChainOption) => {
    switch (getDefaultProcessingChain()) {
      case 'EU_PROD': {
        return (
          processingChainOption.value === 'EU_PROD' ||
          processingChainOption.value === 'EU_UAT'
        );
      }
      case 'EU_UAT': {
        return processingChainOption.value === 'EU_UAT';
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case 'EU_INT':
      default: {
        return processingChainOption.value === 'EU_INT';
      }
    }
  });

  const labelId = useId();

  return (
    <StyledProcessingChainWrapper>
      <InputLabel
        variant="standard"
        id={labelId}
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('deviceStatistics.processingChain.processingChain')}
      </InputLabel>
      <StihlSelect
        SelectProps={{ labelId }}
        value={processingChain ?? ''}
        onChange={handleChange}
        fullWidth
        data-testid="processingChainFilter"
        disabled={disabled}
      >
        {hasEmptyOption && (
          <MenuItem value="">
            {t('deviceStatistics.processingChain.all')}
          </MenuItem>
        )}
        {processingChainOptions.map((processingChainOption) => (
          <MenuItem
            key={processingChainOption.value}
            value={processingChainOption.value}
          >
            {processingChainOption.label}
          </MenuItem>
        ))}
      </StihlSelect>
    </StyledProcessingChainWrapper>
  );
};

export default ProcessingChainFilter;
