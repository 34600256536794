import type { TimeoutError } from '../error/timeout-error';
import { assertTimeoutInRange } from '../extras-javascript/timeout';
import type { Unsubscribe } from './_subscribe/subscribe-abortable';
import type { AbortControllerWithCauseMaybe } from './abort-controller-with-cause';
import { AbortControllerWithCause } from './abort-controller-with-cause';
import { abortOnEvent } from './abort-on-event';
import { createTimeoutErrorDefault } from './timeout-error';

/**
 * Signals an abort after a timeout.
 *
 * @param milliseconds Timeout after which an abort is triggered.
 * @param signal Signals an abort if this function's timeout to
 * trigger an abort is no longer necessary because the performed task has
 * completed or was aborted otherwise. **Use to prevent memory leaks** due to
 * the pending timeout.
 * The returned signal **will not** abort if this `signal` aborts.
 * @param abortController The abort controller to use. If not present
 * a controller dedicated to this abort will be created. Has to be
 * {@link AbortControllerWithCause} to support an {@link AbortCause}.
 * @return signal aborts if the timeout elapses before the passed `signal` is
 * aborted.
 */
export function abortOnTimeout<
  TAbortController extends
    AbortControllerWithCauseMaybe<TimeoutError> = AbortControllerWithCause<TimeoutError>,
>(
  milliseconds: number,
  signal?: AbortSignal,
  abortController: TAbortController = new AbortControllerWithCause<TimeoutError>() as unknown as TAbortController,
): TAbortController['signal'] {
  assertTimeoutInRange(milliseconds);

  function timeout(handle: (abortCause: TimeoutError) => void): Unsubscribe {
    const timeoutId = setTimeout(() => {
      handle(createTimeoutErrorDefault(milliseconds));
    }, milliseconds);
    return () => {
      clearTimeout(timeoutId);
    };
  }

  return abortOnEvent(timeout, signal, abortController);
}
