import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-link';
import StihlIconMedal from '../../../../../base/stihl-material-ui/icons/stihl-icon-medal';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { StyledBodyTableCell } from './device-search-result-shared';

export const BodyTableCellHoverable = styled(StyledBodyTableCell)`
  /* highlight all table cells of this type if any of their siblings (of the same type) are hovered */
  /* progressive enhancement - not really that serious if it does not work */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:hover,
  tr:has(> &:hover) > & {
    background-color: ${stihlColor.greyLight};
  }
`;

const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const DeviceSearchResultDeviceCustomerRole: FC<{
  role: 'OWNER' | 'DEPUTY' | null;
}> = ({ role }) => {
  const { t } = useTranslation();

  if (!role) return null;

  if (role === 'OWNER') {
    return (
      <IconAligner>
        <StihlIconMedal color="grey.500" aria-hidden />

        {t('customerDetails.owner')}
      </IconAligner>
    );
  }

  return (
    <IconAligner>
      <StihlIconLink aria-hidden />

      {t('customerDetails.deputy')}
    </IconAligner>
  );
};
