import type { AbortSignalWithCauseMaybe } from '../abort-controller-with-cause';
import type { EndSubscription } from './subscribe-abortable';
import { subscribeAbortable } from './subscribe-abortable';

type SubscribeAbortSignalOptions<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = {
  target: TAbortSignalAwaited;
  onTargetAbort?: (target: TAbortSignalAwaited) => void;
  signal?: TAbortSignal;
  onSignalAbort?: (target: TAbortSignal) => void;
};

function makeSubscribeToAbortSignal<
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(signal: TAbortSignal) {
  return (handle: (signal: TAbortSignal) => void) => {
    const handleAbort = (): void => {
      handle(signal);
    };
    signal.addEventListener('abort', handleAbort, { once: true });
    return () => {
      signal.removeEventListener('abort', handleAbort);
    };
  };
}

/**
 * Subscribe to a `target` abort signal until another `signal` aborts.
 *
 * @param target whose `abort` event is awaited.
 * @param signal if aborted ends the subscription and waiting for `target`
 * being {@link AbortSignal#aborted}.
 * @param onTargetAbort Called when `target` is aborted.
 * @param onSignalAbort Called when `signal` is aborted.
 */
export function subscribeAbortSignal<
  TAbortSignalAwaited extends AbortSignalWithCauseMaybe,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>({
  target,
  signal,
  onTargetAbort,
  onSignalAbort,
}: SubscribeAbortSignalOptions<
  TAbortSignalAwaited,
  TAbortSignal
>): EndSubscription {
  return subscribeAbortable({
    signal,
    initialValue: target,
    subscribe: makeSubscribeToAbortSignal(target),
    isDone: (value, error): boolean => {
      if (error !== undefined) {
        return true;
      }
      return value?.aborted ?? false;
    },
    onValue: (value: TAbortSignalAwaited) => {
      if (value.aborted) {
        onTargetAbort?.(value);
      }
    },
    onAbort: onSignalAbort,
  });
}
