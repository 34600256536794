import type {
  AppAuthenticationSession,
  AppAuthenticationSessionUnauthenticated,
} from '../../model/authentication-session';
import { useAppAuthenticationSessionContext } from './app-authentication-session-provider';

export default function useAppAuthenticationSession(): AppAuthenticationSession {
  return useAppAuthenticationSessionContext().appAuthenticationSession;
}

export function useAppAuthenticationSessionAuthenticated(): Exclude<
  AppAuthenticationSession,
  AppAuthenticationSessionUnauthenticated
> {
  const session = useAppAuthenticationSession();

  if (session.authType === undefined) {
    throw new Error('User is not authenticated!');
  }

  return session;
}
