import type { AxiosError } from 'axios';
import instance from '../../../app-shell/service/axios';
import type { HealthStatus } from '../../model/health-status.model';

export default async function getHealthStatus(
  signal?: AbortSignal,
): Promise<HealthStatus> {
  return instance
    .get<HealthStatus>(
      `${process.env.REACT_APP_ISP_API_HEALTH_BASE_URL}/health`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(
        (error.response && (error.response.data as Error).message) ??
          error.message,
      );
    });
}
