import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconExit from '../../../base/stihl-material-ui/icons/stihl-icon-exit';
import StihlIconUser from '../../../base/stihl-material-ui/icons/stihl-icon-user';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAppAuthenticationSessionContext } from '../../app-authentication/service/app-authentication-session/app-authentication-session-provider';
import { useAppAuthenticationSessionAuthenticated } from '../../app-authentication/service/app-authentication-session/app-authentication-session-service';

export const UserManagementMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const session = useAppAuthenticationSessionAuthenticated();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { appAuthenticationClient } = useAppAuthenticationSessionContext();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    return setAnchorElement(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorElement(null);
  }

  function handleLogoutMenuClose(): void {
    void appAuthenticationClient?.logout();

    handleClose();
  }

  return (
    <>
      <Button
        data-testid="userMenuTestId"
        aria-controls="user-management-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          padding: '0.25rem 1rem',
          blockSize: '1.5rem',
          textTransform: 'none',
          backgroundColor: `${stihlColor.greyDark}`,
          color: `${stihlColor.white}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            background: `${stihlColor.fontGrey}`,
          },
        }}
      >
        <StihlIconUser color="primary.contrastText" />
        <Typography ml={1}>{session.username}</Typography>
      </Button>
      <Menu
        variant="selectedMenu"
        disableAutoFocusItem
        elevation={0}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        id="user-management-menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenu-paper': {
            inlineSize: '180px',
          },
          '& .MuiList-root': {
            border: `1px solid ${stihlColor.greyBase}`,
            paddingTop: '0',
            paddingBottom: '0',
          },
          '& .MuiMenuItem-root': {
            backgroundColor: `${stihlColor.greyDark}`,
            color: `${stihlColor.white}`,
            paddingTop: '1rem',
            paddingBottom: '1rem',
            '&:hover, &:focus': {
              backgroundColor: `${stihlColor.fontGrey}`,
            },
          },
        }}
      >
        <MenuItem
          data-testid="logoutMenuItemTestId"
          onClick={handleLogoutMenuClose}
        >
          <StihlIconExit />
          <Typography component="span" ml={1}>
            {t('logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
