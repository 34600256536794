export type ErrorCauseOption<TCause extends Error = Error> = {
  cause?: TCause;
};

/**
 * Call in an custom error constructor to initialize a possible cause.
 *
 * @param errorConstructing
 * @param options
 *
 * @see https://github.com/tc39/proposal-error-cause
 * @see https://exploringjs.com/impatient-js/ch_exception-handling.html#error.cause
 */
export function initErrorCause<
  TOptions extends ErrorCauseOption,
  TError extends Error,
>(errorConstructing: TError, options?: TOptions): TError {
  if (options?.cause == null || errorConstructing.cause != null) {
    return errorConstructing;
  }

  const cause = options.cause;
  errorConstructing.cause = cause;
  const causeStack = typeof cause.stack === 'string' ? cause.stack : undefined;
  if (causeStack && errorConstructing.stack) {
    errorConstructing.stack = `${errorConstructing.stack}\nCAUSE: ${causeStack}`;
  }

  return errorConstructing;
}
