/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { Dispatch, JSX, SetStateAction } from 'react';
import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import type { ChipType } from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import {
  FilterParameterValue,
  StihlChipContainer,
} from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type {
  DeviceModel,
  DeviceType,
  ItemType,
} from '../../../../device/model';
import DeviceModelFilter from '../../filters/device-model-filter';
import ProcessingChainFilter from '../../filters/processing-chain-filter';
import DevicePropertyFilter from './device-property-filter';

const CardWrapper = styled(Card)`
  min-block-size: 270px;
  padding: 0.75rem;
`;

export type DeviceManagementFilterProps = {
  deviceTypes: ItemType<DeviceType>[];
  chips: ChipType[];
  setChips: Dispatch<SetStateAction<ChipType[]>>;
  handleSearchCallback: () => void;
  isSearchLoading: boolean;
};

function hasDuplicates(chips: ChipType[]): boolean {
  const filterTypes = new Set<FilterParameterValue>();

  for (const chip of chips) {
    // device id and serial number filter are ok to be duplicates
    if (
      chip.filter === FilterParameterValue.SerialNumber ||
      chip.filter === FilterParameterValue.DeviceId
    ) {
      continue;
    }

    if (filterTypes.has(chip.filter)) {
      return true;
    }

    filterTypes.add(chip.filter);
  }

  return false;
}

function useChip<TValue extends string = string>(
  chips: ChipType[],
  setChips: Dispatch<SetStateAction<ChipType[]>>,
  chipType: FilterParameterValue,
): [value: TValue | undefined, setValue: (value: TValue | undefined) => void] {
  const value = useMemo(
    () =>
      chips.find((chip) => chip.filter === chipType)?.searchTerm as
        | TValue
        | undefined,
    [chips, chipType],
  );

  const setValue = useCallback(
    (valueNew: TValue | undefined) => {
      setChips((chipsCurrent) =>
        valueNew == null
          ? chipsCurrent.filter((chip) => chip.filter !== chipType)
          : [...chipsCurrent, { filter: chipType, searchTerm: valueNew }],
      );
    },
    [setChips, chipType],
  );

  return [value, setValue];
}

const DeviceManagementFilter = ({
  deviceTypes,
  chips,
  setChips,
  handleSearchCallback,
  isSearchLoading,
}: DeviceManagementFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [hasDuplicate, setHasDuplicate] = useState(() => hasDuplicates(chips));

  const setChipsWithDuplicateHandling = useCallback<typeof setChips>(
    (chipsNew) => {
      setHasDuplicate(false);

      setChips((chipsInner) => {
        const chipsUpdated =
          typeof chipsNew === 'function' ? chipsNew(chipsInner) : chipsNew;

        if (hasDuplicates(chipsUpdated)) {
          setHasDuplicate(true);

          return chipsInner;
        }

        return chipsUpdated;
      });
    },
    [setChips],
  );

  const [deviceModel, setDeviceModel] = useChip<DeviceModel>(
    chips,
    setChipsWithDuplicateHandling,
    FilterParameterValue.DeviceModel,
  );

  const [processingChain, setProcessingChain] = useChip(
    chips,
    setChipsWithDuplicateHandling,
    FilterParameterValue.ProcessingChain,
  );

  const addChip = useCallback(
    (chip: ChipType) => {
      setChipsWithDuplicateHandling((chipsCurrent) => [...chipsCurrent, chip]);
    },
    [setChipsWithDuplicateHandling],
  );

  return (
    <CardWrapper data-testid="deviceManagementFilter">
      <Grid container>
        <Grid item xs={12}>
          <Typography>{t('deviceManagementFilter.instruction')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ display: 'flex' }}>
            <Grid item xl={2} xs={6}>
              <DeviceModelFilter
                deviceModel={deviceModel}
                setDeviceModel={setDeviceModel}
                disabled={deviceModel != null}
                hasEmptyOption
              />
            </Grid>
            <Grid item xl={2} xs={6}>
              <ProcessingChainFilter
                processingChain={processingChain}
                setProcessingChain={setProcessingChain}
                disabled={processingChain != null}
                hasEmptyOption
              />
            </Grid>
            <Grid item xl={7} xs={12}>
              <DevicePropertyFilter
                deviceTypes={deviceTypes}
                addChip={addChip}
                chips={chips}
                deviceModel={deviceModel}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StihlChipContainer
            chips={chips}
            setChips={setChips}
            isInvalidInput={hasDuplicate}
          />
          <StihlButtonSecondaryLoading
            loading={isSearchLoading}
            onClick={handleSearchCallback}
            disabled={deviceModel == null}
            data-testid="searchManagedDevicesButton"
          >
            {t('deviceManagementFilter.startSearch')}
          </StihlButtonSecondaryLoading>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default DeviceManagementFilter;

/* eslint-enable react/jsx-max-depth */
