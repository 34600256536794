import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppAuthenticationType } from '../../../app-authentication/model/authentication-session';
import useAppAuthenticationSession from '../../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import type {
  TermsAndConditionsBody,
  TermsAndConditionsResponse,
} from '../../model/terms-and-conditions.model';
import { currentTermsVersion } from '../documents-utils';
import {
  getTermsAndConditionsAcceptance,
  postTermsAndConditionsAcceptance,
} from '../terms-and-conditions-api/terms-and-conditions-api-client';

export function useTermsAndConditions(
  userId: string,
  enabled = true,
): UseQueryResult<TermsAndConditionsResponse, Error> {
  return useQuery<TermsAndConditionsResponse, Error>({
    queryKey: ['termsAndConditionsAcceptance', userId],
    queryFn: async () => getTermsAndConditionsAcceptance(userId),
    enabled,
  });
}

export function useAcceptTermsAndConditions(): UseMutationResult<
  unknown,
  Error,
  { userId: string; version: string }
> {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ userId, version }) =>
        postTermsAndConditionsAcceptance(userId, version),
      onSuccess: (_, { userId, version }) => {
        queryClient.setQueryData<TermsAndConditionsBody>(
          ['termsAndConditionsAcceptance', userId],
          { stihlId: userId, agreementVersion: version, date: new Date() },
        );

        void queryClient.invalidateQueries({
          queryKey: ['termsAndConditionsAcceptance', userId],
        });
      },
    },
    queryClient,
  );
}

export function useAreTermsAndConditionsAccepted(): boolean | undefined {
  const session = useAppAuthenticationSession();

  const termsAccepted = useTermsAndConditions(
    // eslint-disable-next-line @getify/proper-ternary/where
    session.authType === AppAuthenticationType.Dealer ? session.userId : '',
    session.authType === AppAuthenticationType.Dealer,
  );

  return termsAccepted.data?.agreementVersion.includes(currentTermsVersion);
}
