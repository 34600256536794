import instance from '../../../app-shell/service/axios';
import type {
  TermsAndConditionsBody,
  TermsAndConditionsResponse,
} from '../../model/terms-and-conditions.model';
import {
  getTermsAndConditionsPath,
  getTermsAndConditionsPathFor,
} from './terms-and-conditions-api-config';

export async function getTermsAndConditionsAcceptance(
  userId: string,
): Promise<TermsAndConditionsResponse> {
  return (
    instance
      //headers need to be set here as addTokensInterceptor() is not done yet
      .get<TermsAndConditionsResponse>(getTermsAndConditionsPathFor(userId))
      .then((response) => response.data)
  );
}

export async function postTermsAndConditionsAcceptance(
  stihlId: string,
  agreementVersion: string,
): Promise<string> {
  const date = new Date();
  const body: TermsAndConditionsBody = {
    stihlId,
    agreementVersion,
    date,
  };
  return instance
    .post<string>(getTermsAndConditionsPath(), body)
    .then((response) => response.data);
}
