import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import type { FC } from 'react';
import { useState } from 'react';
import { useDealerDetails } from '../../dealer/service/dealer-details/dealer-details-service';

export const supportLocales: string[] = [
  'de-DE',
  'de-AT',
  'de-CH',
  'it-CH',
  'fr-CH',
  'nl-BE',
  'fr-BE',
  'nl-NL',
  'fr-FR',
  'fr-LU',
  'da-DK',
  'nb-NO',
  'sv-SE',
  'pt-PT',
  'es-ES',
  'es-US',
  'it-IT',
  'en-US',
  'en-GB',
  'en-NZ',
  'en-AU',
  'en-INT',
  'fi-FI',
];

const formStyles = { border: 0, contain: 'paint' };

const SupportWrapper = styled.div`
  display: grid;
  block-size: 100%;
`;

const PositionedLoadingSpinner = styled(CircularProgress)`
  justify-self: center;
`;

type SupportFrameProps = {
  title: string;
  testId: string;
  urlParameter: string;
};

export const SupportFrame: FC<SupportFrameProps> = ({
  title,
  testId,
  urlParameter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dealerLocale = useDealerDetails().data?.locale;

  function hideLoadingButton(): void {
    setIsLoading(false);
  }

  const isLocaleValid: boolean = (
    supportLocales as (string | undefined)[]
  ).includes(dealerLocale);

  const localeOrFallback = isLocaleValid ? dealerLocale : 'en-INT';

  return (
    <SupportWrapper>
      {isLoading && <PositionedLoadingSpinner size="4rem" />}
      <iframe
        title={title}
        data-testid={testId}
        width="100%"
        height="100%"
        loading="lazy"
        onLoad={() => hideLoadingButton()}
        allowFullScreen
        style={formStyles}
        src={`${
          process.env.REACT_APP_SUPPORT as string
        }/${localeOrFallback}/${urlParameter}`}
      />
    </SupportWrapper>
  );
};
