import type { AxiosError, AxiosResponse } from 'axios';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import instance from '../../../app-shell/service/axios';
import type { DeviceModel, DeviceType } from '../../../device/model';
import type { ManagedDevice } from '../../model/overview.models';
import type {
  ReleaseManagementMetaData,
  SoftwareRelease,
  SoftwareUpdatePackageFilesReponse,
} from '../../model/release-management/release-management.model';
import type { ActivationStateRequestBody } from '../../model/search.models';
import type { SoftwareVersionMetadata } from '../../model/software-versions.model';
import type {
  DeviceStatistics,
  DeviceStatus,
} from '../../model/statistics.model';
import type {
  SwMassAssignment,
  SwMassAssignmentRequest,
  SwMultiAssignmentRequest,
  SwUpdateResponse,
} from '../../model/update-management.model';

export async function getDeviceManagementTabs(
  signal?: AbortSignal,
): Promise<TabsResponse[]> {
  return instance
    .get<TabsResponse[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/management/tabs`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getErrorStatistics(
  deviceModel: DeviceModel,
): Promise<DeviceStatus[]> {
  return instance
    .get<DeviceStatus[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/error-devices?deviceModel=${deviceModel}`,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getDevicesWithSoftwareVersion(
  deviceModel: DeviceModel,
  softwareVersion: string,
): Promise<DeviceStatus[]> {
  return instance
    .get<DeviceStatus[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/devices-sw-version/${softwareVersion}?deviceModel=${deviceModel}`,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getStatistics(
  processingChain: string,
  deviceModel: string,
  signal?: AbortSignal,
): Promise<DeviceStatistics> {
  return instance
    .get<DeviceStatistics>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/statistics?processingChain=${processingChain}&deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getManagedDevices(
  filterParameters: URLSearchParams,
  signal?: AbortSignal,
): Promise<ManagedDevice[]> {
  return instance
    .get<ManagedDevice[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/management`,
      { params: filterParameters, signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}. Device Management Search Error.`);
    });
}

export async function getManagedDeviceDetails(
  deviceId: string,
  deviceModel: DeviceModel | undefined,
  signal?: AbortSignal,
): Promise<ManagedDevice> {
  return instance
    .get<ManagedDevice>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/management/device-details/${deviceId}?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getSoftwareVersions(
  deviceModel: DeviceModel,
  signal?: AbortSignal,
): Promise<string[]> {
  return instance
    .get<string[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/available-versions?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getSoftwareVersionsMetadata(
  deviceModel: DeviceModel,
  shouldFetchOnlyValid: boolean,
  signal?: AbortSignal,
): Promise<SoftwareVersionMetadata[]> {
  return instance
    .get<SoftwareVersionMetadata[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/versions-metadata?deviceModel=${deviceModel}&onlyValid=${shouldFetchOnlyValid}`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function startMultiAssignmentUpdate(
  deviceModel: DeviceModel,
  deviceIds: string[],
  targetSwVersion: string,
): Promise<number> {
  const body: SwMultiAssignmentRequest = {
    deviceModel,
    deviceIds,
    targetSwVersion,
  };

  return instance
    .post<SwUpdateResponse>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/software-version-multi-assignments`,
      body,
    )
    .then((response) => response.data.numAssigned)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function startDryRunMassAssignmentUpdate(
  deviceModel: DeviceModel,
  installedSwVersion: string,
  targetSwVersion: string,
  deviceTypes?: DeviceType[],
  country?: string,
  iotDeviceFlag?: string,
): Promise<number> {
  const body: SwMassAssignmentRequest = {
    deviceModel,
    installedSwVersion,
    targetSwVersion,
    iotDeviceCountry: country,
    deviceTypes,
    iotDeviceFlag,
  };
  return instance
    .post<SwUpdateResponse>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/dry-run-software-version-mass-assignments`,
      body,
    )
    .then((response) => response.data.numAssigned)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function startMassAssignmentUpdate(
  deviceModel: DeviceModel,
  installedSwVersion: string,
  targetSwVersion: string,
  deviceTypes?: DeviceType[],
  country?: string,
  iotDeviceFlag?: string,
): Promise<number> {
  const body: SwMassAssignmentRequest = {
    deviceModel,
    installedSwVersion,
    targetSwVersion,
    iotDeviceCountry: country,
    deviceTypes,
    iotDeviceFlag,
  };
  return instance
    .post<SwUpdateResponse>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/software-version-mass-assignments`,
      body,
    )
    .then((response) => response.data.numAssigned)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getMassAssignments(
  deviceModel: DeviceModel,
  signal?: AbortSignal,
): Promise<SwMassAssignment[]> {
  return instance
    .get<SwMassAssignment[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/software-version-mass-assignments?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function invalidateMassAssignment(
  assignmentId: number,
): Promise<void> {
  await instance
    .put(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/software-update/software-version-mass-assignments/${assignmentId}`,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function getSoftwareReleases(
  deviceModel: DeviceModel,
  signal?: AbortSignal,
): Promise<SoftwareRelease[]> {
  return instance
    .get<SoftwareUpdatePackageFilesReponse[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/release-management/package-files?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => {
      const responseData: SoftwareRelease[] = response.data.map((data) => {
        return {
          ...data,
          deviceModel,
          isMetadataAvailable: data.isMetaDataAvailable,
        };
      });
      return responseData;
    })
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function addReleaseMetadata(
  metadata: ReleaseManagementMetaData,
): Promise<void> {
  const combinedUpgradeSourceVersions = [
    ...(metadata.supportedUpgradeSourceVersions ?? []),
    ...(metadata.supportedUpgradeSourceVersionsUserInput ?? []),
  ];

  const editedMetaData = {
    ...metadata,
    supportedUpgradeSourceVersions:
      combinedUpgradeSourceVersions.length === 0
        ? undefined
        : combinedUpgradeSourceVersions,
  };

  delete editedMetaData.supportedUpgradeSourceVersionsUserInput;

  await instance
    .post(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/release-management/add-metadata`,
      editedMetaData,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function enableOrDisableDeviceId(
  body: ActivationStateRequestBody,
): Promise<AxiosResponse> {
  return instance
    .put(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/activation-state`,
      body,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}

export async function invalidateReleaseMetadata(
  packageId: number,
): Promise<void> {
  await instance
    .put(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/release-management/invalidate-metadata/${packageId}`,
    )
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}
