import { isError } from '../extras-typescript-asserts/type-error-asserts';
import type { ErrorRecord } from './error-record';
import { isErrorRecord } from './error-record';

type ErrorLike = Error | ErrorRecord<Error>;

export function isErrorLike(value: unknown): value is ErrorLike {
  return isError(value) || isErrorRecord(value);
}

export function assertErrorLike(value: unknown): asserts value is ErrorLike {
  if (!isErrorLike(value)) {
    throw new TypeError(
      'Expected value to be have the same properties as an "Error" instance.',
    );
  }
}

export function asErrorLike(value: unknown): ErrorLike {
  assertErrorLike(value);
  return value;
}
