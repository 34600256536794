import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StihlBackground } from '../../../base/stihl-material-ui/components/stihl-background/stihl-background';
import StihlButtonPrimary from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { AppAuthenticationType } from '../../app-authentication/model/authentication-session';
import { useAppAuthenticationSessionContext } from '../../app-authentication/service/app-authentication-session/app-authentication-session-provider';

const LoginOptionsContainer = styled.div`
  inline-size: 50%;
  max-inline-size: 700px;
  block-size: 50%;
  max-block-size: 320px;
  margin: auto;
  padding: 60px;
  background-color: ${stihlColor.white};
`;

const StyledRuler = styled.hr`
  position: absolute;
  inline-size: 26px;
  block-size: 6px;
  background-color: ${stihlColor.black};
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding-block-start: 60px;

  > button {
    inline-size: 100%;
  }
`;

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { setAuthProvider } = useAppAuthenticationSessionContext();

  function requestDealerLogin(): void {
    void setAuthProvider(AppAuthenticationType.Dealer).login();
  }

  function requestAzureAdLogin(): void {
    void setAuthProvider(AppAuthenticationType.Support).login();
  }

  return (
    <StihlBackground>
      <LoginOptionsContainer>
        <Typography variant="h1">
          {t('loginPage.welcomeToServicePortal')}
        </Typography>
        <StyledRuler />
        <ButtonContainer>
          <StihlButtonPrimary
            data-testid="azureAdLoginButton"
            onClick={requestAzureAdLogin}
          >
            {t('loginPage.loginAzure')}
          </StihlButtonPrimary>
          <StihlButtonPrimary
            onClick={requestDealerLogin}
            data-testid="dealerLoginButton"
          >
            {t('loginPage.loginDealer')}
          </StihlButtonPrimary>
        </ButtonContainer>
      </LoginOptionsContainer>
    </StihlBackground>
  );
};

export default LoginPage;
