import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import StihlLogo from '../../../base/stihl-material-ui/icons/stihl-logo';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useDealerDetails } from '../../dealer/service/dealer-details/dealer-details-service';
import { LanguageMenu } from '../../language-menu/ui/language-menu';
import { UserManagementMenu } from '../../user-management/ui/user-management-menu';
import Footer, { FOOTER_HEIGHT_IN_REM } from './footer';
import SideBar, {
  APPBAR_HEIGHT_IN_PX,
  NAVIGATION_RAIL_SIZE_SPACING,
} from './sidebar';

const FrameLayout = styled.div`
  /* create stacking context for z-index. needed so shadow of header is displayed over rail */
  position: relative;
  display: grid;
  grid-template-areas:
    'header header'
    'rail content'
    'rail footer';
  grid-template-rows: ${APPBAR_HEIGHT_IN_PX}px 1fr;
  /* target platforms have sufficient multi-column support  */
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  grid-template-columns:
    minmax(${NAVIGATION_RAIL_SIZE_SPACING}rem, min-content)
    1fr;
  inline-size: 100vw;
  block-size: 100vh;
`;

const AppHeader = styled.div`
  display: flex;
  grid-area: header;
  justify-content: space-between;
  block-size: ${APPBAR_HEIGHT_IN_PX}px;
  color: ${stihlColor.white};
  background-color: ${stihlColor.greyDark};
`;

const AppHeaderMain = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const HeadingTypography = styled(Typography)`
  font-weight: 900;
  font-size: 1.5rem;
  font-family: 'STIHL Contraface Display Title', sans-serif;
  text-transform: uppercase;

  @media screen and (max-width: 1020px) {
    font-size: 1rem;
  }
`;

const AccountIconPositioner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: end;
  padding: 0.125rem 1rem;
  font-size: 1rem;
`;

const ActionMenusPositioner = styled.span`
  display: flex;
`;

const DealerDetailsPositioner = styled.span`
  padding: 0.125rem 1rem;
  font-size: 14px;
  line-height: 14px;
`;

const AppMainContent = styled.main`
  position: relative;
  grid-area: content;
  margin-block-end: ${FOOTER_HEIGHT_IN_REM + 0.1}rem;
  overflow-y: auto;
`;

const AppSidebar = styled.div`
  grid-area: rail;
`;

const AppFooter = styled.div`
  position: absolute;
  grid-area: footer;
  inset-block: auto;
  inset-block-end: 0;
  inline-size: 100%;
`;

export const AppNavigationFrame: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const dealerDetails = useDealerDetails();

  return (
    /* eslint-disable react/jsx-max-depth */
    <FrameLayout>
      <AppHeader>
        <AppHeaderMain>
          <HeadingTypography data-testid="appNavigationFrame">
            {t('servicePortal')}
          </HeadingTypography>
          <AccountIconPositioner>
            <ActionMenusPositioner>
              <LanguageMenu />
              <UserManagementMenu />
            </ActionMenusPositioner>
            {dealerDetails.data && (
              <>
                <DealerDetailsPositioner data-testid="dealer-details">
                  {dealerDetails.data.dealerCompanyName}
                </DealerDetailsPositioner>
                <DealerDetailsPositioner>
                  {dealerDetails.data.street && `${dealerDetails.data.street} `}
                  {dealerDetails.data.houseNo &&
                    `${dealerDetails.data.houseNo} - `}
                  {`${dealerDetails.data.postalCode} ${dealerDetails.data.city}`}
                </DealerDetailsPositioner>
              </>
            )}
          </AccountIconPositioner>
        </AppHeaderMain>
        <StihlLogo />
      </AppHeader>
      <AppSidebar>
        <SideBar />
      </AppSidebar>
      <AppMainContent>{children}</AppMainContent>
      <AppFooter>
        <Footer />
      </AppFooter>
    </FrameLayout>
    /* eslint-enable react/jsx-max-depth */
  );
};
