import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChipType } from '../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { FilterParameterValue } from '../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { useAppAuthenticationSessionAuthenticated } from '../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import QueryComponent from '../../app-shell/ui/query-component';
import type { DeviceModel } from '../../device/model';
import { useManagedDevices } from '../service/device-management-service/device-management-service';
import { getDefaultProcessingChain } from '../service/processing-chain';
import DeviceManagementFilter from './overview-items/device-filters/device-management-filter';
import DeviceManagementTable from './overview-items/device-management-table/device-management-table';
import { typeModelMatcher } from './overview-items/utils/device-management.const';

type DeviceModelOverviewProps = {
  isAllowedForUpdateManagement: boolean;
};

const DeviceManagementOverview: FC<DeviceModelOverviewProps> = ({
  isAllowedForUpdateManagement,
}) => {
  const { t } = useTranslation();
  const session = useAppAuthenticationSessionAuthenticated();

  const initialDeviceModel =
    session.deviceModels.length === 1 ? session.deviceModels[0] : undefined;

  const hasInitializedDeviceModel = useRef(false);

  const [chips, setChips] = useState<ChipType[]>([
    {
      filter: FilterParameterValue.ProcessingChain,
      searchTerm: getDefaultProcessingChain(),
    },
  ]);

  const deviceModel = useMemo(
    () =>
      chips.find((chip) => chip.filter === FilterParameterValue.DeviceModel)
        ?.searchTerm as DeviceModel | undefined,
    [chips],
  );

  const deviceTypes = useMemo(
    () => (deviceModel == null ? [] : typeModelMatcher[deviceModel]),
    [deviceModel],
  );

  if (!hasInitializedDeviceModel.current && initialDeviceModel != null) {
    hasInitializedDeviceModel.current = true;

    setChips((chipsCurrent) =>
      // if user was faster to initialize the device model filter than we were loading the valid device models,
      // we just skip initialization
      chipsCurrent.some(
        (chip) => chip.filter === FilterParameterValue.DeviceModel,
      )
        ? chipsCurrent
        : [
            ...chipsCurrent,
            {
              filter: FilterParameterValue.DeviceModel,
              searchTerm: initialDeviceModel,
            },
          ],
    );
  }

  const deviceManagementDevices = useManagedDevices(chips);

  return (
    <>
      <DeviceManagementFilter
        chips={chips}
        setChips={setChips}
        deviceTypes={deviceTypes}
        handleSearchCallback={() => {
          void deviceManagementDevices.refetch();
        }}
        isSearchLoading={deviceManagementDevices.isFetching}
      />

      <QueryComponent
        data={deviceManagementDevices}
        placeholderText={t('deviceManagementFilter.hint')}
      >
        {deviceManagementDevices.data ?? deviceManagementDevices.error ? (
          <DeviceManagementTable
            deviceManagementDevices={deviceManagementDevices.data}
            deviceModel={deviceModel}
            error={deviceManagementDevices.error}
            chips={chips}
            isAllowedForUpdateManagement={isAllowedForUpdateManagement}
          />
        ) : undefined}
      </QueryComponent>
    </>
  );
};

export default DeviceManagementOverview;
