import {
  isQuotaExceededError,
  isSecurityError,
} from '../../../../base/extras-javascript/dom-exception';
import { AppAuthenticationError } from './app-authentification-error';

export function createAppAuthenticationClientStorage(
  storage: 'localStorage' | 'sessionStorage',
  onError: (error: AppAuthenticationError) => void,
): Storage {
  assertStorageAvailable(storage);

  return createStorageWithStorageFullHandling(window[storage], onError);
}

/**
 * Test that a storage is available.
 *
 * The storage might not be available if it is deactivated ({@link isSecurityError})
 * or when it is full ({@link isQuotaExceededError}).
 * Note that the `SecurityError` is thrown immediately when accessing `window.localStorage` or `window.sessionStorage`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 */
function assertStorageAvailable(
  storageType: 'localStorage' | 'sessionStorage',
): void {
  try {
    const storage = window[storageType];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
  } catch (error) {
    if (
      error instanceof DOMException &&
      (isSecurityError(error) || isQuotaExceededError(error))
    ) {
      throw AppAuthenticationError.fromCause(
        error,
        'StorageUnavailable',
        `${storageType} is either full or disabled`,
      );
    }
  }
}

function createStorageWithStorageFullHandling(
  storage: Storage,
  onError: (error: AppAuthenticationError) => void,
): Storage {
  return {
    get length() {
      return storage.length;
    },
    key: storage.key.bind(storage),
    getItem: storage.getItem.bind(storage),
    removeItem: storage.removeItem.bind(storage),
    clear: storage.clear.bind(storage),
    setItem(key: string, value: string) {
      try {
        storage.setItem(key, value);
      } catch (error) {
        let errorResulting = error;

        if (error instanceof DOMException && isQuotaExceededError(error)) {
          const authError = (errorResulting = AppAuthenticationError.fromCause(
            error,
            'StorageFull',
            `Cannot set item "${key}" to storage - it is full`,
          ));

          onError(authError);
        }

        throw errorResulting;
      }
    },
  };
}
