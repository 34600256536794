import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import type { FieldProps, FormikHelpers } from 'formik';
import { useFormikContext, Formik, Field, Form } from 'formik';
import type { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { useEffect, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { object, string } from 'yup';
import { StihlHelperText } from '../../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import StihlSelect from '../../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlTextField from '../../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconClose from '../../../../../base/stihl-material-ui/icons/stihl-icon-close';
import type { DeviceModel } from '../../../model/device.model';
import type { SearchObject } from '../../../model/search.model';
import { SearchParameterValues } from '../../../model/search.model';

export const SEARCHBAR_WIDTH_IN_PX = 380;

const StyledForm = styled(Form)`
  display: flex;
  gap: 10px;
  /* Do not remove this- needed so that the form doesn't change its size when an error message is available */
  min-block-size: 110px;
  margin: 1.5rem 0;
`;

type SearchDeviceBarProps = {
  isLoading: boolean;
  selectors: SearchParameterValues[];
  deviceModels: DeviceModel[];
  allowedCombinations: Record<SearchParameterValues, (DeviceModel | 'any')[]>;
};

function translateSearchParameter(
  searchParameter: SearchParameterValues,
  t: (key: string) => string,
): string {
  switch (searchParameter) {
    case SearchParameterValues.SerialNumber: {
      return t('searchParameters.serialNumberLabel');
    }
    case SearchParameterValues.DeviceId: {
      return t('searchParameters.deviceIdLabel');
    }
    case SearchParameterValues.Imsi: {
      return t('searchParameters.imsiLabel');
    }
    case SearchParameterValues.Imei: {
      return t('searchParameters.imeiLabel');
    }
    case SearchParameterValues.Email: {
      return t('searchParameters.emailLabel');
    }
    case SearchParameterValues.FleetEmail: {
      return t('searchParameters.organizationEmail');
    }
  }
}

const SearchBarSelectParameter: FC<{
  name: string;
  searchParameters: SearchParameterValues[];
  value: SearchParameterValues;
  onChange: ChangeEventHandler;
}> = ({ searchParameters, value, onChange, name }) => {
  const { t } = useTranslation();
  const labelId = useId();

  return (
    <>
      <InputLabel variant="standard" id={labelId}>
        {t('deviceSearch.searchCriteria')}
      </InputLabel>

      <StihlSelect
        SelectProps={{ labelId }}
        name={name}
        value={value}
        sx={{ inlineSize: '250px' }}
        onChange={onChange}
        data-testid="searchSelector"
      >
        {searchParameters.map((searchParameter) => (
          <MenuItem key={searchParameter} value={searchParameter}>
            {translateSearchParameter(searchParameter, t)}
          </MenuItem>
        ))}
      </StihlSelect>
    </>
  );
};

const SearchBarSelectDeviceModel: FC<{
  name: string;
  deviceModels: (DeviceModel | 'any')[];
  value: DeviceModel | 'any';
  onChange: ChangeEventHandler;
}> = ({ deviceModels, value, onChange, name }) => {
  const { t } = useTranslation();
  const labelId = useId();
  const { setFieldValue } = useFormikContext<SearchObject>();

  useEffect(() => {
    if (!deviceModels.includes(value)) {
      void setFieldValue(
        'deviceModel' satisfies keyof SearchObject,
        deviceModels[0],
      );
    }
  }, [deviceModels, value, setFieldValue]);

  return (
    <>
      <InputLabel variant="standard" id={labelId}>
        {t('deviceSearch.deviceModel')}
      </InputLabel>
      <StihlSelect
        SelectProps={{ labelId }}
        name={name}
        value={value}
        sx={{ inlineSize: '250px' }}
        onChange={onChange}
        data-testid="searchDeviceModel"
        disabled={deviceModels.length < 2}
      >
        {deviceModels.length === 0 ? (
          <MenuItem disabled>{t('noDeviceModel')}</MenuItem>
        ) : (
          deviceModels.map((option) => (
            <MenuItem key={option} value={option}>
              {option === 'any'
                ? t('deviceSearch.deviceModelOption.any')
                : option}
            </MenuItem>
          ))
        )}
      </StihlSelect>
    </>
  );
};

function getPlaceholderText(
  value: SearchParameterValues,
  t: (key: string) => string,
): string {
  switch (value) {
    case SearchParameterValues.Email: {
      return t('searchParameters.searchByEmail');
    }
    case SearchParameterValues.Imei: {
      return t('searchParameters.searchByImei');
    }
    case SearchParameterValues.Imsi: {
      return t('searchParameters.searchByImsi');
    }
    case SearchParameterValues.DeviceId: {
      return t('searchParameters.searchByDeviceId');
    }
    case SearchParameterValues.FleetEmail: {
      return t('searchParameters.searchByOrganizationEmail');
    }
    case SearchParameterValues.SerialNumber: {
      return t('searchParameters.searchBySerialNumber');
    }
  }
}

const SearchBarInput: FC<{
  name: string;
  value: string;
  onChange: ChangeEventHandler;
}> = ({ name, value, onChange }) => {
  const queryClient = useQueryClient();
  const { touched, errors, setFieldValue, values } =
    useFormikContext<SearchObject>();
  const { t } = useTranslation();

  function handleInputChange(event: ChangeEvent): void {
    onChange(event);
    void queryClient.cancelQueries({ queryKey: ['device'] });
    void queryClient.cancelQueries({ queryKey: ['emailSearch'] });
  }

  function handleSearchReset(): void {
    void setFieldValue('search', '');
    void queryClient.cancelQueries({ queryKey: ['device'] });
    void queryClient.cancelQueries({ queryKey: ['emailSearch'] });
  }

  return (
    <StihlTextField
      id="search"
      inputProps={{
        'data-testid': 'searchBy',
        'aria-label': getPlaceholderText(values.selector, t),
      }}
      sx={{
        '&.MuiTextField-root.MuiFormControl-marginDense': {
          inlineSize: SEARCHBAR_WIDTH_IN_PX,
          minWidth: '70px',
          marginBlockStart: '28px', // DO NOT CHANGE THIS- NEEDED FOR ALIGNMENT
        },
      }}
      placeholder={getPlaceholderText(values.selector, t)}
      error={value !== '' && Boolean(errors.search)}
      helperText={
        value !== '' && errors.search ? (
          <StihlHelperText text={errors.search} />
        ) : undefined
      }
      value={value}
      onChange={handleInputChange}
      name={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value !== '' && (
              <IconButton
                style={{ padding: '6px' }}
                size="large"
                onClick={handleSearchReset}
                data-testid="resetButton"
                aria-label={t('deviceSearch.resetButton')}
              >
                <StihlIconClose color="text.primary" />
              </IconButton>
            )}
            <IconButton
              style={{ padding: '6px' }}
              type="submit"
              disabled={
                touched.search && value !== '' && Boolean(errors.search)
              }
              size="large"
              aria-label={t('deviceSearch.searchButton')}
            >
              <SearchIcon color="secondary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

// eslint-disable-next-line max-lines-per-function
const SearchDeviceBar: FC<SearchDeviceBarProps> = ({
  selectors,
  deviceModels,
  allowedCombinations,
}) => {
  const history = useHistory();

  const { t } = useTranslation();

  function handleSearch(
    searchValue: SearchObject,
    { setSubmitting }: FormikHelpers<SearchObject>,
  ): void {
    history.push('/device', searchValue);
    setSubmitting(false);
  }

  const initialValues: SearchObject = useMemo(() => {
    const selectorInitial = selectors[0];

    return {
      deviceModel: allowedCombinations[selectorInitial][0],
      selector: selectorInitial,
      search: '',
    };
  }, [selectors, allowedCombinations]);

  const validationSchema = object().shape({
    search: string()
      .when('selector', {
        is: SearchParameterValues.SerialNumber,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .min(9, t('formik.min9Digits'))
            .max(10, t('formik.max10Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.Email,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(/^\S[^\s@]*@\S[^\s.]*\.\S+$/u, t('formik.validEmail')),
      })
      .when('selector', {
        is: SearchParameterValues.FleetEmail,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(/^\S[^\s@]*@\S[^\s.]*\.\S+$/u, t('formik.validEmail')),
      })
      .when('selector', {
        is: SearchParameterValues.Imsi,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .length(15, t('formik.exact15Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.Imei,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .length(15, t('formik.exact15Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.DeviceId,
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .required()
            .matches(
              /^[\d_A-Z]+$/u,
              t('formik.onlyNumbersOrLettersOrUnderscore'),
            )
            .max(35, t('formik.max35Chars')),
      }),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearch}
      validationSchema={validationSchema}
    >
      <StyledForm data-testid="searchform">
        <div>
          <Field name="deviceModel">
            {({
              field,
              form,
            }: FieldProps<DeviceModel | 'any', SearchObject>) => {
              const combinations = allowedCombinations[form.values.selector];
              const deviceModelsSelectable = combinations.filter(
                (deviceModel) =>
                  deviceModel === 'any' || deviceModels.includes(deviceModel),
              );

              return (
                <SearchBarSelectDeviceModel
                  name={field.name}
                  deviceModels={deviceModelsSelectable}
                  value={field.value}
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={field.onChange}
                />
              );
            }}
          </Field>
        </div>
        <div>
          <Field name="selector">
            {({ field }: FieldProps<SearchParameterValues>) => (
              <SearchBarSelectParameter
                name={field.name}
                searchParameters={selectors}
                value={field.value}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
              />
            )}
          </Field>
        </div>
        <Field name="search">
          {({ field }: FieldProps<string>) => (
            <SearchBarInput
              name={field.name}
              value={field.value}
              // eslint-disable-next-line react/jsx-handler-names
              onChange={field.onChange}
            />
          )}
        </Field>
      </StyledForm>
    </Formik>
  );
};

export default SearchDeviceBar;
