import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import type { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import { StihlTableRow } from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconArrowDown from '../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import Pagination from '../../../app-shell/ui/pagination/pagination';
import type { DeviceModel } from '../../../device/model';
import type {
  SoftwareVersionMetadata,
  SoftwareVersionsColumns,
  SoftwareVersionsHeadCell,
} from '../../model/software-versions.model';
import { DeviceManagementSoftwareVersionsDetails } from './device-management-software-versions-details';

const StyledArrowIconRight = styled(StihlIconArrowDown)`
  transform: rotate(270deg);
`;

const StyledArrowIconLeft = styled(StihlIconArrowDown)`
  transform: rotate(90deg);
`;

const StyledTableCell = styled(TableCell)<
  TableCellProps & { isHidden: boolean }
>`
  display: ${(props) => (props.isHidden ? 'none' : 'table-cell')};
  max-inline-size: 170px;
  overflow-wrap: break-word;
`;

export const StyledTableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
`;

// eslint-disable-next-line max-lines-per-function
export const DeviceManagementSoftwareVersionsTable = ({
  deviceModel,
  softwareVersionsMetadata,
}: {
  deviceModel: DeviceModel;
  softwareVersionsMetadata: SoftwareVersionMetadata[];
}): JSX.Element => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof SoftwareVersionsColumns>('swVersion');
  const [isHoveredAction, setIsHoveredAction] = useState(false);
  const [currentOpenMetadataDetails, setCurrentOpenMetadataDetails] = useState<
    SoftwareVersionMetadata | undefined
  >(undefined);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [page, setPage] = useState(1);

  const headCells: SoftwareVersionsHeadCell[] = [
    { id: 'swVersion', label: t('softwareVersions.version'), isSortable: true },
    {
      id: 'deviceModel',
      label: t('softwareVersions.deviceModel'),
      isSortable: false,
    },
  ];

  function onHoverAction(): void {
    setIsHoveredAction(true);
  }
  function onStopHoverAction(): void {
    setIsHoveredAction(false);
  }
  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  return (
    <>
      <StyledTableContainer>
        <Table data-testid="softwareVersionsTable">
          <StihlTableHeader<SoftwareVersionsColumns>
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            headCells={headCells}
            shouldHaveCheckbox={false}
            shouldHaveLastColumnEmpty
          />
          <TableBody>
            {softwareVersionsMetadata
              .sort(getComparator(order, orderBy))
              .slice(firstToDisplay, lastToDisplay)
              .map((versionMetadata: SoftwareVersionMetadata) => {
                return (
                  <StihlTableRow
                    key={versionMetadata.packageId}
                    id={`${versionMetadata.packageId}`}
                    shouldHaveCheckbox={false}
                    isHoveredAction={isHoveredAction}
                  >
                    <StyledTableCell
                      isHidden={false}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {versionMetadata.swVersion}
                    </StyledTableCell>
                    <StyledTableCell isHidden={false} align="left">
                      {deviceModel}
                    </StyledTableCell>
                    <StyledTableCell isHidden={false}>
                      <IconButton
                        data-testid="clickDetailsCardButton"
                        onMouseOver={onHoverAction}
                        onMouseOut={onStopHoverAction}
                        onClick={() =>
                          setCurrentOpenMetadataDetails((previousState) =>
                            previousState?.packageId ===
                            versionMetadata.packageId
                              ? undefined
                              : versionMetadata,
                          )
                        }
                      >
                        {currentOpenMetadataDetails?.packageId ===
                        versionMetadata.packageId ? (
                          <StyledArrowIconLeft />
                        ) : (
                          <StihlTooltip
                            title={t('softwareVersions.openDetails')}
                            placement="left"
                          >
                            <StyledArrowIconRight />
                          </StihlTooltip>
                        )}
                      </IconButton>
                    </StyledTableCell>
                  </StihlTableRow>
                );
              })}
          </TableBody>
        </Table>
        {!!currentOpenMetadataDetails && (
          <DeviceManagementSoftwareVersionsDetails
            softwareVersionMetadata={currentOpenMetadataDetails}
            handleCloseDetails={() => setCurrentOpenMetadataDetails(undefined)}
          />
        )}
      </StyledTableContainer>
      {softwareVersionsMetadata.length > 0 && (
        <Pagination
          numberOfResults={softwareVersionsMetadata.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('softwareVersions.versions')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      )}
    </>
  );
};
