import type { InferType } from 'yup';
import { object, string, number, array, mixed, boolean } from 'yup';

const appAuthenticationProfileIdTokenClaimsSchema = object().shape({
  sub: string().optional(),
  iss: string().optional(),
  aud: string().optional(),
  exp: number().optional(),
  iat: number().optional(),
  auth_time: number().optional(),
  nonce: number().optional(),
  at_hash: string().optional(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c_hash: string().optional(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  s_hash: string().optional(),
  acr: string().optional(),
  amr: array().of(string()).optional(),
  azp: string().optional(),
  sid: string().optional(),
});

const appAuthenticationUserSchema = object().shape({
  id_token: string().required(),
  session_state: string().nullable().optional(),
  access_token: string().required(),
  refresh_token: string().optional(),
  token_type: string().required(),
  scope: string().required(),
  // this is not an array
  // eslint-disable-next-line unicorn/prefer-spread
  profile: appAuthenticationProfileIdTokenClaimsSchema.concat(
    object()
      .shape({})
      .required()
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .test('default', 'non-nullable', (value) => value !== null),
  ),
  expires_at: number().required(),
  state: mixed().optional(),
  expires_in: number().optional(),
  expired: boolean().required(),
  scopes: array().of(string().required()).required(),
});

export type AppAuthenticationUserTransfer = InferType<
  typeof appAuthenticationUserSchema
>;

const appAuthenticationUserOrNullSchema =
  appAuthenticationUserSchema.nullable();

export const asAppAuthenticationUser =
  appAuthenticationUserSchema.validateSync.bind(appAuthenticationUserSchema);

export const asAppAuthenticationUserOrNull =
  appAuthenticationUserOrNullSchema.validateSync.bind(
    appAuthenticationUserOrNullSchema,
  );
