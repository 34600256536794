import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StihlBackground } from '../../../base/stihl-material-ui/components/stihl-background/stihl-background';
import StihlButtonOutlined from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlCheckbox from '../../../base/stihl-material-ui/components/stihl-checkbox/stihl-checkbox';
import StihlIconGlobe from '../../../base/stihl-material-ui/icons/stihl-icon-globe';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAppAuthenticationSessionContext } from '../../app-authentication/service/app-authentication-session/app-authentication-session-provider';
import { useAppAuthenticationSessionAuthenticated } from '../../app-authentication/service/app-authentication-session/app-authentication-session-service';
import {
  currentTermsVersion,
  generateFileName,
} from '../../legal-documents/service/documents-utils';
import {
  useAcceptTermsAndConditions,
  useTermsAndConditions,
} from '../../legal-documents/service/terms-and-conditions-service/terms-and-conditions-service';
import { DocumentCountryFilter } from '../../legal-documents/ui/document-country-filter';

const TermsAndConditionsContainer = styled.div`
  max-inline-size: 700px;
  margin: auto;
  padding: 60px;
  background-color: ${stihlColor.white};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block-start: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TermsAndConditionsModal = (): JSX.Element => {
  const { t } = useTranslation();

  const session = useAppAuthenticationSessionAuthenticated();
  const [isChecked, setIsChecked] = useState(false);
  const [termsCountry, setTermsCountry] = useState<string>('Germany (DE)');

  const { appAuthenticationClient } = useAppAuthenticationSessionContext();

  const hasAcceptedTermsAndConditions = useTermsAndConditions(session.userId);
  const acceptTermsAndConditions = useAcceptTermsAndConditions();

  const fileName = `termsofuse${currentTermsVersion}${termsCountry
    .split(' (')[1]
    .slice(0, 2)
    .toLocaleLowerCase()}${termsCountry.split(' (')[0].replace(' ', '')}`;

  const handleAcceptChange = useCallback(() => {
    setIsChecked((value) => !value);
  }, [setIsChecked]);

  const handleLogout = useCallback(() => {
    void appAuthenticationClient?.logout();
  }, [appAuthenticationClient]);

  function handleContinue(): void {
    const version = generateFileName('termsofuse', termsCountry);

    window.open(`./terms/${version}.pdf`);

    void acceptTermsAndConditions.mutate({
      userId: session.userId,
      version,
    });
  }

  return (
    <StihlBackground>
      <TermsAndConditionsContainer data-testid="termsAndConditions">
        {!hasAcceptedTermsAndConditions.isFetching && (
          <Typography variant="h3">{t('termsAndConditions.title')}</Typography>
        )}
        <br />
        {hasAcceptedTermsAndConditions.isFetching ? (
          <>
            <Typography variant="h3">
              {t('termsAndConditions.loadingTitle')}
            </Typography>
            <br />
            <LoadingWrapper>
              <CircularProgress />{' '}
            </LoadingWrapper>
          </>
        ) : (
          <>
            <Typography>{t('termsAndConditions.introText')}</Typography>
            <br />
            <FlexContainer>
              <StihlIconGlobe color="black" />
              <DocumentCountryFilter
                documentCountry={termsCountry}
                setDocumentCountry={setTermsCountry}
              />
            </FlexContainer>
            <Typography sx={{ margin: '1rem 0' }}>
              <StihlCheckbox
                onClick={handleAcceptChange}
                data-testid="acceptCheckBox"
              />

              {/* for interpolation with links the trans component is needed see https://react.i18next.com/latest/trans-component#alternative-usage-components-array */}
              <Trans
                i18nKey="termsAndConditions.acceptText"
                values={{ fileName }}
                components={[
                  <Link
                    key="0"
                    href={`./terms/${fileName}.pdf`}
                    target="_blank"
                    style={{
                      color: stihlColor.blue,
                      // stihl blue with transparency
                      textDecorationColor: 'rgba(36, 154, 190, 0.4)',
                    }}
                  />,
                ]}
              />
            </Typography>
            <Typography variant="overline">
              {t('termsAndConditions.notice')}
            </Typography>
            <ButtonContainer>
              <StihlButtonOutlined
                onClick={handleLogout}
                data-testid="logoutButton"
              >
                {t('termsAndConditions.logoutButton')}
              </StihlButtonOutlined>

              <StihlButtonSecondaryLoading
                loading={acceptTermsAndConditions.isPending}
                disabled={!isChecked}
                onClick={handleContinue}
                data-testid="continueButton"
              >
                {t('termsAndConditions.continueButton')}
              </StihlButtonSecondaryLoading>
            </ButtonContainer>
          </>
        )}
      </TermsAndConditionsContainer>
    </StihlBackground>
  );
};

export default TermsAndConditionsModal;
