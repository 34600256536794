import type { ReactNode } from 'react';

/* eslint-disable no-secrets/no-secrets */
export const CardFieldType = {
  Text: 'Text',
  Number: 'Number',
  DeviceCurrentArea: 'DeviceCurrentArea',
  DeviceOverallState: 'DeviceOverallState',
  DeviceJobState: 'DeviceJobState',
  DeviceError: 'DeviceError',
  DeviceServiceRequired: 'DeviceServiceRequired',
  Customer: 'Customer',
  DeviceGpsPositionValid: 'DeviceGpsPositionValid',
  DeviceGpsPositionUpdated: 'DeviceGpsPositionUpdated',
  DeviceWifiConnection: 'DeviceWifiConnection',
  DeviceCellularConnection: 'DeviceCellularConnection',
  DeviceCellularNetworkInformation: 'DeviceCellularNetworkInformation',
  DeviceEthernetConnection: 'DeviceEthernetConnection',
  Boolean: 'Boolean',
  Subtitle: 'Subtitle',
  DeviceDemoMode: 'DeviceDemoMode',
  Date: 'Date',
  Toggle: 'Toggle',
  Percentage: 'Percentage',
  DeviceTotalWorkingTimeOfCuttingMotor: 'DeviceTotalWorkingTimeOfCuttingMotor',
  DeviceTotalWorkingSeconds: 'DeviceTotalWorkingSeconds',
  DeviceTotalDrivenDistance: 'DeviceTotalDrivenDistance',
  DeviceTotalStartedMowingJobs: 'DeviceTotalStartedMowingJobs',
  DeviceTotalNumberOfBumps: 'DeviceTotalNumberOfBumps',
  DeviceTotalWorkingTimeOfCurrentCuttingKnifes:
    'DeviceTotalWorkingTimeOfCurrentCuttingKnifes',
  DeviceWarrantyData: 'DeviceWarrantyData',
  Command: 'Command',
  DeviceWifiAvailability: 'DeviceWifiAvailability',
  Range: 'Range',
  DeviceStatusError: 'DeviceStatusError',
  SoftwarePackageVersionInitial: 'SoftwarePackageVersionInitial',
  DeviceEmbeddedSoftwareHmi: 'DeviceEmbeddedSoftwareHmi',
  DeviceEmbeddedSoftwarePowerPcb: 'DeviceEmbeddedSoftwarePowerPcb',
  DeviceEmbeddedSoftwareDocking: 'DeviceEmbeddedSoftwareDocking',
  DeviceEmbeddedSoftwareSignalPcb: 'DeviceEmbeddedSoftwareSignalPcb',
  TeamMower: 'TeamMower',
  AccessPinCode: 'AccessPinCode',
  PartOfOrganization: 'PartOfOrganization',
  OrganizationClaimInitiator: 'OrganizationClaimInitiator',
  OrganizationEmail: 'OrganizationEmail',
  FleetManaged: 'FleetManaged',
  GnssWorkingAreaMap: 'GnssWorkingAreaMap',
} as const;

export type CardFieldType = (typeof CardFieldType)[keyof typeof CardFieldType];

export const CardFieldIcon = {
  Heart: 'Heart',
  StateOfCharge: 'StateOfCharge',
  Plug: 'Plug',
  Blades: 'Blades',
  Imow: 'Imow',
  MowingState: 'MowingState',
  Distance: 'Distance',
  Key: 'Key',
  Bluetooth: 'Bluetooth',
} as const;

export type CardFieldIcon = (typeof CardFieldIcon)[keyof typeof CardFieldIcon];

type CardFieldGeneric<TType extends CardFieldType, TValue> = {
  type: TType;
  value: TValue;
  title: string;
};

type CardFieldGenericWithIcon<
  TType extends CardFieldType,
  TValue,
> = CardFieldGeneric<TType, TValue> & {
  icon?: CardFieldIcon;
};

type CardFieldText = CardFieldGeneric<
  | 'DeviceStatusError'
  | 'DeviceEmbeddedSoftwareHmi'
  | 'DeviceEmbeddedSoftwarePowerPcb'
  | 'DeviceEmbeddedSoftwareDocking'
  | 'DeviceEmbeddedSoftwareSignalPcb'
  | 'SoftwarePackageVersionInitial',
  string
>;

type CardFieldNumberWithIcon = CardFieldGenericWithIcon<
  | 'Percentage'
  | 'DeviceTotalWorkingSeconds'
  | 'DeviceTotalWorkingTimeOfCurrentCuttingKnifes'
  | 'DeviceTotalDrivenDistance'
  | 'DeviceTotalStartedMowingJobs'
  | 'DeviceTotalWorkingTimeOfCuttingMotor',
  number
>;

type CardFieldBoolean = CardFieldGeneric<
  | 'DeviceGpsPositionValid'
  | 'DeviceWifiConnection'
  | 'DeviceCellularConnection'
  | 'DeviceEthernetConnection'
  | 'DeviceDemoMode'
  | 'TeamMower',
  boolean
>;

type CardFieldBooleanWithIcon = CardFieldGenericWithIcon<
  'Toggle' | 'AccessPinCode' | 'PartOfOrganization' | 'FleetManaged',
  boolean
>;

type CardFieldTextLike = CardFieldGenericWithIcon<
  'Text' | 'DeviceCurrentArea' | 'Boolean' | 'Number',
  string | number | boolean
>;

export type CardFieldDateValue = {
  date: string;
  isDateOnly: boolean;
};

type CardFieldDate = CardFieldGeneric<'Date', CardFieldDateValue>;

export const Priority = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export type Priority = (typeof Priority)[keyof typeof Priority];

export type ErrorValue = {
  code: string;
  requiredAction: string;
  activeSince: string;
  priority?: Priority;
};

type CardFieldDeviceError = CardFieldGeneric<'DeviceError', ErrorValue[]>;

export type ServiceValue = {
  code: string;
  activeSince: string;
};

type CardFieldDeviceServiceRequired = CardFieldGenericWithIcon<
  'DeviceServiceRequired',
  ServiceValue[]
>;

export type JobStateValue = {
  startTime: string;
  endTime: string;
  mowingZoneId: string;
  isNonCyclic: boolean;
};

export type CardFieldDeviceJobStateValue = {
  state: string;
  value?: JobStateValue;
};

type CardFieldDeviceJobState = CardFieldGeneric<
  'DeviceJobState',
  CardFieldDeviceJobStateValue
>;

export type CardFieldDeviceOverallStateValue = {
  state: string;
  trigger: string;
};

type CardFieldDeviceOverallState = CardFieldGeneric<
  'DeviceOverallState',
  CardFieldDeviceOverallStateValue
>;

type CardFieldDeviceGpsPositionUpdated = CardFieldGeneric<
  'DeviceGpsPositionUpdated',
  number | false
>;

type CardFieldSubtitle = CardFieldGeneric<'Subtitle', '' | boolean>;

export type CardFieldWarrantyValue = {
  warrantyStart: string;
  warrantyEnd: string;
  warrantyExtensionNumber: number;
};

type CardFieldDeviceWarrantyData = CardFieldGeneric<
  'DeviceWarrantyData',
  CardFieldWarrantyValue
>;

export type CardFieldDeviceWifiAvailabilityValue = {
  ssid: string;
  signalStrength: number;
};

type CardFieldDeviceWifiAvailability = CardFieldGeneric<
  'DeviceWifiAvailability',
  CardFieldDeviceWifiAvailabilityValue[]
>;

export type CardFieldRangeOption = {
  value: number;
  label: string;
};

export type CardFieldRangeValue = {
  currentValue: number;
  rangeOptions: CardFieldRangeOption[];
  minIcon?: ReactNode;
  maxIcon?: ReactNode;
};

type CardFieldRange = CardFieldGeneric<'Range', CardFieldRangeValue>;

type CardFieldCustomerErrorValue = {
  hasError: true;
};

type CardFieldCustomerNotConnectedValue = {
  hasError?: false;
  hasCustomersAssigned: false;
  isFleetManaged: boolean;
  deviceSerialNumber: string;
};

export type Deputy = {
  deputyId: string;
  subscriptionId: string;
  deputyEmail: string;
  deputyFirstName: string;
  deputyLastName: string;
  deputySalutation: string;
  hasDeputyMatchingPreferredDealer: boolean;
  isDeputyAccountValid: boolean;
};

export type CardFieldCustomerValidValue = {
  hasError?: false;
  hasCustomersAssigned: true;
  customer: {
    isAccountValid: boolean | undefined;
    ownerEmail: string;
    ownerFirstName: string;
    ownerLastName: string;
    ownerSalutation: string;
    deputies: Deputy[];
    customerId: string;
  };
  hasMatchingPreferredDealer: boolean;
  hasCustomerAccess: boolean;
};

export type CardFieldCustomerValue =
  | CardFieldCustomerErrorValue
  | CardFieldCustomerNotConnectedValue
  | CardFieldCustomerValidValue;

type CardFieldCustomer = CardFieldGeneric<'Customer', CardFieldCustomerValue>;

export enum CardFieldCommandValue {
  Reboot = 'forceReboot',
  Reset = 'forceFactoryReset',
  ResetConfiguration = 'resetConfigurationData',
}

export type CardFieldCommand = CardFieldGeneric<
  'Command',
  CardFieldCommandValue
>;

export type CardFieldDeviceCellularNetworkInformationValue = {
  value:
    | {
        providerId: string;
        connectionType: string;
        currentSignalStrength: number;
      }
    | Record<string, never>;
  sent: number;
  lastUpdated: number;
};

export type CardFieldDeviceCellularNetworkInformation = CardFieldGeneric<
  'DeviceCellularNetworkInformation',
  CardFieldDeviceCellularNetworkInformationValue
>;

export type CardFieldGnssWorkingAreaMap = CardFieldGeneric<
  'GnssWorkingAreaMap',
  string | null
>;

export type CardFieldOrganizationClaimInitiator = CardFieldGeneric<
  'OrganizationClaimInitiator',
  null | 'Customer' | 'FleetManager'
>;

export type CardFieldOrganizationEmail = CardFieldGeneric<
  'OrganizationEmail',
  string
>;

export type CardField =
  | CardFieldText
  | CardFieldNumberWithIcon
  | CardFieldBoolean
  | CardFieldBooleanWithIcon
  | CardFieldTextLike
  | CardFieldDate
  | CardFieldDeviceError
  | CardFieldDeviceServiceRequired
  | CardFieldDeviceJobState
  | CardFieldDeviceOverallState
  | CardFieldDeviceGpsPositionUpdated
  | CardFieldSubtitle
  | CardFieldDeviceWarrantyData
  | CardFieldDeviceWifiAvailability
  | CardFieldRange
  | CardFieldCustomer
  | CardFieldGnssWorkingAreaMap
  | CardFieldOrganizationClaimInitiator
  | CardFieldOrganizationEmail;

/* eslint-enable no-secrets/no-secrets */
