import type { AxiosError } from 'axios';
import instance from '../../../../../../app-shell/service/axios';
import type { DiagnosisSessionData } from '../../../../../model';

export async function getDiagnosisDetails(
  serialNumber: string,
): Promise<DiagnosisSessionData[]> {
  return instance
    .get<DiagnosisSessionData[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/diagnosis-data/${serialNumber}`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}
