import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import type { DeviceResultNotProvisioned } from '../../../model/card.model';
import {
  BodyTableCellHoverable,
  DeviceSearchResultDeviceCustomerRole,
} from './device-search-result-device-entry-shared';

type DeviceSearchResultDeviceEntryNotProvisionedProps = {
  device: DeviceResultNotProvisioned;
  customerRole?: 'OWNER' | 'DEPUTY' | null;
};

const DeviceSearchResultDeviceEntryNotProvisioned: FC<
  DeviceSearchResultDeviceEntryNotProvisionedProps
> = ({ device, customerRole }) => {
  const { t } = useTranslation();

  return (
    <>
      {customerRole !== undefined && (
        <BodyTableCellHoverable>
          <DeviceSearchResultDeviceCustomerRole role={customerRole} />
        </BodyTableCellHoverable>
      )}
      <BodyTableCellHoverable>{device.serialNumber}</BodyTableCellHoverable>
      <BodyTableCellHoverable colSpan={4} />
      <BodyTableCellHoverable>
        <StihlTooltip title={t('searchResultTable.deviceNotProvisioned')}>
          <StihlIconInfo />
        </StihlTooltip>
      </BodyTableCellHoverable>
    </>
  );
};
export default DeviceSearchResultDeviceEntryNotProvisioned;
