import type { NonNullish } from '../../../../base/extras-typescript/nothing-types';
import type { User } from '../../model/user';
import type { AppAuthenticationError } from './app-authentification-error';

export type AppAuthenticationClientState =
  | AppAuthenticationClientStateUninitialized
  | AppAuthenticationClientStateLoading
  | AppAuthenticationClientStateLoggedIn
  | AppAuthenticationClientStateRefreshingToken
  | AppAuthenticationClientStateLoggedOut
  | AppAuthenticationClientStateLoggingOut
  | AppAuthenticationClientStateLoggingIn;

type AppAuthenticationClientStateUninitialized = {
  state: 'uninitialized';
};

export function isAppAuthenticationClientStateUninitialized(
  value: unknown,
): value is AppAuthenticationClientStateUninitialized {
  return (
    typeof value === 'object' &&
    value != null &&
    'state' in value &&
    (value as { state: unknown }).state === 'uninitialized'
  );
}

type AppAuthenticationClientStateLoading = {
  state: 'loading';
};

type AppAuthenticationClientStateLoggedIn = {
  state: 'loggedIn';
  user: User;
  refreshFailedReason?: AppAuthenticationError;
};

export function isAppAuthenticationClientStateLoggedIn(
  value: unknown,
): value is AppAuthenticationClientStateLoggedIn {
  return (
    typeof value === 'object' &&
    value != null &&
    'state' in value &&
    (value as { state: unknown }).state === 'loggedIn' &&
    'user' in value &&
    (value as { user: unknown }).user != null
  );
}

type AppAuthenticationClientStateRefreshingToken = {
  state: 'refreshingToken';
  // `User` that is being refreshed.
  // this `User` might be expired (but is not necessarily so,
  // since a refresh might take place when `User` still valid
  // but about to be expired).
  // might be `undefined` on the initial refresh
  // (i.e. page is loaded, stored user is expired
  //  -> no user data, but refreshing in progress)
  userStale: User | undefined;
};

function isAppAuthenticationClientStateRefreshingToken(
  value: unknown,
): value is AppAuthenticationClientStateRefreshingToken {
  return (
    typeof value === 'object' &&
    value != null &&
    'state' in value &&
    (value as { state: unknown }).state === 'refreshingToken'
  );
}

export function isAppAuthenticationClientStateRefreshingTokenInitially(
  value: unknown,
): value is AppAuthenticationClientStateRefreshingToken & {
  userStale: undefined;
} {
  return (
    isAppAuthenticationClientStateRefreshingToken(value) &&
    value.userStale == null
  );
}

export function isAppAuthenticationClientStateRefreshingTokenNotInitially(
  value: unknown,
): value is AppAuthenticationClientStateRefreshingToken & {
  userStale: NonNullish;
} {
  return (
    isAppAuthenticationClientStateRefreshingToken(value) &&
    value.userStale != null
  );
}

type AppAuthenticationClientStateLoggedOut = {
  state: 'loggedOut';
  reason?: AppAuthenticationError;
};

export function isAppAuthenticationClientStateLoggedOut(
  value: unknown,
): value is AppAuthenticationClientStateLoggedOut {
  return (
    typeof value === 'object' &&
    value != null &&
    'state' in value &&
    (value as { state: unknown }).state === 'loggedOut'
  );
}

type AppAuthenticationClientStateLoggingOut = {
  state: 'loggingOut';
  user: User;
};

function isAppAuthenticationClientStateLoggingOut(
  value: unknown,
): value is AppAuthenticationClientStateLoggingOut {
  return (
    typeof value === 'object' &&
    value != null &&
    'state' in value &&
    (value as { state: unknown }).state === 'loggingOut' &&
    'user' in value &&
    (value as { user: unknown }).user != null
  );
}

type AppAuthenticationClientStateLoggingIn = {
  state: 'loggingIn';
};

export function isAppAuthenticationClientStateSettled(
  value: unknown,
): value is
  | AppAuthenticationClientStateUninitialized
  | AppAuthenticationClientStateLoggedOut
  | AppAuthenticationClientStateLoggedIn {
  return (
    isAppAuthenticationClientStateUninitialized(value) ||
    isAppAuthenticationClientStateLoggedOut(value) ||
    isAppAuthenticationClientStateLoggedIn(value)
  );
}

export function isAppAuthenticationClientStateWithUserStale(
  value: unknown,
): value is
  | AppAuthenticationClientStateRefreshingToken
  | AppAuthenticationClientStateLoggingOut {
  return (
    isAppAuthenticationClientStateRefreshingToken(value) ||
    isAppAuthenticationClientStateLoggingOut(value)
  );
}

export function isAppAuthenticationClientStateWithUser(
  value: unknown,
): value is Extract<
  AppAuthenticationClientState,
  { user: User } | { userStale: User }
> {
  return (
    isAppAuthenticationClientStateLoggedIn(value) ||
    isAppAuthenticationClientStateRefreshingToken(value) ||
    isAppAuthenticationClientStateLoggingOut(value)
  );
}

export function getUserMaybeFromAppAuthenticationClientState(
  state: AppAuthenticationClientState,
): User | undefined {
  if (isAppAuthenticationClientStateRefreshingToken(state)) {
    return state.userStale;
  }

  if (
    isAppAuthenticationClientStateLoggedIn(state) ||
    isAppAuthenticationClientStateLoggingOut(state)
  ) {
    return state.user;
  }
}
