import type { ToUnknownRecord } from '../extras-typescript/object-modifier';
import type { ErrorBaseOptions } from './error-base';
import { initErrorBase } from './error-base';

export class AbortError<
  TCode extends string = string,
  TCause extends Error = Error,
> extends Error {
  override name = 'AbortError';

  code?: TCode;

  constructor(message: string, options?: ErrorBaseOptions<TCode, TCause>) {
    super(message);
    initErrorBase(this, options, AbortError);
  }
}

export function isAbortError(value: unknown): value is AbortError {
  const maybe = value as ToUnknownRecord<AbortError>;
  // check structural type instead of `instanceof` to allow plain objects
  // (e.g. created from a deserialized error) and errors from other JS contexts
  return (
    typeof value === 'object' &&
    value != null &&
    maybe.name === 'AbortError' &&
    typeof maybe.message === 'string' &&
    (maybe.stack == null || typeof maybe.stack === 'string') &&
    (maybe.code == null || typeof maybe.code === 'string')
  );
}
