import type { UseQueryResult } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import type { Page } from '../../model/page.model';

export function useDeviceDetailsAlert(deviceData: UseQueryResult<Page>): void {
  const [_alertStore, setAlert] = useAlertStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAxiosError(deviceData.error)) {
      if (deviceData.data != null) {
        setAlert({
          isOpen: true,
          message: 'deviceDetails.deviceStatusUpdateError',
          severity: 'error',
        });
      } else if (deviceData.error.response?.status === 500) {
        setAlert({
          isOpen: true,
          message: 'searchErrors.deviceSearchError',
          severity: 'error',
        });
      } else if (deviceData.error.message) {
        setAlert({
          isOpen: true,
          message: deviceData.error.message,
          severity: 'error',
        });
      }
    }
  }, [deviceData, deviceData.data, deviceData.error, setAlert, t]);
}
