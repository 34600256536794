import type { AxiosError } from 'axios';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import instance from '../../../app-shell/service/axios';

export async function getHelpTabs(
  signal?: AbortSignal,
): Promise<TabsResponse[]> {
  return instance
    .get<TabsResponse[]>(
      `${process.env.REACT_APP_ISP_API_MAIN_BASE_URL}/main/tabs`,
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(error.message);
    });
}
