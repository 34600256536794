import type { NonUndefined } from '../../extras-typescript/nothing-types';
import type { RequiredKey } from '../../extras-typescript/object-modifier';
import type { AbortSignalWithCauseMaybe } from '../abort-controller-with-cause';

type ValueOrError<TValue, TError> = [value: TValue | undefined, error?: TError];

/**
 * Handles a value or an error.
 *
 * 1. Value: only first `value` argument is passed
 * 2. Error: second `error !== undefined` argument is passed.
 *    First `value` argument has to be `undefined` (ignored otherwise).
 */
export type HandleValueOrError<TValue, TError> = (
  ...valueOrError: ValueOrError<TValue, TError>
) => void;

/**
 * Determines if a value or error is [handled ]{@link HandleValueOrError}.
 *
 * Uses `error !== undefined` to determine error vs. value case:
 * an `error` can never be `undefined` but a value could technically be
 * `undefined`.
 *
 * @param value
 * @param error
 */
export function isErrorHandling<TValue, TError>(
  value: TValue | undefined,
  error?: TError,
): error is NonUndefined<TError> {
  return error !== undefined;
}

type SubscriptionHandlers<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = {
  /**
   * Called when the subscription source emits a value.
   *
   * ## Error conversion ability
   *
   * The handler may throw to convert the emitted value to an error
   * and [`onError()`]{@link SubscriptionHandlers#onError} is called afterwards.
   * [`isDone`]{@link IsDone} is called for the error to determine if
   * that error ends the subscription – if it does
   * [`onFinally`]{@link SubscriptionHandlers#onFinally} is called.
   *
   * @see HandleValueOrError
   */
  onValue?: (value: TValue, isDone: boolean) => void;
  /**
   * Called when the subscription source emits an error.
   *
   * Also called if [`onValue()`]{@link SubscriptionHandlers#onValue} throws.
   *
   * If handler throws, it is a fatal error that will be thrown and
   * [ends the subscription]{@link SubscriptionEndReason}
   * and [`onFinally`]{@link SubscriptionHandlers#onFinally} is called.
   *
   * @see HandleValueOrError
   */
  onError?: (error: TError, isDone: boolean) => void;
  /**
   * Called when the subscription is aborted by the passed {@link AbortSignal}.
   *
   * Not called if [done]{@link IsDone}.
   *
   * If handler throws, it is a fatal error that will be thrown and
   * [ends the subscription]{@link SubscriptionEndReason}
   * and [`onFinally`]{@link SubscriptionHandlers#onFinally} is called.
   */
  onAbort?: (signal: TAbortSignal) => void;
  /**
   * Called when the subscription {@link IsDone} after the source emitted
   * the last value or error.
   *
   * Called after the last {@link onValue} / {@link onError}.
   * Not called if [aborted]{@link onAbort}.
   *
   * If handler throws, it is a fatal error that will be thrown and
   * [ends the subscription]{@link SubscriptionEndReason}
   * and [`onFinally`]{@link SubscriptionHandlers#onFinally} is called.
   */
  onDone?: () => void;
  /**
   * Called when the subscription has [ended]{@link SubscriptionEndReason}.
   *
   * Called after {@link onAbort}, {@link onDone}.
   *
   * If handler throws, it is a fatal error that will be thrown.
   */
  onFinally?: () => void;
};

/**
 * Reasons for a subscription to end.
 *
 * If a subscription ends its listeners (for event source, {@link AbortSignal})
 * are cleaned up and {@link SubscriptionHandlers#onFinally} is called to
 * enable additional cleanup if required.
 *
 * - `aborted`: by [`signal`]{@link AbortSignal}.
 *    {@link SubscriptionHandlers#onAbort}, then {@link SubscriptionHandlers#onFinally}
 *    will be called.
 * - `done`: {@link IsDone} determined that last value/error has been emitted.
 *    {@link SubscriptionHandlers#onDone}, then {@link SubscriptionHandlers#onFinally}
 *    will be called.
 * - `fatalError`: {@link SubscriptionHandlers#onError},
 *    {@link SubscriptionHandlers#onDone} or {@link HandleOptions#onAbort} did `throw`.
 *    {@link SubscriptionHandlers#onFinally} will be called and
 *    error will be thrown.
 * - `manual` {@link EndSubscription} was called by user.
 *   {@link SubscriptionHandlers#onFinally} will be called.
 */
type SubscriptionEndReason = 'aborted' | 'done' | 'fatalError' | 'manual';

/**
 * Determines if a subscription is done and should [end]{@link SubscriptionEndReason}
 * to not consume any value(s) / error(s) and perform cleanup to release listeners.
 *
 * 1. `false` = the subscription is never done and listens forever.
 * 2. `true` = the subscription is done after the first `value` or `error`
 * 3. predicate = the subscription is done when the predicate returns `true`
 *
 */
export type IsDone<TValue, TError> = boolean | IsDonePredicate<TValue, TError>;
export type IsDoneValue<TValue> = boolean | ((value: TValue) => boolean);

/**
 * Determines if a subscription is done by inspecting the emitted value or error.
 *
 * If inspecting a value `isDone` has the same
 * [error conversion ability as `onValue`]{@link SubscriptionHandlers#onValue}.
 * If inspecting an error `isDone` has the same
 * [error semantics as `onError`]{@link SubscriptionHandlers#onError} where
 * and error is fatal.
 *
 * @see HandleValueOrError
 */
type IsDonePredicate<TValue, TError> = (
  ...valueOrError: ValueOrError<TValue, TError>
) => boolean;

export function evaluateIsDone<TValue, TError>(
  isDone: IsDone<TValue, TError>,
  value: TValue | undefined,
  error?: TError,
): boolean;
export function evaluateIsDone<TValue>(
  isDone: IsDoneValue<TValue>,
  value: TValue,
): boolean;
export function evaluateIsDone<TValue, TError>(
  isDone: IsDone<TValue, TError>,
  value: TValue | undefined,
  error?: TError,
): boolean {
  return typeof isDone === 'boolean' ? isDone : isDone(value, error);
}

/**
 * Considers a subscriptions done and [ending it]{@link SubscriptionEndReason}
 * on the first occurrence of an error.
 * Otherwise stays subscribed forever to consume values indefinitely.
 *
 * @param _value
 * @param error
 */
export function isDoneOnError<TValue, TError>(
  _value: TValue,
  error?: TError,
): boolean {
  return error !== undefined;
}

type HandleOptions<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = {
  signal: TAbortSignal;
  isDone: IsDone<TValue, TError>;
} & SubscriptionHandlers<TValue, TError, TAbortSignal>;

/**
 * Handles an abort that ends the subscription.
 *
 * @param signal that aborted the subscription.
 * @param onAbort Called if defined.
 * @param onFinally Called if defined in case `onAbort` throws.
 * @param cleanup Cleanup to unsubscribe and remove listeners. Called before
 * delegating to callbacks to ensure cleanup is performed.
 * @return endReason {@link SubscriptionEndReason#aborted} or `undefined` if not aborted.
 *
 * @throws unknown If any passed callbacks throws.
 */
function handleAborted<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(
  {
    signal,
    onAbort,
    onFinally,
  }: Pick<
    HandleOptions<TValue, TError, TAbortSignal>,
    'signal' | 'onAbort' | 'onFinally'
  >,
  cleanup?: () => void,
): SubscriptionEndReason | undefined {
  if (!signal.aborted) {
    return;
  }

  // cleanup before handoff to external code
  cleanup?.();

  try {
    onAbort?.(signal);
  } finally {
    onFinally?.();
  }

  return 'aborted';
}

/**
 * Handles a value or error emitted by the subscribed event source while
 * checking if [aborted]{@link AbortSignal#aborted}, [done]{@link IsDone}.
 * Exerts error handling and invokes suitable {@link SubscriptionHandlers}.
 *
 * @param options
 * @param options.signal If {@link AbortSignal#aborted}
 * [ends the subscription]{@link SubscriptionEndReason} due
 * [abort]{@link handleAborted}.
 * @param options.isDone [Determines]{@link IsDone} if this is the last
 * value/error that [ends the subscription]{@link SubscriptionEndReason}.
 * @param options.onValue Called if a [value is handled]{@link SubscriptionHandlers#onValue}.
 * @param options.onError Called if an [error is handled]{@link SubscriptionHandlers#onError}.
 * @param options.onFinally Called to perform custom resource cleanup
 * when the subscription [ended]{@link SubscriptionEndReason}.
 * @param cleanup Cleanup called if subscription ends to remove listeners.
 * Called before delegating to {@link SubscriptionHandlers} to ensure cleanup is
 * performed early and in any case.
 * @param value
 * @param error
 * @param endReason Preserves the {@link SubscriptionEndReason} from a previous
 * recursive call.
 * @return endReason {@link SubscriptionEndReason} or `undefined` if not ended.
 *
 * @see HandleValueOrError
 */
// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
function handleValueOrError<
  TValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(
  options: Omit<HandleOptions<TValue, TError, TAbortSignal>, 'signal'> &
    Partial<Pick<HandleOptions<TValue, TError, TAbortSignal>, 'signal'>>,
  cleanup: (() => void) | undefined,
  value: TValue | undefined,
  error?: TError,
  endReason?: SubscriptionEndReason,
): SubscriptionEndReason | undefined {
  if (options.signal?.aborted) {
    endReason = 'aborted';
    handleAborted(options as RequiredKey<typeof options, 'signal'>, cleanup);
    return endReason;
  }

  const isErrorHandler = isErrorHandling(value, error);
  let fatalError: unknown;

  // check if done before invoking callbacks to so cleanup can be as early as
  // possible and safely without possible disruption by user code in callbacks
  if (endReason == null) {
    try {
      endReason = evaluateIsDone(options.isDone, value, error)
        ? 'done'
        : undefined;
    } catch (error_) {
      /*
      - if handling `value`: `isDone` has same semantics as `onValue()`
        and can convert an value to an error.
      - if handling `error`: `isDone` has same semantics as `onError()`
        and error is fatal.
       */
      if (isErrorHandler) {
        endReason = 'fatalError';
        fatalError = error_;
      } else {
        // call again to re-check if aborted + check `isDone` + invoke `onError`
        return handleValueOrError(
          options,
          cleanup,
          undefined,
          error_ as TError,
          endReason,
        );
      }
    }
  }
  if (endReason === 'done') {
    cleanup?.();
  }

  if (isErrorHandler) {
    // `onError` error is not called because it did throw it will probably throw again
    try {
      options.onError?.(error, endReason === 'done');
    } catch (error_) {
      endReason = 'fatalError';
      fatalError = error_;
    }
  } else {
    try {
      // cast: if this is really `undefined` it is passed to us by user code hence
      // should be handled by user-code
      options.onValue?.(value as TValue, endReason === 'done');
    } catch (error_) {
      return handleValueOrError(
        options,
        cleanup,
        undefined,
        error_ as TError,
        endReason,
      );
    }
  }

  if (endReason === 'fatalError') {
    cleanup?.();
    options.onFinally?.();
    throw fatalError;
  }
  if (endReason === 'done') {
    try {
      options.onDone?.();
    } finally {
      options.onFinally?.();
    }
  }

  return endReason;
}

/**
 * Subscribes to an event source that emits value(s) an/or error(s).
 */
export type SubscribeToValueOrError<TValue, TError = unknown> = (
  handle: HandleValueOrError<TValue, TError>,
) => Unsubscribe;
export type Unsubscribe = () => void;

export type SubscribeAbortableOptions<
  TValue,
  TInitialValue,
  TError,
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = {
  /**
   * Initial value passed to {@link isDone}, {@link onValue} before calling
   * {@link subscribe}.
   */
  initialValue?: TInitialValue;
  /**
   * @see SubscribeToValueOrError
   */
  subscribe: SubscribeToValueOrError<TValue, TError>;
  /**
   * If {@link AbortSignal#aborted} [ends the subscription]{@link SubscriptionEndReason}
   * due [abort]{@link handleAborted}.
   */
  signal?: TAbortSignal;
  /**
   * If {@link IsDone} [ends the subscription]{@link SubscriptionEndReason}.
   */
  isDone?: IsDone<TValue | TInitialValue, TError>;
} & SubscriptionHandlers<TValue | TInitialValue, TError, TAbortSignal>;

/**
 * [Manually ends]{@link SubscriptionEndReason} the subscription
 */
export type EndSubscription = () => void;

/**
 * Subscribes to an event source of value(s) and/or error(s) until
 * [aborted]{@link AbortSignal#aborted} or [done]{@link IsDone} and
 * provides [handlers]{@link SubscriptionHandlers} for subscription lifecycle
 * and error handling.
 *
 * Subscription is cleaned up automatically when [ended]{@link SubscriptionEndReason}.
 * If you setup additional resources, listeners etc. for the subscription
 * use {@link SubscriptionHandlers#onFinally} for custom cleanup.
 *
 * @param options See {@link SubscribeAbortableOptions} and {@link SubscriptionHandlers}.
 * @param options.subscribe Subscribes to the event source and returns an
 * unsubscribe function.
 *
 * @throws unknown Is thrown if a any callback except
 * {@link SubscriptionHandlers#onValue} or {@link SubscriptionHandlers#isDone}
 * (only if called to inspect a value, not an error)
 * throws – before {@link SubscriptionHandlers#onFinally} is called.
 * If {@link SubscriptionHandlers#onValue} or {@link SubscriptionHandlers#isDone}
 * (if inspecting a value) throw it is considered as converting a handled value
 * to an error and the error is delegated to {@link SubscriptionHandlers#onError}.
 *
 * @see handleValueOrError
 */
// eslint-disable-next-line max-lines-per-function
export function subscribeAbortable<
  TValue,
  TInitialValue = TValue,
  TError = unknown,
  TAbortSignal extends AbortSignalWithCauseMaybe = AbortSignalWithCauseMaybe,
>(
  options: SubscribeAbortableOptions<
    TValue,
    TInitialValue,
    TError,
    TAbortSignal
  >,
): EndSubscription {
  const optionsWithDefaults = (
    options.isDone == null ? { ...options, isDone: isDoneOnError } : options
  ) as RequiredKey<typeof options, 'isDone'>;
  const { signal, initialValue, onFinally } = optionsWithDefaults;

  let endReason: SubscriptionEndReason | undefined = undefined;
  let unsubscribe: Unsubscribe | undefined;
  let isCleanedUp = false;

  function cleanup(): void {
    if (isCleanedUp) {
      return;
    }
    isCleanedUp = true;
    try {
      signal?.removeEventListener('abort', handleAbortSignal);
      unsubscribe?.();
    } catch (error) {
      onFinally?.();
      throw error;
    }
  }

  function subscribe(): void {
    try {
      unsubscribe = options.subscribe(
        (value: TValue | undefined, error?: TError) => {
          endReason = handleValueOrError(
            optionsWithDefaults,
            cleanup,
            value,
            error,
          );
        },
      );
    } catch (error) {
      onFinally?.();
      throw error;
    }
  }

  function endSubscriptionManually(): void {
    if (isCleanedUp) return;

    endReason = endReason ?? 'manual';
    cleanup();
    if (endReason === 'manual') {
      // already called for other `SubscriptionEndReason`s
      onFinally?.();
    }
  }

  function handleAbortSignal(): void {
    endReason = handleAborted(
      optionsWithDefaults as RequiredKey<typeof optionsWithDefaults, 'signal'>,
      cleanup,
    );
  }

  if (signal?.aborted) {
    endReason = handleAborted(
      optionsWithDefaults as RequiredKey<typeof optionsWithDefaults, 'signal'>,
      undefined,
    );
    return endSubscriptionManually;
  }
  signal?.addEventListener('abort', handleAbortSignal, { once: true });

  if ('initialValue' in options) {
    endReason = handleValueOrError(
      optionsWithDefaults,
      undefined,
      initialValue as TInitialValue,
    );
    if (endReason != null) {
      return endSubscriptionManually;
    }
  }
  subscribe();

  return endSubscriptionManually;
}
