import type { NavigationItem } from '../../app-shell/model/app.models';
import type {
  AccessLicenses,
  DeviceModel,
  StihlDeviceTag,
} from '../../device/model';

export type AppAuthenticationSession =
  | AppAuthenticationSessionUnauthenticated
  | AppAuthenticationSessionSupport
  | AppAuthenticationSessionDealer;

export enum AppAuthenticationType {
  Support = 'SUPPORT',
  Dealer = 'DEALER',
}

export type AppAuthenticationSessionUnauthenticated = {
  authType: undefined;
};

type AppAuthenticationSessionAuthenticated = {
  authType: AppAuthenticationType;
  token: string;
  tokenType: string;
  accessToken: string;
  username: string;
  navigationItems: NavigationItem[];
  deviceModels: DeviceModel[];
  deviceFlags: StihlDeviceTag[];
  license: AccessLicenses;
  userId: string;
};

type AppAuthenticationSessionSupport = AppAuthenticationSessionAuthenticated & {
  authType: AppAuthenticationType.Support;
};

export type AppAuthenticationSessionDealer =
  AppAuthenticationSessionAuthenticated & {
    authType: AppAuthenticationType.Dealer;
    dealerId: string;
  };
