import { AbortError } from '../error/abort-error';
import { DisposeError } from '../error/dispose-error';
import { isErrorLike } from '../error/error';
import type { InterfaceOfClass } from '../extras-typescript/interface-of-class';
import type { NonNullish } from '../extras-typescript/nothing-types';

export type AbortCause = Error | string;

function isAbortCause(value: unknown): value is AbortCause {
  return typeof value === 'string' || isErrorLike(value);
}

export type AbortCauseObject<TAbortCause extends AbortCause = AbortCause> = {
  cause: TAbortCause;
};

export type AbortSignalWithCauseMaybe<
  TAbortCause extends AbortCause = AbortCause,
> = AbortSignal & Partial<AbortCauseObject<TAbortCause>>;

export type AbortSignalWithCause<TAbortCause extends AbortCause = AbortCause> =
  AbortSignal & AbortCauseObject<TAbortCause>;

export function isAbortSignalWithCause<
  TAbortCause extends AbortCause = AbortCause,
>(
  value: AbortSignal,
  isCause: (cause: NonNullish) => cause is TAbortCause = isAbortCause as (
    cause: NonNullish,
  ) => cause is TAbortCause,
): value is AbortSignalWithCause<TAbortCause> {
  const maybe = value as AbortSignalWithCauseMaybe;
  return maybe.cause != null && isCause(maybe.cause);
}

export type AbortControllerWithCauseMaybe<
  TAbortCause extends AbortCause = AbortCause,
> = Omit<InterfaceOfClass<AbortController>, 'abort'> & {
  abort: (cause?: TAbortCause) => void;
};

/**
 * Accepts a cause for the abort, useful to determine which error to throw
 * when a task is aborted (e.g. a timeout).
 */
export class AbortControllerWithCause<
    TAbortCause extends AbortCause = AbortCause,
  >
  extends AbortController
  implements Disposable
{
  constructor(private readonly createAbortCauseDefault?: () => TAbortCause) {
    super();
  }

  // eslint-disable-next-line class-methods-use-this
  get [Symbol.toStringTag](): string {
    return 'AbortControllerWithCause';
  }

  [Symbol.dispose](): void {
    this.abort(
      new DisposeError(
        `AbortControllerWithCause was disposed before it aborted`,
      ) as TAbortCause,
    );
  }

  override abort(
    cause: TAbortCause | undefined = this.createAbortCauseDefault?.(),
  ): void {
    if (!this.signal.aborted) {
      this.signal.cause = cause;
    }
    super.abort();
  }

  override get signal(): AbortSignalWithCauseMaybe<TAbortCause> {
    return super.signal as AbortSignalWithCauseMaybe<TAbortCause>;
  }
}

export function isAbortControllerWithCause(
  value: unknown,
): value is AbortControllerWithCause {
  return (
    Object.prototype.toString.call(value) ===
    `[object AbortControllerWithCause]`
  );
}

export type AbortErrorFromCause<
  TAbortCauseMaybe extends AbortCause | undefined,
> = TAbortCauseMaybe extends Error ? TAbortCauseMaybe : AbortError;

/**
 * Converts an {@link AbortCause} to a corresponding error.
 *
 * - If {@link AbortSignalWithCauseMaybe#cause} is an error it is used.
 * - Else and {@link AbortError} is used.
 *   - If {@link AbortSignalWithCauseMaybe#cause} is a non-empty string
 *     it is included as the error `message`.
 *
 * @param abortCause
 */
export function toAbortErrorFromCauseMaybe<
  TAbortCause extends AbortCause | undefined,
>(
  abortCause: TAbortCause = '' as TAbortCause,
): AbortErrorFromCause<TAbortCause> {
  if (isErrorLike(abortCause)) {
    return abortCause as AbortErrorFromCause<TAbortCause>;
  }
  const message =
    abortCause === '' ? 'Aborted' : `Aborted: ${String(abortCause)}`;
  return new AbortError(message) as AbortErrorFromCause<TAbortCause>;
}

export type AbortErrorFromSignalWithCauseMaybe<
  TAbortSignal extends AbortSignalWithCauseMaybe,
> =
  TAbortSignal extends AbortSignalWithCause<infer TAbortCause>
    ? AbortErrorFromCause<TAbortCause>
    : TAbortSignal extends AbortSignal & { cause?: undefined }
      ? AbortError
      : TAbortSignal extends AbortSignalWithCauseMaybe<infer TAbortCauseMaybe>
        ? AbortErrorFromCause<TAbortCauseMaybe | undefined>
        : never;

export type GetAbortErrorFromSignalWithCauseMaybe<
  TAbortSignal extends AbortSignalWithCauseMaybe,
> = (signal: TAbortSignal) => AbortErrorFromSignalWithCauseMaybe<TAbortSignal>;

/**
 * Gets the [signal's cause]{@link AbortSignalWithCauseMaybe#cause} if it is
 * an error or creates an {@link AbortError}.
 *
 * @param signal
 *
 * @see toAbortErrorFromCauseMaybe
 */
export function getAbortErrorFromSignalWithCauseMaybe<
  TAbortSignal extends AbortSignalWithCauseMaybe,
>(signal: TAbortSignal): AbortErrorFromSignalWithCauseMaybe<TAbortSignal> {
  return toAbortErrorFromCauseMaybe(
    signal.cause,
  ) as AbortErrorFromSignalWithCauseMaybe<TAbortSignal>;
}
