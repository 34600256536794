import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useContext,
  useMemo,
  useSyncExternalStore,
} from 'react';
import { createEventHandlersSync } from '../../../../base/event/event-handlers';
import { useAppBase } from '../../../app-shell/service/app-base/app-base-api-client';
import { AccessLicenses } from '../../../device/model';
import { AppAuthenticationType } from '../../model/authentication-session';
import type {
  AppAuthenticationSession,
  AppAuthenticationSessionUnauthenticated,
} from '../../model/authentication-session';
import type { AppAuthenticationClient } from '../app-authentication-client/app-authentication-client';
import { createAppAuthenticationClient } from '../app-authentication-client/app-authentication-client';
import { isAppAuthenticationClientStateSettled } from '../app-authentication-client/app-authentication-client-state';
import appAuthenticationSessionConfigDealer from './config/dealer';
import appAuthenticationSessionConfigSupport from './config/support';

type AppAuthenticationSessionContextValue = {
  appAuthenticationClient: AppAuthenticationClient | undefined;
  appAuthenticationSession: AppAuthenticationSession;
  authProvider: AppAuthenticationType | null;
  setAuthProvider: ((
    authProvider: AppAuthenticationType,
  ) => AppAuthenticationClient) &
    ((authProvider: null) => void);
};
const AppAuthenticationSessionContext = createContext<
  AppAuthenticationSessionContextValue | undefined
>(undefined);

export const useAppAuthenticationSessionContext =
  (): AppAuthenticationSessionContextValue => {
    const context = useContext(AppAuthenticationSessionContext);

    if (!context) {
      throw new Error('No AppAuthenticationSessionContext found.');
    }

    return context;
  };

const authenticationSessionUnauthenticated: AppAuthenticationSessionUnauthenticated =
  { authType: undefined };

// eslint-disable-next-line unicorn/consistent-function-scoping
const subscribeUserUndefined = () => () => {};
const getUserUndefined = (): undefined => undefined;

function useAuthenticationClientStore(): Pick<
  AppAuthenticationSessionContextValue,
  'appAuthenticationClient' | 'authProvider' | 'setAuthProvider'
> {
  const authenticationClientStore = useMemo(() => {
    const notifiers = createEventHandlersSync<() => void>();

    function setAuthProviderInternal(
      authProviderNew: AppAuthenticationType,
    ): AppAuthenticationClient;
    function setAuthProviderInternal(authProviderNew: null): undefined;
    function setAuthProviderInternal(
      authProviderNew: AppAuthenticationType | null,
    ): AppAuthenticationClient | undefined {
      result.appAuthenticationClient?.[Symbol.dispose]();

      let appAuthenticationClient: AppAuthenticationClient | undefined =
        undefined;

      if (authProviderNew === null) {
        window.localStorage.removeItem('AUTH_PROVIDER');

        notifiers.invoke();
      } else {
        window.localStorage.setItem('AUTH_PROVIDER', authProviderNew);

        const authenticationClientConfig =
          authProviderNew === AppAuthenticationType.Support
            ? appAuthenticationSessionConfigSupport
            : appAuthenticationSessionConfigDealer;

        appAuthenticationClient = createAppAuthenticationClient({
          settings: authenticationClientConfig,
        });

        void appAuthenticationClient
          .load()
          .catch((error) => {
            console.error(
              '[AppAuthenticationClientService] Loading authentication client failed',
              error,
            );

            appAuthenticationClient?.[Symbol.dispose]();

            if (result.appAuthenticationClient === appAuthenticationClient) {
              result.appAuthenticationClient = undefined;
            }
          })
          .finally(notifiers.invoke);
      }

      result = {
        ...result,
        appAuthenticationClient,
      };

      return result.appAuthenticationClient;
    }

    const authProviderInitial = window.localStorage.getItem(
      'AUTH_PROVIDER',
    ) as AppAuthenticationType | null;

    let result: ReturnType<typeof useAuthenticationClientStore> = {
      authProvider: authProviderInitial,
      setAuthProvider: setAuthProviderInternal,
      appAuthenticationClient: undefined,
    };

    return {
      get: () => result,
      subscribe: (notify: () => void) => {
        if (
          result.authProvider != null &&
          result.appAuthenticationClient == null
        ) {
          setAuthProviderInternal(result.authProvider);
        }

        const removeNotifier = notifiers.add(notify);

        return () => {
          removeNotifier();

          const appAuthenticationClient = result.appAuthenticationClient;

          const removeSubscription = appAuthenticationClient?.onStatus.add(
            (status) => {
              if (!isAppAuthenticationClientStateSettled(status)) {
                return;
              }

              (removeSubscription as () => void)();

              if (notifiers.size > 0) {
                return;
              }

              appAuthenticationClient[Symbol.dispose]();

              if (result.appAuthenticationClient === appAuthenticationClient) {
                result.appAuthenticationClient = undefined;
              }
            },
            { shouldInitialize: true },
          );
        };
      },
    };
  }, []);

  return useSyncExternalStore(
    authenticationClientStore.subscribe,
    authenticationClientStore.get,
  );
}

const AppAuthenticationSessionProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { appAuthenticationClient, setAuthProvider, authProvider } =
    useAuthenticationClientStore();

  const user = useSyncExternalStore(
    appAuthenticationClient?.onUser.add ?? subscribeUserUndefined,
    appAuthenticationClient?.onUser.get ?? getUserUndefined,
  );

  const appBaseResult = useAppBase(Boolean(user));

  const authenticationSession = useMemo<AppAuthenticationSession>(() => {
    if (!user) {
      return authenticationSessionUnauthenticated;
    }

    if (authProvider === AppAuthenticationType.Support) {
      return {
        authType: AppAuthenticationType.Support,
        token: user.id_token,
        tokenType: user.token_type,
        accessToken: user.access_token,
        username: 'name' in user.profile ? (user.profile.name as string) : '',
        navigationItems: [],
        deviceModels: [],
        deviceFlags: [],
        license: AccessLicenses.FirstLevelSupportLicense,
        ...appBaseResult.data,
        userId: user.profile.sub as string,
      };
    }

    return {
      authType: AppAuthenticationType.Dealer,
      token: user.id_token,
      tokenType: user.token_type,
      accessToken: user.access_token,
      username:
        'given_name' in user.profile && 'family_name' in user.profile
          ? `${user.profile.given_name as string} ${user.profile.family_name as string}`
          : '',
      navigationItems: [],
      deviceModels: [],
      deviceFlags: [],
      license: AccessLicenses.DealerLicense,
      ...appBaseResult.data,
      userId: user.profile.sub as string,
      dealerId: user.profile.dealer_org_id as string,
    };
  }, [user, authProvider, appBaseResult]);

  return (
    <AppAuthenticationSessionContext.Provider
      value={{
        appAuthenticationClient,
        authProvider,
        appAuthenticationSession: authenticationSession,
        setAuthProvider,
      }}
    >
      {children}
    </AppAuthenticationSessionContext.Provider>
  );
};

export default AppAuthenticationSessionProvider;
