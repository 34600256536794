import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { FilterParameterValue } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { ChipType } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import QueryComponent from '../../../app-shell/ui/query-component';
import { MessageWrapper } from '../../../device/ui/tabs/device-tabs-device-details';
import { useCustomers } from '../../service/customer-service/customer-service';
import CustomerManagementTable from '../customer-management-list/customer-management-table';
import CustomerTableFilter from '../customer-management-list/customer-table-filter';
import { filterDataByChips } from './customer-management-utils';

type CustomerStateType = {
  filterEmail: string | undefined;
};

const CustomerManagementOverviewTab = (): JSX.Element => {
  const { t } = useTranslation();
  const [chips, setChips] = useState<ChipType[]>([]);

  const customerManagementCustomersQueryResult = useCustomers();

  const { state } = useLocation<CustomerStateType | null>();

  useEffect(() => {
    if (state?.filterEmail) {
      setChips([
        { filter: FilterParameterValue.Email, searchTerm: state.filterEmail },
      ]);
    }
  }, [state, state?.filterEmail]);

  const filteredCustomerData = filterDataByChips(
    customerManagementCustomersQueryResult.data ?? [],
    chips,
  );

  return (
    <>
      <CustomerTableFilter chips={chips} setChips={setChips} />
      <QueryComponent
        data={customerManagementCustomersQueryResult}
        placeholderText={t('searchPrompt')}
      >
        {filteredCustomerData.length > 0 ? (
          <CustomerManagementTable
            customerManagementCustomers={filteredCustomerData}
            customerManagementCustomersQueryResult={
              customerManagementCustomersQueryResult
            }
          />
        ) : (
          customerManagementCustomersQueryResult.data && (
            <MessageWrapper data-testid="noResultMessage">
              <Typography>
                {t('customerManagement.noCustomersAssigned')}
              </Typography>
            </MessageWrapper>
          )
        )}
      </QueryComponent>
    </>
  );
};

export default CustomerManagementOverviewTab;
