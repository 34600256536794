import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import type { HealthStatus } from '../../model/health-status.model';
import getHealthStatus from '../health-api-client/health-api-client';

export function useHealthStatus(): UseQueryResult<HealthStatus, Error> {
  return useQuery<HealthStatus, Error>({
    queryKey: ['healthStatus'],
    queryFn: async ({ signal }) => getHealthStatus(signal),
  });
}
