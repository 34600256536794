import type { UseQueryResult } from '@tanstack/react-query';
import { useQueryClient, useIsFetching, useQuery } from '@tanstack/react-query';
import type { AppBaseResponse } from '../../model/app.models';
import instance from '../axios';
import { getAppBaseApiPath } from './app-base-api-config';

export async function getAppBase(
  signal?: AbortSignal,
): Promise<AppBaseResponse> {
  return instance
    .get<AppBaseResponse>(getAppBaseApiPath(), { signal })
    .then((response) => response.data);
}

export function useAppBase(isEnabled = true): UseQueryResult<AppBaseResponse> {
  return useQuery({
    queryKey: ['appBase'],
    queryFn: async ({ signal }) => getAppBase(signal),
    enabled: isEnabled,
    refetchOnMount: false,
  });
}

export function useIsFetchingAppBase(): boolean {
  const client = useQueryClient();

  const isFetchingAppBase = useIsFetching({ queryKey: ['appBase'] }) > 0;
  const appBaseResult = client.getQueryCache().find({ queryKey: ['appBase'] });

  return (
    isFetchingAppBase ||
    appBaseResult == null ||
    appBaseResult.state.status === 'pending'
  );
}
