import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import { useState, useCallback, useId } from 'react';
import type { SyntheticEvent, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { string, object } from 'yup';
import { useDateFormatting } from '../../../../base/date-formatting/date-formatting';
import type { RangeObject } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { FilterParameterValue } from '../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { StihlHelperText } from '../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import { SthilLocalizationProvider } from '../../../../base/stihl-material-ui/components/stihl-localization-provider/stihl-localization-provider';
import StihlTextField from '../../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconFilter from '../../../../base/stihl-material-ui/icons/stihl-icon-filter';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type {
  DeviceConnectionState,
  DeviceType,
  StihlDeviceTag,
  DeviceModel,
} from '../../../device/model/device.model';
import { useSoftwareVersions } from '../../service/device-management-service/device-management-service';

const StyledForm = styled.form`
  display: flex;
  align-items: flex-start;
  padding-block-start: 1.5rem;
  color: black;
`;

const RangeTypography = styled(Typography)`
  margin-block-start: 1rem;
  padding-inline: 0.5rem;
`;

const StyledIconButton = styled(IconButton)`
  padding-block-start: 1rem;
`;

type RangeInputSerialNumberProps = {
  serialNumbersCallback: (values: RangeObject) => void;
};

export const RangeInputSerialNumber = ({
  serialNumbersCallback,
}: RangeInputSerialNumberProps): JSX.Element => {
  const { t } = useTranslation();
  function handleSearch(
    values: RangeObject,
    { setSubmitting, resetForm }: FormikHelpers<RangeObject>,
  ): void {
    setSubmitting(false);
    serialNumbersCallback(values);
    resetForm();
  }

  const initialValues: RangeObject = {
    identifier: FilterParameterValue.RangeOfSerialnumbers,
    start: '',
    end: '',
    range: [null, null],
  };

  const validationSchema = object().shape({
    start: string()
      .matches(/^\d*$/u, t('formik.onlyNumbers'))
      .min(9, t('formik.min9Digits'))
      .max(10, t('formik.max10Digits'))
      .required(t('formik.validSerialNumber')),
    end: string()
      .matches(/^\d*$/u, t('formik.onlyNumbers'))
      .min(9, t('formik.min9Digits'))
      .max(10, t('formik.max10Digits'))
      .required(t('formik.validSerialNumber')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSearch,
  });

  const { handleChange, handleSubmit, values, errors } = formik;

  const labelId = useId();

  return (
    <StyledForm onSubmit={handleSubmit} data-testid="serialNumberRangeForm">
      <RangeTypography id={`${labelId}-start`}>
        {t('deviceManagementFilter.rangeFrom')}
      </RangeTypography>
      <StihlTextField
        id="start"
        name="start"
        variant="standard"
        color="secondary"
        value={values.start}
        onChange={handleChange}
        error={values.start !== initialValues.start && Boolean(errors.start)}
        helperText={
          values.start !== initialValues.start && Boolean(errors.start) ? (
            <StihlHelperText
              text="deviceManagementFilter.errorSerialNr"
              data-testid="rangeErrorText"
            />
          ) : (
            ''
          )
        }
        inputProps={{
          'data-testid': 'startNumber',
          'aria-labelledby': `${labelId}-start`,
        }}
      />
      <RangeTypography id={`${labelId}-end`}>
        {t('deviceManagementFilter.rangeTo')}
      </RangeTypography>
      <StihlTextField
        id="end"
        name="end"
        variant="standard"
        color="secondary"
        value={values.end}
        onChange={handleChange}
        error={values.end !== initialValues.end && Boolean(errors.end)}
        helperText={
          values.end !== initialValues.end && Boolean(errors.end) ? (
            <StihlHelperText text="deviceManagementFilter.errorSerialNr" />
          ) : (
            ''
          )
        }
        inputProps={{ 'aria-labelledby': `${labelId}-end` }}
      />
      <StyledIconButton
        data-testid="addFilterButton"
        type="submit"
        disabled={Boolean(errors.start) || Boolean(errors.end)}
      >
        <StihlIconFilter
          color={
            Boolean(errors.start) || Boolean(errors.end)
              ? 'text.disabled'
              : 'text.primary'
          }
        />
      </StyledIconButton>
    </StyledForm>
  );
};

type RangeInputProductionDateProps = {
  productionRangeCallback: (values: RangeObject) => void;
};

export const RangeInputProductionDate = ({
  productionRangeCallback,
}: RangeInputProductionDateProps): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  function handleProductionRangeSubmit(): void {
    const startDate = dateStart ? toLocaleDateString(dateStart) : '';
    const endDate = dateEnd ? toLocaleDateString(dateEnd) : '';
    productionRangeCallback({
      identifier: FilterParameterValue.RangeOfProductionDates,
      start: startDate,
      end: endDate,
      range: [dateStart, dateEnd],
    });
    setDateStart(null);
    setDateEnd(null);
  }

  const labelId = useId();

  return (
    <>
      <StyledForm
        onSubmit={handleProductionRangeSubmit}
        data-testid="productionDateRangeForm"
      >
        <SthilLocalizationProvider>
          <RangeTypography id={`${labelId}-start`}>
            {t('deviceManagementFilter.rangeFrom')}
          </RangeTypography>
          <DatePicker
            value={dateStart}
            onChange={(value) => {
              setHasError(false);
              setDateStart(value);
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
            slotProps={{
              textField: {
                inputProps: { 'aria-labelledby': `${labelId}-start` },
              },
            }}
          />
          <RangeTypography id={`${labelId}-end`}>
            {t('deviceManagementFilter.rangeTo')}
          </RangeTypography>
          <DatePicker
            value={dateEnd}
            onChange={(value) => {
              setHasError(false);
              setDateEnd(value);
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
            slotProps={{
              textField: {
                inputProps: { 'aria-labelledby': `${labelId}-end` },
              },
            }}
          />
        </SthilLocalizationProvider>
        <StyledIconButton
          data-testid="addFilterButton"
          onClick={handleProductionRangeSubmit}
          disabled={dateStart === null || dateEnd === null || hasError}
        >
          <StihlIconFilter
            color={
              dateStart === null || dateEnd === null || hasError
                ? 'text.disabled'
                : 'text.primary'
            }
          />
        </StyledIconButton>
      </StyledForm>
      {hasError && <StihlHelperText text="deviceManagementFilter.errorDate" />}
    </>
  );
};

type SoftwareVersionInputProps = {
  deviceModel: DeviceModel | undefined;
  handleCallback: (
    name: string,
    value: DeviceConnectionState | StihlDeviceTag | DeviceType | string,
  ) => void;
};

export const SoftwareVersionInput = ({
  deviceModel,
  handleCallback,
}: SoftwareVersionInputProps): JSX.Element => {
  const { t } = useTranslation();
  const softwareVersions = useSoftwareVersions(deviceModel);
  const [selectedSwVersion, setSelectedSwVersion] = useState<string>('');

  const handleSwVersionSelectionChange = useCallback(
    (_: SyntheticEvent<Element, Event>, value?: string | null): void => {
      setSelectedSwVersion(value ?? '');
    },
    [setSelectedSwVersion],
  );

  function handleSwVersionChangeTextfield(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    setSelectedSwVersion(event.target.value);
  }

  function handleSoftwareVersionSubmit(): void {
    handleCallback(FilterParameterValue.SoftwareVersion, selectedSwVersion);
    setSelectedSwVersion('');
  }

  function handleEnter(event: React.KeyboardEvent): void {
    const pressedKey: string = event.key;
    if (
      pressedKey === 'Enter' &&
      selectedSwVersion.length > 0 &&
      selectedSwVersion.length <= 35
    ) {
      event.preventDefault();
      handleCallback(FilterParameterValue.SoftwareVersion, selectedSwVersion);
      setSelectedSwVersion('');
    }
  }

  const labelId = useId();

  return (
    <form
      onSubmit={handleSoftwareVersionSubmit}
      data-testid="softwareVersionForm"
    >
      <InputLabel
        variant="standard"
        id={labelId}
        sx={{ color: `${stihlColor.black}` }}
      >
        {t('deviceManagementFilter.softwareVersion')}
      </InputLabel>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          freeSolo
          fullWidth
          loading={softwareVersions.isLoading}
          id="swVersion"
          data-testid="softwareVersionAutoComplete"
          value={selectedSwVersion}
          options={softwareVersions.data ?? []}
          onChange={handleSwVersionSelectionChange}
          onKeyDown={handleEnter}
          renderInput={(parameters) => (
            <StihlTextField
              {...parameters}
              InputProps={{
                ...parameters.InputProps,
              }}
              inputProps={{
                ...parameters.inputProps,
                'aria-labelledby': labelId,
              }}
              onChange={handleSwVersionChangeTextfield}
              onKeyDown={handleEnter}
            />
          )}
        />
        <StyledIconButton
          data-testid="addFilterButton"
          onClick={handleSoftwareVersionSubmit}
          disabled={selectedSwVersion === '' || selectedSwVersion.length >= 35}
        >
          <StihlIconFilter
            color={
              selectedSwVersion === '' || selectedSwVersion.length >= 35
                ? 'text.disabled'
                : 'text.primary'
            }
          />
        </StyledIconButton>
      </div>
    </form>
  );
};
